import { useParams } from "react-router-dom";
import {
  getVehicleById,
  updateVehicle,
  submitPhotosToVehicle,
} from "../../services/vehicles";
import VehicleForm from "../../components/vehicle-form/VehicleForm";
import { useQuery } from "@tanstack/react-query";
import { useEntityUpdate, usePhotoUpload } from "../../utils/sharedMutations";
import { preparePhotosForUpload } from "../../utils/helpers";

function EditVehicle() {
  const { vehicleId } = useParams();

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["vehicles", vehicleId],
    queryFn: () => getVehicleById(vehicleId),
    select: (data) => data.data,
    onSuccess: (data) => {},
  });

  const submitPhotosMutation = usePhotoUpload(({ vehicleId, photos }) =>
    submitPhotosToVehicle(vehicleId, photos)
  );

  const editVehicleMutation = useEntityUpdate((vehicleData) => {
    delete vehicleData.id;
    return updateVehicle(vehicleId, {
      ...vehicleData,
    });
  });

  const onSubmit = async (vehicleData, images) => {
    const vehicle = await editVehicleMutation.mutateAsync(vehicleData);
    if (images.length > 0) {
      await submitPhotosMutation.mutateAsync({
        vehicleId: vehicle.data.id,
        photos: preparePhotosForUpload(images),
      });
    }
    refetch();
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <VehicleForm
      editable
      vehicleData={{
        ...data,
      }}
      onSubmit={onSubmit}
      isSubmitting={
        editVehicleMutation.isLoading || submitPhotosMutation.isLoading
      }
    />
  );
}

export default EditVehicle;
