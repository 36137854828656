import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import "./MainLayout.scss";
import { useAuth } from "../../contexts/AuthContext";
import //Alert,
//AlertIcon,
//AlertTitle,
//AlertDescription,
//CloseButton,
"@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react";
//import { useTranslation } from "react-i18next";
import { ROLES } from "../../constants";

function MainLayout({ children }) {
  const { currentUser } = useAuth();
  //const { t, i18n } = useTranslation("common");
  const { isOpen /*onClose*/ } = useDisclosure({ defaultIsOpen: true });

  return (
    <div>
      <Header />
      {
        isOpen &&
          !currentUser?.emailConfirmed &&
          currentUser?.type === ROLES.GUEST &&
          null /*
          <Alert h={"40px"} status="error">
            <AlertIcon />
            <AlertTitle>{t("emailNotConfirmedAlert")}</AlertTitle>
            <AlertDescription>{t("emailNotConfirmedMessage")}</AlertDescription>
            <CloseButton
              alignSelf="center"
              position="absolute"
              right={i18n.language === "en" ? 0 : "auto"}
              left={i18n.language === "en" ? "auto" : 0}
              top={0}
              onClick={onClose}
              size="sm"
            />
          </Alert>
        */
      }
      <main>{children}</main>
      <Footer />
    </div>
  );
}

export default MainLayout;
