import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { fetchAccommodationsByUser } from "../../services/accommodations";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Image,
  Button,
  HStack,
  Tag,
  TagLeftIcon,
  TagLabel,
  TagCloseButton,
  Tooltip,
  useToast,
  Skeleton,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { EditIcon, PlusSquareIcon } from "@chakra-ui/icons";
import { deleteAccommodationUnit } from "../../services/accommodationUnits";
import { useTranslation } from "react-i18next";
import { ACCOMMODATION_TYPES } from "../../constants";

function ManageAccommodations() {
  const toast = useToast();
  const { t } = useTranslation("common");
  const [currentPage, setCurrentPage] = useState(1);
  const [accommodationsData, setAccommodationsData] = useState([]);
  const [hasNextPage, setHasNextPage] = useState(false);

  const {
    data: accommodations,
    isLoading,
    isFetching,
    refetch,
  } = useQuery(
    ["manageAccommodations", currentPage],
    () => fetchAccommodationsByUser({ page: currentPage }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: true,
      select: (payload) => payload.data,
      onSuccess: (data) => {
        setAccommodationsData((prevAccommodationsData) => [
          ...prevAccommodationsData,
          ...data.results,
        ]);
      },
    }
  );

  useEffect(() => {
    if (
      accommodations?.pagination.nextPage &&
      accommodations?.pagination.totalPages > currentPage
    )
      setHasNextPage(true);
    else setHasNextPage(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accommodations]);

  const onAccommodationUnitDelete = (id) => {
    if (
      window.confirm("Are you sure you want to delete this accommodation unit?")
    ) {
      deleteAccommodationUnit(id).then(() => {
        toast({
          title: t("entityDeleteSuccessMessage"),
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        refetch();
      });
    }
  };

  if (isLoading) {
    return (
      <div>
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <>
      <TableContainer>
        <Table variant="simple">
          <TableCaption>
            Accommodations added by administrators and domestic partners
          </TableCaption>
          <Thead>
            <Tr>
              <Th>{t("id")}</Th>
              <Th>{t("thumbnail")}</Th>
              <Th>{t("name")}</Th>
              <Th>{t("accommodationUnitsName")}</Th>
              <Th>{t("addedBy")}</Th>
              <Th>{t("actions")}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {accommodationsData.map((accommodation) => (
              <Tr key={accommodation.id}>
                <Td>{accommodation.id}</Td>
                <Td>
                  <Link to={`/${accommodation.type}/${accommodation.slug}`}>
                    <Image
                      borderRadius="lg"
                      src={accommodation.previewImageUrl}
                      alt="photo"
                      boxSize={"50px"}
                    />
                  </Link>
                </Td>
                <Td>{accommodation.name}</Td>
                <Td>
                  <HStack wrap="wrap">
                    {accommodation.units.map((unit) => (
                      <Link
                        key={unit.id}
                        to={`/${accommodation.type}/${accommodation.slug}/${unit.id}/edit`}
                      >
                        <Tag key={unit.id} size="md" variant="subtle">
                          <TagLeftIcon boxSize="12px" as={EditIcon} />
                          <TagLabel>{unit.name}</TagLabel>
                          <Tooltip label="Delete" aria-label="Delete">
                            <TagCloseButton
                              onClick={(e) => {
                                e.preventDefault();
                                onAccommodationUnitDelete(unit.id);
                              }}
                            />
                          </Tooltip>
                        </Tag>
                      </Link>
                    ))}
                    {[
                      ACCOMMODATION_TYPES.APARTMENT,
                      ACCOMMODATION_TYPES.VILLA,
                    ].includes(accommodation.type) &&
                    accommodation.units.length >= 1 ? null : (
                      <Link
                        to={`/${accommodation.type}/${accommodation.id}/create-accommodation-unit`}
                      >
                        <Button
                          size="xs"
                          variant="outline"
                          leftIcon={<PlusSquareIcon />}
                        >
                          {t("addNew")}
                        </Button>
                      </Link>
                    )}
                  </HStack>
                </Td>
                <Td>{accommodation.createdBy.firstName}</Td>
                <Td>
                  <Link
                    to={`/${accommodation.type}/${accommodation.slug}/edit`}
                  >
                    <Button size="xs" variant="outline" leftIcon={<EditIcon />}>
                      {t("edit")}
                    </Button>
                  </Link>
                </Td>
              </Tr>
            ))}
            {isFetching && (
              <>
                <Tr>
                  <Td colSpan="100%">
                    <Skeleton height="20px" />
                  </Td>
                </Tr>
                <Tr>
                  <Td colSpan="100%">
                    <Skeleton height="20px" />
                  </Td>
                </Tr>
              </>
            )}
          </Tbody>
        </Table>
      </TableContainer>
      {hasNextPage && (
        <Button
          isLoading={isFetching}
          onClick={() => {
            setCurrentPage(currentPage + 1);
          }}
        >
          Show more
        </Button>
      )}
    </>
  );
}

export default ManageAccommodations;
