import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Grid,
  GridItem,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { AddIcon, CheckIcon } from "@chakra-ui/icons";
import { createBooking } from "../../services/bookings";
import { getCategories } from "../../services/vehicles";
import { RESERVATION_TYPES } from "../../constants";
import _ from "lodash";
import TripCard from "./TripCard";
import ChooseStayMenu from "./ChooseStayMenu";
import { useState, useEffect } from "react";
import ReservationTabs from "../../components/reservation-tabs/ReservationTabs";
import {
  //pickUpTimeInputOptions,
  SARAJEVO_INTL_AIRPORT,
  //STAY_CITIES,
} from "../../constants";

const CITIES = ["Sarajevo", "Mostar", "Jajce", "Bihac"];

const formattedDateString = (dateString) => {
  return new Date(dateString).toLocaleDateString(undefined, {
    day: "2-digit",
    month: "short",
  });
};

const getDatesArray = (startDate, endDate) => {
  let dates = [];
  let currentDate = new Date(startDate);
  while (currentDate <= new Date(endDate)) {
    dates.push(currentDate.toISOString().split("T")[0]);
    currentDate = new Date(currentDate);
    currentDate.setDate(currentDate.getDate() + 1);
  }
  // dates = dates.slice(0, dates.length - data.trips.length + 1);
  return dates;
};

const createInitialTourFromTemplate = (tourTemplate, startDate, endDate) => {
  let initialTour = {};
  let cursor = 0;
  const tripsToRemove = [];
  const tourDuration = getDatesArray(startDate, endDate).length;

  while (tripsToRemove.length < tourTemplate.length - tourDuration) {
    cursor = cursor % CITIES.length;
    let removableTripIndex = tourTemplate.findLastIndex(
      // eslint-disable-next-line no-loop-func
      (trip, index) =>
        trip.startingPoint === CITIES[cursor] &&
        trip.destination === CITIES[cursor] &&
        !tripsToRemove.includes(index)
    );

    if (removableTripIndex) {
      tripsToRemove.push(removableTripIndex);
    }
    cursor++;
  }

  initialTour = tourTemplate.filter(
    (_trip, index) => !tripsToRemove.includes(index)
  );

  return initialTour;
};

const getTourName = (tour, tourDuration) => {
  const cities = [
    ...new Set(Object.values(tour).map((trip) => trip.destination)),
  ];
  return (
    cities.join(", ").replace(/, ([^,]*)$/, " & $1") +
    // ` - ${Object.keys(tour).length} days`
    ` - ${tourDuration} days`
  );
};

// eslint-disable-next-line no-unused-vars
const validateTourStay = (tourStays, city, duration, index) => {
  // Must stay at least 1 day in Sarajevo
  if (index === 0 && (city !== "Sarajevo" || duration < 1)) return false;
  // Last day must be in Sarajevo
  if (index === tourStays.length - 1 && city !== "Sarajevo") return false;
  // At least 2 consecutive days in Bihac
  if (city === "Bihac" && duration < 2) return false;
  // No return to the same city
  if (index > 0 && !!tourStays.find((stay) => stay.city === city)) return false;

  return true;
};

const rearrangeStayDurations = (tourStays, totalDuration) => {
  const staysCount = Object.keys(tourStays).length;
  const daysToDistributePerStay = Math.floor(
    (totalDuration - 1) / (staysCount - 1)
  );

  return tourStays
    .map((stay, index) => {
      return {
        ...stay,
        duration:
          index === staysCount - 1
            ? 1
            : index === staysCount - 2
            ? totalDuration -
              1 -
              daysToDistributePerStay * (staysCount - 2) -
              (daysToDistributePerStay === 1 ? 1 : 0)
            : stay.city === "Bihac" && daysToDistributePerStay === 1
            ? 2
            : daysToDistributePerStay,
      };
    })
    .filter((stay) => stay.duration > 0);
};

const groupTourDaysIntoArray = (tour) => {
  const tourArray = Object.keys(tour).map((key) => {
    return { date: key, trip: tour[key] };
  });

  return tourArray.reduce((acc, tourDay, index) => {
    acc = acc ?? [];
    const { date, trip } = tourDay;

    if (
      index === 0 ||
      trip.destination !== tourArray[index - 1].trip.destination
    ) {
      acc.push({
        startDate: date,
        endDate: tourArray[index + 1]?.date || date,
        destination: trip.destination,
        stay: {
          id: null,
          name: "",
        },
        trips: [
          {
            ...trip,
            date: date,
            replacements: trip.tripReplacements,
          },
        ],
      });
    } else {
      acc[acc.length - 1].endDate = tourArray[index + 1]?.date || date;
      acc[acc.length - 1].trips.push({
        ...trip,
        date: date,
        replacements: trip.tripReplacements,
      });
    }

    return acc;
  }, []);
};

const makeTourFromStays = (tourStays, startDate, endDate) => {
  let tour = {};
  const cities = [];
  tourStays.forEach((stay) =>
    _.times(stay.duration, () => cities.push(stay.city))
  );
  const tourTemplate = require("./tourTemplate.json");

  getDatesArray(startDate, endDate).forEach((date, index) => {
    const startingPoint = cities[index - 1] || "Sarajevo";
    const destination = cities[index] || "Sarajevo";
    const suitableTrips = tourTemplate.filter(
      (trip) =>
        trip.startingPoint === startingPoint && trip.destination === destination
    );
    const previousTripsInSameCity = Object.values(tour).filter(
      (trip) =>
        trip.startingPoint === startingPoint && trip.destination === destination
    );
    const recommendedTrip = suitableTrips[previousTripsInSameCity.length || 0];
    const replacementTrips = suitableTrips.filter(
      (trip) => trip !== recommendedTrip
    );

    tour[date] = {
      startingPoint: startingPoint,
      destination: destination,
      description: recommendedTrip?.description || "",
      attractions: recommendedTrip?.attractions || [],
      tripReplacements: replacementTrips,
      stayId: null,
      submitted: false,
    };
  });

  return groupTourDaysIntoArray(tour);
};

const TourBuilder = () => {
  const [searchParams] = useSearchParams();
  const tourStartDate = searchParams.get("start_date");
  const tourEndDate = searchParams.get("end_date");
  const adults = parseInt(searchParams.get("adults"));
  const children = searchParams.getAll("children");
  const startDate = searchParams.get("start_date");
  const endDate = searchParams.get("end_date");
  // eslint-disable-next-line no-unused-vars
  const rooms = parseInt(searchParams.get("rooms"));

  //const [pickUpTime, setPickUpTime] = useState("08:00");
  const [pickupLocation, setPickupLocation] = useState(SARAJEVO_INTL_AIRPORT);

  const tourTemplate = require("./tourTemplate.json");
  const initialTour = createInitialTourFromTemplate(
    tourTemplate,
    tourStartDate,
    tourEndDate
  );
  let initialTourState = {};
  const tourDuration = getDatesArray(tourStartDate, tourEndDate).length - 1;
  getDatesArray(tourStartDate, tourEndDate).forEach((date, index) => {
    initialTourState[date] = initialTour[index];
  });

  const duration = tourDuration - 2;
  const initialTourStays = rearrangeStayDurations(
    [
      {
        city: "Sarajevo",
        duration: Math.floor(duration / CITIES.length) + 1,
        trips: [],
        stayId: null,
      },
      {
        city: "Mostar",
        duration: Math.floor(duration / CITIES.length),
        trips: [],
        stayId: null,
      },
      {
        city: "Jajce",
        duration: Math.floor(duration / CITIES.length),
        trips: [],
        stayId: null,
      },
      {
        city: "Bihac",
        duration: Math.floor(duration / CITIES.length),
        trips: [],
        stayId: null,
      },
      {
        city: "Sarajevo",
        duration: 1,
        trips: [],
        stayId: null,
      },
    ],
    tourDuration
  );
  //const [allDaysPicked, setAllDaysPicked] = useState(true);
  const [tourStays, setTourStays] = useState(initialTourStays);
  const [tour, setTour] = useState(null);
  const [currentTripData, setCurrentTripData] = useState({
    trip: {},
    selectedStayIndex: null,
    selectedTripIndex: null,
    selectedReplacementIndex: null,
  });

  useEffect(() => {
    //console.log("tourrrr: ", tour);
  }, [tour]);

  useEffect(() => {
    setTourStays(initialTourStays);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, adults /*, children*/]);

  const {
    isOpen: isTripChangeModalOpen,
    onOpen: onTripChangeModalOpen,
    onClose: onTripChangeModalClose,
  } = useDisclosure();

  const toast = useToast();
  const navigate = useNavigate();

  const [selectedTransportationKey, setSelectedTransportationKey] = useState({
    seats: 0,
  });

  useEffect(() => {
    //console.log("selectedTransportationKey", JSON.stringify(selectedTransportationKey));
  }, [selectedTransportationKey]);

  const vehicleCategoriesQuery = useQuery(
    ["vehicleCategories", tourStartDate, tourEndDate, adults, children],
    () => getCategories(),
    {
      refetchOnWindowFocus: false,
      select: (payload) => payload.data,
      onSuccess: (data) => {
        var childrenPassengers = 0;
        if (children.length !== 0) {
          childrenPassengers = children.length;
        }
        var min = adults + childrenPassengers;
        for (let category of data) {
          if (parseInt(category.seats) >= adults + childrenPassengers) {
            min = category;
            break;
          }
        }
        setSelectedTransportationKey(min);
      },
    }
  );

  // eslint-disable-next-line no-unused-vars
  const fullStayName = (stay) => {
    return `${
      stay.accommodationType === "hotel" ? stay.accommodationName + " - " : ""
    }${stay.name}`;
  };

  const staysCount = Object.keys(tourStays).length;
  const uniqueCitiesInTour = [...new Set(tourStays.map((stay) => stay.city))];
  const totalStayDuration = tourStays.reduce(
    (acc, stay) => acc + stay.duration,
    0
  );
  const stayPeriods = [];
  const stayDates = getDatesArray(tourStartDate, tourEndDate);
  let dateOffset = 0;
  tourStays.forEach((stay, index) => {
    stayPeriods.push({
      startDate: stayDates[dateOffset],
      endDate: stayDates[dateOffset + stay.duration],
    });
    dateOffset += stay.duration;
  });

  const createBookingMutation = useMutation(
    () => {
      /*const bookedStays = tour
        .filter((tourStay) => tourStay.stay.id !== null)
        .map((tourStay) => {
          return {
            id: tourStay.stay.id,
            type: "AccommodationUnit",
            quantity: tourStay.stay.quantity,
            startDate: tourStay.startDate,
            endDate: tourStay.endDate,
            // endDate: new Date(trip.date).toISOString().split("T")[0],
          };
        });
        */
      const bookedStays = tour.flatMap((tourStay) => {
        const stays = Array.isArray(tourStay.stay) ? tourStay.stay : [];
        return stays
          .filter((stay) => stay.id !== null) // Filter stays with a non-null id
          .map((stay) => ({
            id: stay.id,
            type: "AccommodationUnit",
            quantity: stay.quantity,
            startDate: tourStay.startDate,
            endDate: tourStay.endDate,
          }));
      });

      const tourData = tour.map((tourStay) => {
        return {
          city: tourStay.destination,
          startDate: tourStay.startDate,
          endDate: tourStay.endDate,
          //stay: tourStay.stay.name,
          //stay: tourStay.stay.map((stay) => stay.name).join(", "),
          stay: Array.isArray(tourStay.stay)
            ? tourStay.stay.map((stay) => stay.name).join(", ")
            : "No stays", // Handle non-array cases
          trips: tourStay.trips.map((trip) => {
            return {
              description: trip.description,
              attractions: trip.attractions,
            };
          }),
        };
      });

      const bookingData = {
        bookingType: RESERVATION_TYPES.TOUR.toLowerCase(),
        startDate: tourStartDate,
        endDate: tourEndDate,
        adults: parseInt(adults),
        children: parseInt(children),
        tourData: tourData,
        pickupLocation: pickupLocation,
        selectedVehicleCategory: selectedTransportationKey.key,
        driverIncluded: true,
        bookables: [
          // { id: null, quantity: 1, type: "Tour", tourData: tour },
          ...bookedStays,
        ],
      };
      return createBooking(bookingData);
    },
    {
      onSuccess: (data) => {
        toast({
          title: "Booking created.",
          description: "Booking created successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        navigate(`/book/${data.data.uuid}`);
      },
      onError: (error) => {
        toast({
          title: "Error.",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      },
    }
  );

  const bookTour = () => {
    createBookingMutation.mutate();
  };

  return (
    <Flex direction="column">
      <ReservationTabs
        selectedTab={RESERVATION_TYPES.TOUR}
        forHomePage={false}
        isFetching={false}
        onSearchButtonClick={() => {}}
      />
      {tour && (
        <h1 style={{ fontSize: 24, alignSelf: "flex-start" }}>
          {getTourName(tour, tourDuration)}
        </h1>
      )}
      {!tour && (
        <VStack alignSelf="flex-start" gap={4}>
          <Text alignSelf="flex-start" whiteSpace="nowrap">
            Choose stays for your tour:{" "}
          </Text>
          {tourStays.map((stay, index) => {
            return (
              <>
                {index === Object.keys(tourStays).length - 1 &&
                  uniqueCitiesInTour.length < 4 &&
                  totalStayDuration < tourDuration && (
                    <Button
                      alignSelf="flex-start"
                      size={"sm"}
                      variant="outline"
                      colorScheme="teal"
                      leftIcon={<AddIcon />}
                      onClick={() => {
                        const newTourStays = [...tourStays];
                        newTourStays.splice(staysCount - 1, 0, {
                          city: _.differenceBy(CITIES, uniqueCitiesInTour)[0],
                          duration: 1,
                        });
                        setTourStays(newTourStays);
                      }}
                    >
                      Add stay
                    </Button>
                  )}
                <HStack
                  w="full"
                  key={index}
                  gap={4}
                  alignSelf="flex-start"
                  wrap={"wrap"}
                >
                  <Text w="120px" as="span">{`${formattedDateString(
                    stayPeriods[index].startDate
                  )} - ${formattedDateString(
                    stayPeriods[index].endDate
                  )}`}</Text>
                  <Select
                    w="300px"
                    value={stay.city}
                    onChange={(selected) => {
                      const selectedCity = selected.target.value;
                      const newTourStays = tourStays.map((s, i) =>
                        s.city === selectedCity && s.city !== "Sarajevo"
                          ? { ...s, city: stay.city }
                          : s
                      );

                      setTourStays(
                        newTourStays.map((s, i) =>
                          i === index ? { ...s, city: selectedCity } : s
                        )
                      );
                    }}
                  >
                    {CITIES.map((city) => (
                      <option
                        disabled={
                          ((index === 0 || index === staysCount - 1) &&
                            city !== "Sarajevo") ||
                          tourStays
                            .map((s) => s.city)
                            .slice(0, index)
                            .includes(city)
                        }
                        key={city}
                        value={city}
                      >
                        {city}
                      </option>
                    ))}
                  </Select>
                  <HStack gap={2}>
                    <Button
                      size="sm"
                      colorScheme="teal"
                      variant="outline"
                      isDisabled={
                        stay.duration <= 1 ||
                        (stay.city === "Bihac" && stay.duration <= 2)
                      }
                      onClick={() => {
                        //var currentDuration = -1;
                        //tourStays.forEach((stay, index) => {
                        //currentDuration += stay.duration;
                        //});
                        //if (currentDuration !== tourDuration)
                        //setAllDaysPicked(false);
                        //else setAllDaysPicked(true);
                        setTourStays(
                          tourStays.map((s, i) =>
                            i === index ? { ...s, duration: s.duration - 1 } : s
                          )
                        );
                      }}
                    >
                      -
                    </Button>
                    <Text minW="28px" textAlign="center" as="span">
                      {stay.duration}
                    </Text>
                    <Button
                      size="sm"
                      colorScheme="teal"
                      variant="outline"
                      isDisabled={
                        stay.duration +
                          1 +
                          tourStays
                            .filter((s, i) => i !== index)
                            .reduce((acc, s) => acc + s.duration, 0) >
                        tourDuration
                      }
                      onClick={() => {
                        //var currentDuration = 1;
                        //tourStays.forEach((stay, index) => {
                        //currentDuration += stay.duration;
                        //});
                        //if (currentDuration !== tourDuration)
                        //setAllDaysPicked(false);
                        //else //setAllDaysPicked(true);
                        setTourStays(
                          tourStays.map((s, i) =>
                            i === index ? { ...s, duration: s.duration + 1 } : s
                          )
                        );
                      }}
                    >
                      +
                    </Button>
                  </HStack>
                  <Text as="span">{`day${
                    stay.duration === 1 ? "" : "s"
                  }`}</Text>
                  {index !== 0 &&
                    index !== Object.keys(tourStays).length - 1 && (
                      <Button
                        variant="link"
                        colorScheme="red"
                        onClick={() => {
                          setTourStays(tourStays.filter((s, i) => i !== index));
                        }}
                      >
                        Remove stay
                      </Button>
                    )}
                </HStack>
              </>
            );
          })}
          <Button
            variant="solid"
            //isDisabled={!allDaysPicked}
            colorScheme="teal"
            onClick={() => {
              /*toast({
                title: "Invalid tour",
                description: `Tour duration must be ${tourDuration} days.`,
                status: "error",
                duration: 5000,
                isClosable: true,
              });
              return;
              */
              setTour(makeTourFromStays(tourStays, tourStartDate, tourEndDate));
            }}
          >
            Submit Tour
          </Button>
          {
            //!allDaysPicked && (
            /*(<Text textColor={"red.300"}>
              You need to fill all days for the tour, change dates if you dont
              want current set of days!
            </Text>
          )*/
          }
        </VStack>
      )}
      {tour && (
        <>
          <Button
            w={{ base: "full", lg: "30%" }}
            alignSelf={"center"}
            marginTop={"20px"}
            marginBottom={"20px"}
            onClick={() => setTour(null)}
          >
            {"<- Change Tour"}
          </Button>
          <Accordion
            allowToggle
            w="full"
            defaultIndex={[0]}
            // index={openIndex}
            // onChange={(index) => setOpenIndex(index)}
          >
            {tour.map((tourGroup, tourStayindex) => {
              const tourGroupWithStaysAsArray = {
                ...tourGroup,
                stay: Array.isArray(tourGroup.stay)
                  ? tourGroup.stay
                  : [{ id: null, name: null }],
              };
              const TripStack = () => (
                <VStack>
                  {tourGroup.trips.map((trip, index) => (
                    <TripCard
                      key={index}
                      trip={trip}
                      onChangeTrip={() => {
                        setCurrentTripData({
                          trip: trip,
                          selectedTripIndex: index,
                          selectedStayIndex: tourStayindex,
                        });
                        onTripChangeModalOpen();
                      }}
                    />
                  ))}
                  <ChooseStayMenu
                    key={tourStayindex}
                    tourStay={tourGroupWithStaysAsArray}
                    onStaySelect={(stay) => {
                      setTour(
                        tour.map((tourStay, index) => {
                          if (index === tourStayindex) {
                            return {
                              ...tourStay,
                              stay: stay,
                              submitted: true,
                            };
                          } else {
                            return tourStay;
                          }
                        })
                      );
                    }}
                  />
                </VStack>
              );

              return (
                <AccordionItem key={tourStayindex}>
                  {({ isExpanded }) => (
                    <>
                      <h2>
                        <AccordionButton
                          h="64px"
                          _expanded={{
                            borderRadius: "6px",
                            borderBottomRightRadius: "0",
                            borderBottomLeftRadius: "0",
                            borderWidth: "2px",
                            borderColor: "teal.500",
                            borderBottom: "none",
                          }}
                        >
                          <Badge colorScheme="blue" mr={4}>
                            {tourGroup.destination}
                          </Badge>
                          <Box
                            textAlign="left"
                            as="span"
                            flex="1"
                          >{`${formattedDateString(
                            tourGroup.startDate
                          )} - ${formattedDateString(tourGroup.endDate)}`}</Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel
                        pb={4}
                        {...(isExpanded && {
                          borderRadius: "6px",
                          borderTopLeftRadius: "0",
                          borderTopRightRadius: "0",
                          borderWidth: "2px",
                          borderColor: "teal.500",
                          borderTop: "none",
                        })}
                      >
                        <TripStack />
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              );
            })}
          </Accordion>
        </>
      )}
      {/* <ChangeTripModal
        isOpen={isTripChangeModalOpen}
        onClose={() => {
          setCurrentTripData({
            trip: {},
            selectedStayIndex: null,
            selectedReplacementIndex: null,
          });
          onTripChangeModalClose();
        }}
        trip={currentTripData.trip}
        onSelectTrip={(trip) => {
          console.log("selected trip", trip);
        }}
      /> */}
      <VStack alignItems="unset" gap={4} w="full" marginTop={"20px"}>
        <Text whiteSpace="nowrap">
          Choose your transportation for this tour:{" "}
        </Text>
        {vehicleCategoriesQuery.isLoading ? (
          <Spinner />
        ) : (
          <Grid
            templateColumns={{
              base: "repeat(1, 1fr)",
              md: "repeat(2, 1fr)",
              lg: "repeat(3, 1fr)",
            }}
            gap={2}
          >
            {vehicleCategoriesQuery.data?.map((vehicleCategory, index) => {
              const categoryDisabled =
                parseInt(vehicleCategory.seats) < adults + children.length;
              return (
                <GridItem w="full" key={index}>
                  <Card
                    cursor="pointer"
                    variant="outline"
                    borderWidth={2}
                    onClick={() => {
                      if (categoryDisabled) return;
                      setSelectedTransportationKey(vehicleCategory);
                    }}
                    {...(selectedTransportationKey.seats ===
                      vehicleCategory.seats && {
                      border: "2px solid teal",
                    })}
                    {...(categoryDisabled && {
                      opacity: 0.3,
                      cursor: "not-allowed",
                    })}
                  >
                    {selectedTransportationKey.seats ===
                      vehicleCategory.seats && (
                      <CheckIcon
                        position="absolute"
                        top={2}
                        right={2}
                        color="teal"
                      />
                    )}
                    <CardBody padding={6}>
                      <VStack gap={1}>
                        <Text
                          fontSize="xl"
                          fontWeight="bold"
                          alignSelf="flex-start"
                          padding={0}
                        >
                          {vehicleCategory.name}
                        </Text>
                        <Text
                          fontStyle="italic"
                          alignSelf="flex-start"
                          padding={0}
                        >
                          {`up to ${vehicleCategory.seats} passengers`}
                        </Text>
                      </VStack>
                    </CardBody>
                  </Card>
                </GridItem>
              );
            })}
          </Grid>
        )}
        {/*<Text>Pickup time</Text>
        <Select
          style={{ paddingLeft: "2.5rem" }}
          isRequired
          placeholder="Pick-up time"
          value={pickUpTime}
          onChange={(e) => setPickUpTime(e.target.value)}
        >
          {pickUpTimeInputOptions.map((time, tid) => (
            <option key={`time-${tid}`} value={time}>
              {time}
            </option>
          ))}
        </Select>
        */}
        <Text>Pickup location</Text>
        <Select
          defaultValue={pickupLocation}
          style={{ paddingLeft: "2.5rem" }}
          width={"full"}
          value={pickupLocation}
          onChange={(e) => {
            setPickupLocation(e.target.value);
          }}
        >
          {[SARAJEVO_INTL_AIRPORT /*, ...STAY_CITIES*/].map((city, index) => (
            <option key={index} value={city}>
              {city}
            </option>
          ))}
        </Select>
      </VStack>
      <HStack marginTop={"30px"} w="full" justifyContent="flex-end">
        <Button
          colorScheme="teal"
          onClick={() => bookTour()}
          isDisabled={_.isEmpty(tour)}
          isLoading={createBookingMutation.isLoading}
        >
          Book
        </Button>
      </HStack>
      <Modal isOpen={isTripChangeModalOpen} onClose={onTripChangeModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Choose a Trip</ModalHeader>
          <ModalCloseButton />
          <ModalBody padding={4}>
            <VStack gap={4}>
              {!_.isEmpty(currentTripData.trip) &&
                (currentTripData?.trip?.replacements || []).map(
                  (replacement, index) => {
                    const replacementDisabled =
                      tour[currentTripData.selectedStayIndex].trips.some(
                        (trip) =>
                          trip.description === replacement.description &&
                          trip.attractions === replacement.attractions
                      ) || false;
                    const replacementSelected =
                      currentTripData.selectedReplacementIndex === index ||
                      false;

                    return (
                      <Card
                        cursor="pointer"
                        variant="outline"
                        key={index}
                        w="full"
                        borderWidth={2}
                        onClick={() => {
                          if (replacementDisabled) return;
                          setCurrentTripData({
                            ...currentTripData,
                            selectedReplacementIndex: index,
                          });
                        }}
                        {...(replacementSelected && {
                          border: "2px solid teal",
                        })}
                        {...(replacementDisabled && {
                          opacity: 0.3,
                          cursor: "not-allowed",
                        })}
                      >
                        {replacementSelected && (
                          <CheckIcon
                            position="absolute"
                            top={2}
                            right={2}
                            color="teal"
                          />
                        )}
                        <CardBody padding={6}>
                          <VStack gap={6}>
                            <Text alignSelf="flex-start" padding={0}>
                              {replacement.description}
                            </Text>
                            <Flex
                              gap={2}
                              flexWrap="wrap"
                              alignSelf="flex-start"
                            >
                              {replacement.attractions.map((attr, index) => (
                                <Badge
                                  variant="outline"
                                  colorScheme="yellow"
                                  key={index}
                                >
                                  {attr}
                                </Badge>
                              ))}
                            </Flex>
                          </VStack>
                        </CardBody>
                      </Card>
                    );
                  }
                )}
              <Button
                w="full"
                colorScheme="teal"
                variant="solid"
                onClick={() => {
                  //if no available trips, close modal
                  if (
                    tour[currentTripData.selectedStayIndex].trips[
                      currentTripData.selectedTripIndex
                    ].replacements[currentTripData.selectedReplacementIndex] ===
                    undefined
                  ) {
                    onTripChangeModalClose();
                    return;
                  }
                  let newTour = tour.map((tourGroup, index) => {
                    if (index === currentTripData.selectedStayIndex) {
                      return {
                        ...tourGroup,
                        trips: tourGroup.trips.map((trip, index) => {
                          if (index === currentTripData.selectedTripIndex) {
                            return {
                              ...tour[currentTripData.selectedStayIndex].trips[
                                currentTripData.selectedTripIndex
                              ],
                              description:
                                tour[currentTripData.selectedStayIndex].trips[
                                  currentTripData.selectedTripIndex
                                ].replacements[
                                  currentTripData.selectedReplacementIndex
                                ].description,
                              attractions:
                                tour[currentTripData.selectedStayIndex].trips[
                                  currentTripData.selectedTripIndex
                                ].replacements[
                                  currentTripData.selectedReplacementIndex
                                ].attractions,
                              replacements:
                                tour[currentTripData.selectedStayIndex].trips[
                                  currentTripData.selectedTripIndex
                                ].replacements,
                            };
                          } else {
                            return trip;
                          }
                        }),
                      };
                    } else {
                      return tourGroup;
                    }
                  });
                  setCurrentTripData({
                    ...currentTripData,
                    trip: {},
                    selectedTripIndex: null,
                    selectedStayIndex: null,
                    selectedReplacementIndex: null,
                  });
                  setTour(newTour);
                  onTripChangeModalClose();
                }}
                isDisabled={false}
              >
                Save
              </Button>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default TourBuilder;
