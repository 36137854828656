import React from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer>
      Fursatook Bosnia 2024 | <Link to="/privacy_policy">Privacy policy</Link> |{" "}
      <Link to="/terms_of_use">Terms of use</Link>
    </footer>
  );
}
