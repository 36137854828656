import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import englishTranslationHome from "./locales/en/home.json";
import arabicTranslationHome from "./locales/ar/home.json";
import englishTranslationCommon from "./locales/en/common.json";
import arabicTranslationCommon from "./locales/ar/common.json";
import englishTranslationAccommodation from "./locales/en/accommodation.json";
import arabicTranslationAccommodation from "./locales/ar/accommodation.json";
import englishTranslationVehicle from "./locales/en/vehicle.json";
import arabicTranslationVehicle from "./locales/ar/vehicle.json";

const resources = {
  en: {
    home: englishTranslationHome,
    common: englishTranslationCommon,
    accommodation: englishTranslationAccommodation,
    vehicle: englishTranslationVehicle,
  },
  ar: {
    home: arabicTranslationHome,
    common: arabicTranslationCommon,
    accommodation: arabicTranslationAccommodation,
    vehicle: arabicTranslationVehicle,
  },
};

const lang = localStorage.getItem("lang") || "en";
document.body.dir = lang === "ar" ? "rtl" : "ltr";

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem("lang") || "en",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
