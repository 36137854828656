import {
  Stack,
  InputGroup,
  Radio,
  RadioGroup,
  Button,
  useToast,
  Center,
  Heading,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { invite } from "../../services/authService";
import { useMutation } from "@tanstack/react-query";
import { ROLES } from "../../constants";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { phoneNumberInputStyleInherit } from "../../styles/phoneNumberInputStyleInherit";
import "../../styles/phoneNumberInput.scss";

function InviteUser() {
  const { t } = useTranslation("common");
  const toast = useToast();

  const [inviteeData, setInviteeData] = useState({
    telephoneNumber: "",
    userType: ROLES.DOMESTIC_PARTNER,
  });

  const mutation = useMutation({
    mutationFn: () => {
      return invite({
        ...inviteeData,
        telephoneNumber: inviteeData.telephoneNumber,
      });
    },
    onError: (error) => {
      toast({
        title: error.message,
        status: "error",
      });
    },
    onSuccess: (data) => {
      setInviteeData({ ...inviteeData, telephoneNumber: "" });
      toast({
        title: t("inviteSuccessMessage"),
        status: "success",
      });
    },
  });

  return (
    <Center>
      <Stack spacing={3} width={"400px"}>
        <Heading size={"lg"} mb={8}>
          {t("inviteUserHeading")}
        </Heading>
        <InputGroup>
          <PhoneInput
            style={phoneNumberInputStyleInherit}
            placeholder={t("phoneNumberInputPlaceholder")}
            value={inviteeData.telephoneNumber}
            //defaultCountry="BA"
            onChange={(e) =>
              setInviteeData({ ...inviteeData, telephoneNumber: e })
            }
          />
        </InputGroup>
        <RadioGroup
          name="choose-partner"
          onChange={(nextValue) =>
            setInviteeData({ ...inviteeData, userType: nextValue })
          }
          value={inviteeData.userType}
          defaultChecked
        >
          <Stack direction="row">
            <Radio value={ROLES.DOMESTIC_PARTNER}>{t("domesticPartner")}</Radio>
            <Radio value={ROLES.FOREIGN_PARTNER}>{t("foreignPartner")}</Radio>
          </Stack>
        </RadioGroup>
        <Button
          colorScheme="whatsapp"
          isLoading={mutation.isLoading}
          onClick={mutation.mutate}
        >
          {t("invite")}
        </Button>
      </Stack>
    </Center>
  );
}

export default InviteUser;
