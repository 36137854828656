import axiosInstance from "./axiosInstance";

const createAccommodation = (accommodationData) =>
  axiosInstance.post("/accommodations", accommodationData);

const submitPhotosToAccommodation = (accommodationId, images) =>
  axiosInstance.post(
    `/accommodations/${accommodationId}/google_drive_images`,
    images,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        "Key-Inflection": "camel",
      },
    }
  );

const getAccommodationBySlug = (slug, start_date, end_date) =>
  axiosInstance.get(`/accommodations/${slug}`, {
    params: {
      start_date,
      end_date,
    },
  });

const getRecommendedUnitsForAccommodation = (slug, searchParams) =>
  axiosInstance.get(`/accommodations/${slug}/recommended_units`, {
    params: searchParams,
  });

const updateAccommodation = (accommodationData) =>
  axiosInstance.patch(
    `/accommodations/${accommodationData.id}`,
    accommodationData
  );

const fetchAccommodations = () => axiosInstance.get("/accommodations");

const fetchAccommodationsByUser = (page) =>
  axiosInstance.get(`/accommodations/index_for_user`, {
    params: page,
  });

const searchAccommodations = (searchParams) =>
  axiosInstance.get("/accommodations/search", {
    params: searchParams,
  });

// const searchAccommodations = (searchQueryParamsString) =>
//   axiosInstance.get(`/accommodations/search?${searchQueryParamsString}`);

export {
  createAccommodation,
  submitPhotosToAccommodation,
  getAccommodationBySlug,
  updateAccommodation,
  fetchAccommodations,
  fetchAccommodationsByUser,
  searchAccommodations,
  getRecommendedUnitsForAccommodation,
};
