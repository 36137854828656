import React, { useState } from "react";
import {
  Input,
  Stack,
  InputGroup,
  InputRightElement,
  Button,
  Center,
  Heading,
  useToast,
  FormControl,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { signIn } from "../../services/authService";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../contexts/AuthContext";

function Login() {
  const { t } = useTranslation("common");
  const toast = useToast();
  const navigate = useNavigate();
  const { login } = useAuth();

  const [showPassword, setShowPassword] = useState(false);

  const mutation = useMutation({
    mutationFn: (loginCredentials) => {
      return signIn(loginCredentials);
    },
    onError: (error) => {
      toast({
        title: error.response.data.errors[0],
        status: "error",
      });
    },
    onSuccess: (data) => {
      let { id, email, firstName, lastName, type, emailConfirmed, telephoneNumber } =
        data.data.data;
      login({ id, email, firstName, lastName, type, emailConfirmed, telephoneNumber });
      navigate("/");
    },
  });

  return (
    <Center>
      <Stack spacing={3} width={"400px"}>
        <Heading size={"lg"} mb={8}>
          {t("loginHeading")}
        </Heading>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={Yup.object({
            email: Yup.string()
              .email(t("invalidEmailMessage"))
              .required(t("requiredFieldMessage")),
            password: Yup.string().required(t("requiredFieldMessage")),
          })}
          onSubmit={(values) => {
            mutation.mutate(values);
          }}
        >
          {(props) => (
            <Form>
              <Stack spacing={3}>
                <Field name="email">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.email && form.touched.email}
                    >
                      <Input
                        {...field}
                        placeholder={t("emailInputPlaceholder")}
                        variant="filled"
                      />
                      <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="password">
                  {({ field, form }) => {
                    return (
                      <FormControl
                        isInvalid={
                          form.errors.password && form.touched.password
                        }
                      >
                        <InputGroup size="md">
                          <Input
                            {...field}
                            variant="filled"
                            pr="4.5rem"
                            type={showPassword ? "text" : "password"}
                            placeholder={t("passwordInputPlaceholder")}
                          />
                          <InputRightElement width="4.5rem">
                            <Button
                              colorScheme="gray"
                              h="1.75rem"
                              size="sm"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword
                                ? t("hidePasswordButtonText")
                                : t("showPasswordButtonText")}
                            </Button>
                          </InputRightElement>
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.password}
                        </FormErrorMessage>
                      </FormControl>
                    );
                  }}
                </Field>
                <Button
                  width="full"
                  isLoading={mutation.isLoading}
                  type="submit"
                  colorScheme="whatsapp"
                  mt={4}
                >
                  {t("login")}
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      </Stack>
    </Center>
  );
}

export default Login;
