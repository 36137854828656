import React, { useState, useEffect } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useMatch } from "react-router-dom";
import axiosInstance from "../../services/axiosInstance";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";

const Checkout = ({ showPreviousButton, nextStep, activeStep }) => {
  const [success, setSuccess] = useState(false);
  const match = useMatch("/book/:bookingId");
  const bookingId = match?.params.bookingId;

  const createOrder = () => {
    return axiosInstance
      .post(`/bookings/${bookingId}/create_paypal_order`)
      .then((response) => {
        return response.data.order.id; // Assuming the order ID is returned in the response data
      })
      .catch((error) => {
        //console.error("Error creating PayPal order:", error);
        throw error; // Optionally rethrow the error to handle it further up the chain
      });
  };

  const onApprove = (orderId) => {
    // Order is captured on the server and the response is returned to the browser
    return axiosInstance
      .post(`/bookings/${bookingId}/paypal/success`, {
        orderId: orderId,
      })
      .then((response) => {
        //console.log("Payment successful!!", response);
        setSuccess(true);
        return response;
      })
      .catch((error) => {
        //console.error("Error capturing PayPal order:", error);
        throw error; // Optionally rethrow the error to handle it further up the chain
      });
  };

  useEffect(() => {
    if (success) {
      //alert("Payment successful!!");
      showPreviousButton(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  return (
    <PayPalScriptProvider
      options={{
        clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
        currency: "EUR",
      }}
    >
      {!success ? (
        <div>
          <br></br>
          <PayPalButtons
            style={{ layout: "vertical" }}
            createOrder={createOrder}
            onApprove={onApprove}
          />
        </div>
      ) : (
        <div>
          {<Alert
            borderRadius="lg"
            status="success"
            variant="subtle"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            height="200px"
          >
            <AlertIcon boxSize="40px" mr={0} />
            <AlertTitle mt={4} mb={1} fontSize="lg">
              Reservation completed!
            </AlertTitle>
            <AlertDescription fontSize={"sm"}>
              Thanks for submitting your reservation. Our team will get back to
              you soon via email and WhatsApp.
            </AlertDescription>
          </Alert>}
        </div>
      )}
    </PayPalScriptProvider>
  );
};

export default Checkout;
