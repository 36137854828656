import React, { useState } from "react";
import { searchAccommodations } from "../../services/accommodations";
import { useQuery } from "@tanstack/react-query";
import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Divider,
  Flex,
  FormLabel,
  HStack,
  Input,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Show,
  Stack,
  VStack,
  useDisclosure,
  Text,
} from "@chakra-ui/react";
import AccommodationCard, {
  AccommodationCardSkeleton,
} from "../../components/accommodation-card/AccommodationCard";
import { Link, createSearchParams, useSearchParams } from "react-router-dom";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSliders, faFilter } from "@fortawesome/free-solid-svg-icons";
import ReservationTabs from "../../components/reservation-tabs/ReservationTabs";
import { RESERVATION_TYPES } from "../../constants";
import ClearFilterButton from "../../components/clear-filter/ClearFilterButton";
import Pagination from "../../components/pagination/Pagination";

function AccommodationsSearch() {
  const [accommodations, setAccommodations] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [shouldResetParams, setShouldResetParams] = useState(false);

  const params = Object.fromEntries(searchParams.entries());
  if (params.children) {
    params.children = searchParams.getAll("children");
  }
  const amenitiesParams = searchParams.getAll("amenities");
  const typesParams = searchParams.getAll("types");

  const { data, isFetching } = useQuery(
    ["searchAccommodations", params, currentPage],
    () =>
      searchAccommodations({
        ...params,
        page: currentPage,
        amenities: amenitiesParams.map((amenity) => _.snakeCase(amenity)) || [],
        types: typesParams.map((type) => _.capitalize(type)) || [],
      }),
    {
      refetchOnWindowFocus: false,
      select: (payload) => payload.data,
      onSuccess: (newData) => {
        if (shouldResetParams || currentPage === 1) {
          setShouldResetParams(false);
          setAccommodations(newData.results);
        } else {
          setAccommodations([...accommodations, ...newData.results]);
        }
      },
    }
  );

  const renderSkeleton = (length = 3) => {
    return [...Array(length)].map((_item, index) => (
      <AccommodationCardSkeleton key={index} />
    ));
  };

  const renderAccommodations = () => {
    return (
      <Pagination
        isFetching={isFetching}
        hasNextPage={data?.pagination.nextPage !== null ? true : false}
        onShowMore={() => setCurrentPage(currentPage + 1)}
      >
        <VStack gap={5}>
          {accommodations?.map((accommodation) => (
            <Link
              style={{ display: "contents" }}
              key={accommodation.id}
              to={{
                pathname: `/${accommodation.type.toLowerCase()}/${
                  accommodation.slug
                }`,
                search: `?${createSearchParams({
                  ...params,
                  children: searchParams.getAll("children"),
                })}`,
              }}
            >
              <AccommodationCard data={accommodation} />
            </Link>
          ))}
        </VStack>
      </Pagination>
    );
  };

  const allAmenities = [
    "swimmingPool",
    "wiFi",
    "parking",
    "airConditioning",
    "heating",
    "flatScreenTv",
    "wheelchairAccessible",
  ];

  const allTypes = ["villa", "hotel", "apartment"];

  //filters modal
  const { isOpen, onOpen, onClose } = useDisclosure();

  function Filters({ width = "250px" }) {
    const [filters, setFilters] = React.useState({
      amenities: Object.fromEntries(
        allAmenities.map((amenity) => [
          amenity,
          amenitiesParams.includes(amenity),
        ])
      ),
      name: params.name || "",
      types: Object.fromEntries(
        allTypes.map((type) => [type, typesParams.includes(type)])
      ),
    });

    function makeTypesFilterText() {
      let text = Object.keys(filters.types)
        .filter((type) => filters.types[type])
        .map((type) => _.startCase(type))
        .join(", ");
      return text
        ? _.truncate(text, { length: 25, omission: "..." })
        : "Select...";
    }

    return (
      <VStack width={width} spacing={8}>
        <Box width={"100%"}>
          <FormLabel size={"sm"}>Search by property name</FormLabel>
          <Input
            value={filters.name}
            onChange={(e) => {
              setFilters({
                ...filters,
                name: e.target.value,
              });
            }}
          />
        </Box>
        <Divider />
        <Box width={"100%"}>
          <FormLabel size={"sm"}>Filter by type</FormLabel>
          <Menu closeOnSelect={false}>
            <MenuButton
              variant="outline"
              as={Button}
              colorScheme="teal"
              width={"100%"}
            >
              {makeTypesFilterText()}
            </MenuButton>
            <MenuList minWidth="240px">
              <MenuOptionGroup
                value={Object.keys(filters.types).filter(
                  (type) => filters.types[type]
                )}
                type="checkbox"
              >
                {Object.keys(filters.types).map((type) => (
                  <MenuItemOption
                    key={type}
                    value={type}
                    onClick={(e) => {
                      setFilters({
                        ...filters,
                        types: {
                          ...filters.types,
                          [e.target.innerText.toLowerCase()]:
                            !filters.types[e.target.innerText.toLowerCase()],
                        },
                      });
                    }}
                  >
                    {_.startCase(type)}
                  </MenuItemOption>
                ))}
              </MenuOptionGroup>
            </MenuList>
          </Menu>
        </Box>
        <Divider />
        <Box width={"100%"}>
          {/* <FormLabel size={"sm"}>Filter by amenities</FormLabel> */}
          <CheckboxGroup colorScheme="teal">
            <Stack spacing={[1, 5]} direction={["column"]}>
              {Object.keys(filters.amenities).map((amenity) => (
                <Checkbox
                  isChecked={filters.amenities[amenity]}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      amenities: {
                        ...filters.amenities,
                        [amenity]: e.target.checked,
                      },
                    })
                  }
                  key={amenity}
                >
                  {_.startCase(amenity)}
                </Checkbox>
              ))}
            </Stack>
          </CheckboxGroup>
        </Box>
        <Divider />
        <Button
          leftIcon={<FontAwesomeIcon icon={faFilter} />}
          colorScheme="teal"
          width={"100%"}
          onClick={() => {
            if (isOpen) {
              onclose();
            }
            setCurrentPage(1);
            setSearchParams({
              ...params,
              name: filters.name,
              amenities: Object.keys(filters.amenities).filter(
                (amenity) => filters.amenities[amenity]
              ),
              types: Object.keys(filters.types).filter(
                (type) => filters.types[type]
              ),
            });
          }}
        >
          Apply filters
        </Button>
        {(amenitiesParams.length > 0 ||
          typesParams.length > 0 ||
          params.name) && (
          <ClearFilterButton
            variant={"link"}
            onClick={() => {
              setCurrentPage(1);
              setSearchParams({
                location: params.location,
                start_date: params.start_date,
                end_date: params.end_date,
                pick_up_time: params.pickUpTime,
                adults: params.adults,
                children: searchParams.getAll("children"),
              });
            }}
          />
        )}
      </VStack>
    );
  }

  const handleSearchButtonClick = () => {
    setCurrentPage(1);
    setShouldResetParams(true);
  };

  return (
    <Flex direction="column">
      <ReservationTabs
        selectedTab={RESERVATION_TYPES.STAY}
        forHomePage={false}
        isFetching={shouldResetParams && isFetching}
        onSearchButtonClick={handleSearchButtonClick}
      />
      <Show below="md">
        {isOpen && <Filters />}
        <Button
          mt={"-35px"}
          mb={"35px"}
          width="full"
          leftIcon={<FontAwesomeIcon icon={faSliders} />}
          onClick={onOpen}
        >
          Sort & Filter
        </Button>
        <Modal onClose={onClose} size={"full"} isOpen={isOpen}>
          <ModalOverlay />
          <ModalContent padding={"25px"}>
            <ModalCloseButton />
            <Filters width="full" />
          </ModalContent>
        </Modal>
      </Show>

      <HStack align="flex-start" spacing="16">
        <Show above="md">
          <Filters />
        </Show>
        <VStack
          w="100%"
          direction="column"
          spacing="16px"
          alignItems="flex-start"
        >
          {isFetching && currentPage === 1
            ? renderSkeleton(4)
            : renderAccommodations()}
          {accommodations?.length === 0 && !isFetching && (
            <Text>No accommodations found with selected filters</Text>
          )}
        </VStack>
      </HStack>
    </Flex>
  );
}

export default AccommodationsSearch;
