import {
  Button,
  Card,
  CardBody,
  HStack,
  Text,
  VStack,
  Heading,
  Icon,
  useMediaQuery,
  Flex,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import ImageGallery from "react-image-gallery";
import "./VehicleCard.scss";
import { FullScreenButton } from "../../pages/accommodation/Accommodation";
import { useState } from "react";
import { capitalize } from "../../utils/helpers";

import { TbAutomaticGearbox } from "react-icons/tb";
import { MdAirlineSeatReclineExtra } from "react-icons/md";
import { BsFuelPumpFill } from "react-icons/bs";
import { FaCar } from "react-icons/fa6";

const CARD_WIDTH = { base: "100%", lg: "700px" };

const VehicleCard = ({ vehicle, onSelect }) => {
  const {
    id: vehicleId,
    name,
    numberOfSeats,
    vehicleType,
    fuelType,
    automatic,
    productionYear,
    pricePerDay,
    totalPrice,
    images,
  } = vehicle;
  const { t, i18n } = useTranslation("common");
  const [isFullscreen, setIsFullscreen] = useState(false);
  const galleryImages = images.map(({ url }) => ({
    original: url,
    thumbnail: url,
    originalClass: isFullscreen ? "" : "vehicle-gallery-image",
  }));
  const [isLargerThan648] = useMediaQuery("(min-width: 648px)");
  const [isMakingReservation, setIsMakingReservation] = useState(false);

  const ReserveCarButton = ({ onClick }) => (
    <Button
      isLoading={isMakingReservation}
      onClick={onClick}
      colorScheme="teal"
      variant="solid"
    >
      {t("reserveButtonText")}
    </Button>
  );

  const CarPriceDetails = ({ pricePerDay, totalPrice }) => (
    <VStack alignItems="flex-end" gap={4}>
      <Text p={0} fontSize="2xl" fontWeight="bold">
        {pricePerDay}
      </Text>
      <Text p={0} mt="-16px" fontSize="sm">
        {`${totalPrice} total`}
      </Text>
    </VStack>
  );

  const CarDetailsStackComponent = ({ children, ...props }) => {
    if (isLargerThan648) {
      return (
        <VStack alignItems="flex-start" alignSelf="flex-start" {...props}>
          {children}
        </VStack>
      );
    } else {
      return (
        <HStack {...props} flexWrap="wrap">
          {children}
        </HStack>
      );
    }
  };

  /*
  const StackComponent = ({ children, ...props }) => {
    if (isLargerThan648) {
      return <HStack {...props}>{children}</HStack>;
    } else {
      return <VStack {...props}>{children}</VStack>;
    }
  };
  */

  return (
    <Card
      w={CARD_WIDTH}
      variant="outline"
      borderRadius="12px"
      overflow="hidden"
    >
      <CardBody as={VStack} padding={4} fontSize="small">
        <Heading fontSize="xl" mb={6} alignSelf={"flex-start"}>
          {`${name} (${productionYear})`}
        </Heading>
        <Flex
          justifyContent="space-between"
          gap={4}
          alignSelf="flex-start"
          flexDirection={isLargerThan648 ? "row" : "column"}
        >
          <ImageGallery
            items={galleryImages}
            showPlayButton={false}
            showThumbnails={isFullscreen}
            isRTL={i18n.language === "ar"}
            showIndex={isFullscreen}
            additionalClass={`vehicle-gallery ${
              isFullscreen ? "full-screen" : ""
            }`}
            onScreenChange={(isFullscreen) => setIsFullscreen(isFullscreen)}
            renderFullscreenButton={(onClick, _isFullscreen) => (
              <FullScreenButton
                label={galleryImages.length}
                onClick={onClick}
              />
            )}
          />
          <CarDetailsStackComponent alignSelf="flex-start">
            <HStack>
              <Icon as={FaCar}></Icon>
              <Text>{capitalize(vehicleType)}</Text>
            </HStack>
            <HStack>
              <Icon as={TbAutomaticGearbox} />
              <Text>{automatic ? "Automatic" : "Manual"}</Text>
            </HStack>
            <HStack>
              <Icon as={MdAirlineSeatReclineExtra}></Icon>
              <Text>{numberOfSeats} seats</Text>
            </HStack>
            <HStack>
              <Icon as={BsFuelPumpFill}></Icon>
              <Text>{capitalize(fuelType)}</Text>
            </HStack>
          </CarDetailsStackComponent>
          {/*isLargerThan648 && (
            <VStack marginTop={2.5} w="full">
              <CarPriceDetails
                pricePerDay={pricePerDay}
                totalPrice={totalPrice}
              />
              <ReserveCarButton
                onClick={() => {
                  setIsMakingReservation(true);
                  onSelect(vehicleId);
                }}
              />
            </VStack>
          )*/}
        </Flex>
        <HStack marginTop={2.5} w="full" justifyContent="space-between">
          <CarPriceDetails pricePerDay={pricePerDay} totalPrice={totalPrice} />
          <ReserveCarButton
            onClick={() => {
              setIsMakingReservation(true);
              onSelect(vehicleId);
            }}
          />
        </HStack>
      </CardBody>
    </Card>
  );
};

export default VehicleCard;
