import React from "react";
import {
  Box,
  Container,
  Heading,
  Text,
  List,
  ListItem,
  Link,
} from "@chakra-ui/react";

const PrivacyPolicy = () => {
  return (
    <Container maxW="container.md" mt={5} mb={5}>
      <Box p={5} bg="white" borderRadius="md" boxShadow="md">
        <Heading as="h1" size="xl" mb={4}>
          Privacy Policy
        </Heading>
        <Text mb={4}>Last updated: August 10, 2024</Text>

        <Text mb={4}>
          This Privacy Policy describes Our policies and procedures on the
          collection, use and disclosure of Your information when You use the
          Service and tells You about Your privacy rights and how the law
          protects You.
        </Text>
        <Text mb={4}>
          We use Your Personal data to provide and improve the Service. By using
          the Service, You agree to the collection and use of information in
          accordance with this Privacy Policy.
        </Text>

        <Heading as="h2" size="lg" mb={2}>
          Interpretation and Definitions
        </Heading>

        <Heading as="h3" size="md" mb={2}>
          Interpretation
        </Heading>
        <Text mb={4}>
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions. The following definitions
          shall have the same meaning regardless of whether they appear in
          singular or in plural.
        </Text>

        <Heading as="h3" size="md" mb={2}>
          Definitions
        </Heading>
        <List spacing={3} mb={4}>
          <ListItem>
            <strong>Account</strong> means a unique account created for You to
            access our Service or parts of our Service.
          </ListItem>
          <ListItem>
            <strong>Affiliate</strong> means an entity that controls, is
            controlled by or is under common control with a party, where
            "control" means ownership of 50% or more of the shares, equity
            interest or other securities entitled to vote for election of
            directors or other managing authority.
          </ListItem>
          <ListItem>
            <strong>Company</strong> (referred to as either "the Company", "We",
            "Us" or "Our" in this Agreement) refers to Fursatook Bosnia.
          </ListItem>
          <ListItem>
            <strong>Cookies</strong> are small files that are placed on Your
            computer, mobile device or any other device by a website, containing
            the details of Your browsing history on that website among its many
            uses.
          </ListItem>
          <ListItem>
            <strong>Country</strong> refers to: Bosnia & Herzegovina
          </ListItem>
          <ListItem>
            <strong>Device</strong> means any device that can access the Service
            such as a computer, a cellphone or a digital tablet.
          </ListItem>
          <ListItem>
            <strong>Personal Data</strong> is any information that relates to an
            identified or identifiable individual.
          </ListItem>
          <ListItem>
            <strong>Service</strong> refers to the Website.
          </ListItem>
          <ListItem>
            <strong>Service Provider</strong> means any natural or legal person
            who processes the data on behalf of the Company. It refers to
            third-party companies or individuals employed by the Company to
            facilitate the Service, to provide the Service on behalf of the
            Company, to perform services related to the Service or to assist the
            Company in analyzing how the Service is used.
          </ListItem>
          <ListItem>
            <strong>Usage Data</strong> refers to data collected automatically,
            either generated by the use of the Service or from the Service
            infrastructure itself (for example, the duration of a page visit).
          </ListItem>
          <ListItem>
            <strong>Website</strong> refers to Fursatook Bosnia, accessible from
            fursatook.com
          </ListItem>
          <ListItem>
            <strong>You</strong> means the individual accessing or using the
            Service, or the company, or other legal entity on behalf of which
            such individual is accessing or using the Service, as applicable.
          </ListItem>
        </List>

        <Heading as="h2" size="lg" mb={2}>
          Collecting and Using Your Personal Data
        </Heading>

        <Heading as="h3" size="md" mb={2}>
          Types of Data Collected
        </Heading>
        <Heading as="h4" size="sm" mb={2}>
          Personal Data
        </Heading>
        <Text mb={4}>
          While using Our Service, We may ask You to provide Us with certain
          personally identifiable information that can be used to contact or
          identify You. Personally identifiable information may include, but is
          not limited to:
        </Text>
        <List spacing={3} mb={4}>
          <ListItem>Email address</ListItem>
          <ListItem>First name and last name</ListItem>
          <ListItem>Phone number</ListItem>
          <ListItem>Usage Data</ListItem>
        </List>

        <Heading as="h4" size="sm" mb={2}>
          Usage Data
        </Heading>
        <Text mb={4}>
          Usage Data is collected automatically when using the Service. Usage
          Data may include information such as Your Device's Internet Protocol
          address (e.g. IP address), browser type, browser version, the pages of
          our Service that You visit, the time and date of Your visit, the time
          spent on those pages, unique device identifiers and other diagnostic
          data.
        </Text>
        <Text mb={4}>
          When You access the Service by or through a mobile device, We may
          collect certain information automatically, including, but not limited
          to, the type of mobile device You use, Your mobile device unique ID,
          the IP address of Your mobile device, Your mobile operating system,
          the type of mobile Internet browser You use, unique device identifiers
          and other diagnostic data.
        </Text>
        <Text mb={4}>
          We may also collect information that Your browser sends whenever You
          visit our Service or when You access the Service by or through a
          mobile device.
        </Text>

        <Heading as="h3" size="md" mb={2}>
          Tracking Technologies and Cookies
        </Heading>
        <Text mb={4}>
          We use Cookies and similar tracking technologies to track the activity
          on Our Service and store certain information. Tracking technologies
          used are beacons, tags, and scripts to collect and track information
          and to improve and analyze Our Service. The technologies We use may
          include:
        </Text>
        <List spacing={3} mb={4}>
          <ListItem>
            <strong>Cookies or Browser Cookies</strong>. A cookie is a small
            file placed on Your Device. You can instruct Your browser to refuse
            all Cookies or to indicate when a Cookie is being sent. However, if
            You do not accept Cookies, You may not be able to use some parts of
            our Service. Unless you have adjusted Your browser setting so that
            it will refuse Cookies, our Service may use Cookies.
          </ListItem>
          <ListItem>
            <strong>Web Beacons</strong>. Certain sections of our Service and
            our emails may contain small electronic files known as web beacons
            (also referred to as clear gifs, pixel tags, and single-pixel gifs)
            that permit the Company, for example, to count users who have
            visited those pages or opened an email and for other related website
            statistics (for example, recording the popularity of a certain
            section and verifying system and server integrity).
          </ListItem>
        </List>
        <Text mb={4}>
          Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies
          remain on Your personal computer or mobile device when You go offline,
          while Session Cookies are deleted as soon as You close Your web
          browser. Learn more about cookies on the Free Privacy Policy website
          article.
        </Text>
        <Text mb={4}>
          We use both Session and Persistent Cookies for the purposes set out
          below:
        </Text>
        <List spacing={3} mb={4}>
          <ListItem>
            <strong>Necessary / Essential Cookies</strong>
            <Text as="span" fontWeight="bold">
              Type:
            </Text>{" "}
            Session Cookies
            <br />
            <Text as="span" fontWeight="bold">
              Administered by:
            </Text>{" "}
            Us
            <br />
            <Text as="span" fontWeight="bold">
              Purpose:
            </Text>{" "}
            These Cookies are essential to provide You with services available
            through the Website and to enable You to use some of its features.
            They help to authenticate users and prevent fraudulent use of user
            accounts. Without these Cookies, the services that You have asked
            for cannot be provided, and We only use these Cookies to provide You
            with those services.
          </ListItem>
          <ListItem>
            <strong>Cookies Policy / Notice Acceptance Cookies</strong>
            <br />
            <Text as="span" fontWeight="bold">
              Type:
            </Text>{" "}
            Persistent Cookies
            <br />
            <Text as="span" fontWeight="bold">
              Administered by:
            </Text>{" "}
            Us
            <br />
            <Text as="span" fontWeight="bold">
              Purpose:
            </Text>{" "}
            These Cookies identify if users have accepted the use of cookies on
            the Website.
          </ListItem>
          <ListItem>
            <strong>Functionality Cookies </strong>
            <br />
            <Text as="span" fontWeight="bold">
              Type:
            </Text>{" "}
            Persistent Cookies
            <br />
            <Text as="span" fontWeight="bold">
              Administered by:
            </Text>{" "}
            Us
            <br />
            <Text as="span" fontWeight="bold">
              Purpose:
            </Text>{" "}
            These Cookies allow us to remember choices You make when You use the
            Website, such as remembering your login details or language
            preference. The purpose of these Cookies is to provide You with a
            more personal experience and to avoid You having to re-enter your
            preferences every time You use the Website.
          </ListItem>
        </List>

        <Heading as="h2" size="lg" mb={2}>
          Use of Your Personal Data
        </Heading>
        <List spacing={3} mb={4}>
          <ListItem>
            To provide and maintain our Service, including to monitor the usage
            of our Service.
          </ListItem>
          <ListItem>
            To manage Your Account: to manage Your registration as a user of the
            Service. The Personal Data You provide can give You access to
            different functionalities of the Service that are available to You
            as a registered user.
          </ListItem>
          <ListItem>
            For the performance of a contract: the development, compliance and
            undertaking of the purchase contract for the products, items or
            services You have purchased or of any other contract with Us through
            the Service.
          </ListItem>
          <ListItem>
            To contact You: To contact You by email, telephone calls, SMS, or
            other equivalent forms of electronic communication, such as a mobile
            application's push notifications regarding updates or informative
            communications related to the functionalities, products or
            contracted services, including the security updates, when necessary
            or reasonable for their implementation.
          </ListItem>
          <ListItem>
            To provide You with news, special offers and general information
            about other goods, services and events which we offer that are
            similar to those that you have already purchased or enquired about
            unless You have opted not to receive such information.
          </ListItem>
          <ListItem>
            To manage Your requests: To attend and manage Your requests to Us.
          </ListItem>
          <ListItem>
            For business transfers: We may use Your information to evaluate or
            conduct a merger, divestiture, restructuring, reorganization,
            dissolution, or other sale or transfer of some or all of Our assets,
            whether as a going concern or as part of bankruptcy, liquidation, or
            similar proceeding, in which Personal Data held by Us about our
            Service users is among the assets transferred.
          </ListItem>
          <ListItem>
            For other purposes: We may use Your information for other purposes,
            such as data analysis, identifying usage trends, determining the
            effectiveness of our promotional campaigns and to evaluate and
            improve our Service, products, services, marketing and your
            experience.
          </ListItem>
        </List>

        <Heading as="h2" size="lg" mb={2}>
          Disclosure of Your Personal Data
        </Heading>
        <List spacing={3} mb={4}>
          <ListItem>
            <strong>Business Transactions:</strong> If the Company is involved
            in a merger, acquisition or asset sale, Your Personal Data may be
            transferred. We will provide notice before Your Personal Data is
            transferred and becomes subject to a different Privacy Policy.
          </ListItem>
          <ListItem>
            <strong>Law enforcement:</strong> Under certain circumstances, the
            Company may be required to disclose Your Personal Data if required
            to do so by law or in response to valid requests by public
            authorities (e.g. a court or a government agency).
          </ListItem>
          <ListItem>
            <strong>Other legal requirements:</strong> The Company may disclose
            Your Personal Data in the good faith belief that such action is
            necessary to:
            <List spacing={2} mt={2}>
              <ListItem>Comply with a legal obligation</ListItem>
              <ListItem>
                Protect and defend the rights or property of the Company
              </ListItem>
              <ListItem>
                Prevent or investigate possible wrongdoing in connection with
                the Service
              </ListItem>
              <ListItem>
                Protect the personal safety of Users of the Service or the
                public
              </ListItem>
              <ListItem>Protect against legal liability</ListItem>
            </List>
          </ListItem>
        </List>

        <Heading as="h2" size="lg" mb={2}>
          Security of Your Personal Data
        </Heading>
        <Text mb={4}>
          The security of Your Personal Data is important to Us, but remember
          that no method of transmission over the Internet, or method of
          electronic storage is 100% secure. While We strive to use commercially
          acceptable means to protect Your Personal Data, We cannot guarantee
          its absolute security.
        </Text>

        <Heading as="h2" size="lg" mb={2}>
          Children's Privacy
        </Heading>
        <Text mb={4}>
          Our Service does not address anyone under the age of 13. We do not
          knowingly collect personally identifiable information from anyone
          under the age of 13. If You are a parent or guardian and You are aware
          that Your child has provided Us with Personal Data, please contact Us.
          If We become aware that We have collected Personal Data from anyone
          under the age of 13 without verification of parental consent, We take
          steps to remove that information from Our servers.
        </Text>
        <Text mb={4}>
          If We need to rely on consent as a legal basis for processing Your
          information and Your country requires consent from a parent, We may
          require Your parent's consent before We collect and use that
          information.
        </Text>

        <Heading as="h2" size="lg" mb={2}>
          Links to Other Websites
        </Heading>
        <Text mb={4}>
          Our Service may contain links to other websites that are not operated
          by Us. If You click on a third party link, You will be directed to
          that third party's site. We strongly advise You to review the Privacy
          Policy of every site You visit.
        </Text>
        <Text mb={4}>
          We have no control over and assume no responsibility for the content,
          privacy policies or practices of any third party sites or services.
        </Text>

        <Heading as="h2" size="lg" mb={2}>
          Changes to this Privacy Policy
        </Heading>
        <Text mb={4}>
          We may update Our Privacy Policy from time to time. We will notify You
          of any changes by posting the new Privacy Policy on this page.
        </Text>
        <Text mb={4}>
          We will let You know via email and/or a prominent notice on Our
          Service, prior to the change becoming effective and update the "Last
          updated" date at the top of this Privacy Policy.
        </Text>
        <Text mb={4}>
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </Text>

        <Heading as="h2" size="lg" mb={2}>
          Contact Us
        </Heading>
        <Text mb={4}>
          If you have any questions about this Privacy Policy, You can contact
          us:
        </Text>
        <List spacing={2} mb={4}>
          <ListItem>
            <strong>By email:</strong>{" "}
            <Link color="teal.500" href="mailto:fursatook.it@gmail.com">
              fursatook.it@gmail.com
            </Link>
          </ListItem>
        </List>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
