import { VEHICLE_TYPES, FUEL_TYPES } from "../../constants";
import { Field, Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import {
  FormControl,
  FormErrorMessage,
  Input,
  Stack,
  Heading,
  Button,
  NumberInput,
  NumberInputField,
  Select,
  FormLabel,
  Switch,
  Divider,
  HStack,
  Image,
  CloseButton,
  Box,
  InputGroup,
  Center,
} from "@chakra-ui/react";
import { useState } from "react";
import { useDropZoneUpload } from "../../utils/customHooks";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { phoneNumberInputStyles } from "../../styles/phoneNumberInputStyles";
import "../../styles/phoneNumberInput.scss";

function VehicleForm({
  editable = false,
  vehicleData = {},
  onSubmit,
  isSubmitting,
}) {
  const { t } = useTranslation(["common", "vehicle"]);

  const {
    selectedFiles,
    cleanBuffer,
    getRootProps,
    getInputProps,
    handleRemoveFile,
  } = useDropZoneUpload(vehicleData.images);

  const [isPhoneInputFocused, setIsPhoneInputFocused] = useState(false);

  return (
    <Center>
      <Stack width={"500px"} spacing={3}>
        <Heading size={"lg"} mb={8}>
          {editable ? t("editVehicle") : t("createVehicle")}
        </Heading>
        <Formik
          initialValues={vehicleData}
          enableReinitialize
          validationSchema={Yup.object({
            name: Yup.string().required(t("requiredFieldMessage")),
            productionYear: Yup.number()
              .required(t("requiredFieldMessage"))
              .positive()
              .integer(),
            numberOfSeats: Yup.number()
              .required(t("requiredFieldMessage"))
              .positive()
              .integer(),
            pricePerDay: Yup.number()
              .required(t("requiredFieldMessage"))
              .positive(),
            contact: Yup.object({
              name: Yup.string().required(t("requiredFieldMessage")),
              phoneNumber: Yup.string()
                .required(t("requiredFieldMessage"))
                .min(8, "Phone number is too short"),
            }),
          })}
          onSubmit={(values, actions) => {
            const newlyAddedFiles = selectedFiles
              .filter((file) => !file.id)
              .map((file) => file.file);

            onSubmit(
              {
                ...values,
                images_attributes: selectedFiles.filter((file) => file.id),
              },
              newlyAddedFiles
            );

            actions.resetForm();
            cleanBuffer();
          }}
        >
          {(props) => (
            <Form>
              <Stack spacing={3}>
                <Field name="available">
                  {({ field }) => (
                    <FormControl>
                      <Stack direction="row" align="center">
                        <FormLabel margin="0">
                          {t("vehicleAvailableLabel")}
                        </FormLabel>
                        <Switch
                          id="available"
                          isChecked={field.value}
                          {...field}
                        />
                      </Stack>
                    </FormControl>
                  )}
                </Field>

                <Divider />

                <Field name="name">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.name && form.touched.name}
                    >
                      <Input
                        {...field}
                        placeholder={t("nameInputPlaceholder")}
                        variant="filled"
                      />
                      <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="vehicleType">
                  {({ field, form }) => {
                    return (
                      <FormControl
                        isInvalid={
                          form.errors.vehicleType && form.touched.vehicleType
                        }
                      >
                        <FormLabel>{t("vehicleTypeFormLabel")}</FormLabel>
                        <Select
                          defaultValue={vehicleData.vehicleType}
                          variant="filled"
                          onChange={(event) => {
                            props.setFieldValue(
                              "vehicleType",
                              event.target.value
                            );
                          }}
                        >
                          {Object.keys(VEHICLE_TYPES).map((type) => (
                            <option
                              key={type}
                              label={t(VEHICLE_TYPES[type], {
                                ns: "vehicle",
                              })}
                              value={VEHICLE_TYPES[type]}
                            />
                          ))}
                        </Select>
                      </FormControl>
                    );
                  }}
                </Field>
                <Field name="fuelType">
                  {({ field, form }) => {
                    return (
                      <FormControl
                        isInvalid={
                          form.errors.fuelType && form.touched.fuelType
                        }
                      >
                        <FormLabel>{t("fuelTypeFormLabel")}</FormLabel>
                        <Select
                          defaultValue={vehicleData.fuelType}
                          variant="filled"
                          onChange={(event) => {
                            props.setFieldValue("fuelType", event.target.value);
                          }}
                        >
                          {Object.keys(FUEL_TYPES).map((type) => (
                            <option
                              key={type}
                              label={t(FUEL_TYPES[type], {
                                ns: "vehicle",
                              })}
                              value={FUEL_TYPES[type]}
                            />
                          ))}
                        </Select>
                      </FormControl>
                    );
                  }}
                </Field>
                <Divider />
                <Field name="productionYear">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.productionYear &&
                        form.touched.productionYear
                      }
                    >
                      <FormLabel>{t("productionYearFormLabel")}</FormLabel>
                      <NumberInput variant="filled" {...field}>
                        <NumberInputField {...field} />
                      </NumberInput>
                      <FormErrorMessage>
                        {form.errors.productionYear}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="numberOfSeats">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.numberOfSeats && form.touched.numberOfSeats
                      }
                    >
                      <FormLabel>{t("seatsFormLabel")}</FormLabel>
                      <NumberInput variant="filled" min="2" {...field}>
                        <NumberInputField {...field} />
                      </NumberInput>
                      <FormErrorMessage>
                        {form.errors.numberOfSeats}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <Field name="automatic">
                  {({ field }) => (
                    <FormControl>
                      <Stack direction="row" align="center">
                        <FormLabel margin="0">
                          {t("vehicleAutomaticTransmissionLabel")}
                        </FormLabel>
                        <Switch
                          id="available"
                          isChecked={field.value}
                          {...field}
                        />
                      </Stack>
                    </FormControl>
                  )}
                </Field>
                <Field name="pricePerDay">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.pricePerDay && form.touched.pricePerDay
                      }
                    >
                      <FormLabel>{t("priceLabel")}</FormLabel>
                      <NumberInput variant="filled" {...field}>
                        <NumberInputField {...field} />
                      </NumberInput>
                      <FormErrorMessage>
                        {form.errors.pricePerDay}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Divider />
                <FormLabel>{t("contactInfoLabel")}</FormLabel>
                <Field name="contact.name">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.contact?.name && form.touched.contact?.name
                      }
                    >
                      <Input
                        {...field}
                        placeholder={t("nameInputPlaceholder")}
                        variant="filled"
                      />
                      <FormErrorMessage>
                        {form.errors.contact?.name}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <Field name="contact.phoneNumber">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.contact?.phoneNumber &&
                        form.touched.contact?.phoneNumber
                      }
                    >
                      <InputGroup>
                        <PhoneInput
                          style={{
                            ...phoneNumberInputStyles,
                            ...(isPhoneInputFocused &&
                            !form.errors.contact?.phoneNumber
                              ? {
                                  borderColor: "#3182ce",
                                  //boxShadow: "0 0 0 1px #3182ce",
                                }
                              : {}),
                            ...(form.errors.contact?.phoneNumber
                              ? {
                                  borderColor: "#E53E3E",
                                  //boxShadow: "0 0 0 1px #E53E3E",
                                }
                              : {}),
                          }}
                          onFocus={() => setIsPhoneInputFocused(true)}
                          onBlur={() => setIsPhoneInputFocused(false)}
                          placeholder={t("phoneNumberInputPlaceholder")}
                          value={field.value}
                          onChange={(value) => {
                            props.setFieldValue("contact.phoneNumber", value);
                          }}
                        />
                      </InputGroup>
                      <FormErrorMessage>
                        {form.errors.contact?.phoneNumber}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Divider />
                <div>
                  <FormLabel>{t("imagesLabel")}</FormLabel>
                  <div
                    {...getRootProps()}
                    style={{ padding: "20px", border: "2px dashed #ccc" }}
                  >
                    <input {...getInputProps()} />
                    <p>{t("dropZoneMessage")}</p>
                  </div>
                  <Box mt="4">
                    <HStack wrap="wrap" spacing="4">
                      {selectedFiles.map((image, index) => {
                        if (image._destroy) {
                          return null;
                        }
                        return (
                          <Box key={index}>
                            <Box
                              width="50px"
                              height="50px"
                              position={"relative"}
                            >
                              <Image src={image.url} />
                              <CloseButton
                                size={"sm"}
                                position={"absolute"}
                                top={"-10px"}
                                right={"-10px"}
                                onClick={() =>
                                  handleRemoveFile(index, image.id)
                                }
                              />
                            </Box>
                          </Box>
                        );
                      })}
                    </HStack>
                  </Box>
                </div>

                <Button
                  width="full"
                  isLoading={isSubmitting}
                  type="submit"
                  colorScheme="whatsapp"
                  mt={4}
                >
                  {editable ? t("editVehicle") : t("createVehicle")}
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      </Stack>
    </Center>
  );
}

export default VehicleForm;
