import { useParams } from 'react-router-dom';
import AccommodationUnitForm from "../../components/accommodation-unit-form/AccommodationUnitForm";
import { ACCOMMODATION_TYPES } from "../../constants";
import {
  createAccommodationUnit,
  submitPhotosToAccommodationUnit,
} from "../../services/accommodationUnits";
import { preparePhotosForUpload } from "../../utils/helpers";
import { useEntityCreation, usePhotoUpload } from "../../utils/sharedMutations";

function CreateAccommodationUnit() {
  const { accommodationId, accommodationType } = useParams();

  const submitPhotosMutation = usePhotoUpload(
    ({ accommodationUnitId, photos }) =>
      submitPhotosToAccommodationUnit(accommodationUnitId, photos)
  );

  const createAccommodationUnitMutation = useEntityCreation(
    ({ accommodationId, accommodationUnitData }) =>
      createAccommodationUnit(accommodationId, accommodationUnitData)
  );

  const onSubmit = async (accommodationUnitData, photos) => {
    const accommodationUnit = await createAccommodationUnitMutation.mutateAsync(
      {
        accommodationId,
        accommodationUnitData,
      }
    );
    if (photos.length > 0) {
      await submitPhotosMutation.mutateAsync({
        accommodationUnitId: accommodationUnit.data.id,
        photos: preparePhotosForUpload(photos),
      });
    }
  };

  return (
    <AccommodationUnitForm
      accommodationUnitData={{
        name: "",
        singleBedCount: 1,
        doubleBedCount: 0,
        extraBedCount: 0,
        facilities: "",
        defaultPrice: 100,
        seasonalPricesAttributes: [],
      }}
      onSubmit={onSubmit}
      isSubmitting={
        submitPhotosMutation.isLoading ||
        createAccommodationUnitMutation.isLoading
      }
      allowPhotoUpload={accommodationType === ACCOMMODATION_TYPES.HOTEL}
    />
  );
}

export default CreateAccommodationUnit;
