import { Box, InputGroup, Text } from "@chakra-ui/react";

const InputGroupWithLabel = ({ label, children }) => {
  return (
    <Box position="relative" width="full">
      <Text
        position="absolute"
        backgroundColor="white"
        zIndex={1}
        top="-10px"
        left="10px"
        padding={"0 4px"}
        color="gray.600"
        fontSize="xs"
      >
        {label}
      </Text>
      <InputGroup>{children}</InputGroup>
    </Box>
  );
};

export default InputGroupWithLabel;
