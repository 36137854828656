import axiosInstance from "./axiosInstance";

const createAccommodationUnit = (accommodationId, accommodationUnitData) =>
  axiosInstance.post(
    `/accommodations/${accommodationId}/accommodation_units`,
    accommodationUnitData
  );

const submitPhotosToAccommodationUnit = (accommodationUnitId, images) =>
  axiosInstance.post(
    `/accommodation_units/${accommodationUnitId}/google_drive_images`,
    images,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        "Key-Inflection": "camel",
      },
    }
  );

const getAccommodationUnitById = (accommodationUnitId) =>
  axiosInstance.get(`/accommodation_units/${accommodationUnitId}`);

const deleteAccommodationUnit = (accommodationUnitId) =>
  axiosInstance.delete(`/accommodation_units/${accommodationUnitId}`);

const updateAccommodationUnit = (accommodationUnitData) => {
  axiosInstance.patch(
    `/accommodation_units/${accommodationUnitData.id}`,
    accommodationUnitData
  );
}
export {
  createAccommodationUnit,
  submitPhotosToAccommodationUnit,
  deleteAccommodationUnit,
  updateAccommodationUnit,
  getAccommodationUnitById,
};
