import {
  Flex,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBed,
  faCar,
  faMapLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import ReservationSearchForm from "../../components/reservation-search-form/ReservationSearchForm";
import { RESERVATION_TYPES } from "../../constants";
import { capitalize } from "lodash";

function ReservationTabs({
  selectedTab,
  forHomePage = true,
  isFetching,
  onSearchButtonClick,
}) {
  const navigate = useNavigate();

  const selectedTabStyle = {
    color: "blue.500",
    bg: "whitealpha.500",
    border: "2px solid",
    borderBlockColor: "blue.500",
  };

  const tabIcons = {
    [RESERVATION_TYPES.STAY]: faBed,
    [RESERVATION_TYPES.CAR]: faCar,
    [RESERVATION_TYPES.TOUR]: faMapLocationDot,
  };

  const tabIndex = {
    [RESERVATION_TYPES.STAY]: 0,
    [RESERVATION_TYPES.CAR]: 1,
    [RESERVATION_TYPES.TOUR]: 2,
  };

  return (
    <>
      <Tabs
        isManual
        index={tabIndex[selectedTab]}
        defaultIndex={0}
        align="center"
        variant="soft-rounded"
      >
        <TabList marginBottom={"15px"}>
          {Object.values(RESERVATION_TYPES).map((reservationType) => (
            <Tab
              key={reservationType}
              _selected={selectedTabStyle}
              onClick={() => navigate(`/${reservationType}s`)}
            >
              <Flex gap={2} align="center">
                <FontAwesomeIcon icon={tabIcons[reservationType]} />
                {capitalize(`${reservationType}s`)}
              </Flex>
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          {Object.values(RESERVATION_TYPES).map((reservationType) => (
            <TabPanel key={reservationType} padding={0}>
              <ReservationSearchForm
                onSearchButtonClick={onSearchButtonClick}
                reservationType={reservationType}
                forHomePage={forHomePage}
                isFetching={isFetching}
              />
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </>
  );
}

export default ReservationTabs;
