import { AuthProvider } from "./contexts/AuthContext";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ApplicationRouter from "./ApplicationRouter";
import "./index.scss";
import "@fontsource-variable/figtree";
import i18n from "./i18n";

const fontFamily = `'Figtree Variable', sans-serif`;

const theme = extendTheme({
  fonts: {
    heading: fontFamily,
    body: fontFamily,
  },
  direction: i18n.language === "ar" ? "rtl" : "ltr",
  // withDefaultProps({
  //   defaultProps: {
  //     colorScheme: 'whatsapp'
  //   },
  //   components: ['Button'],
  // })
});

const queryClient = new QueryClient();

function App() {
  return (
    <ChakraProvider
      theme={theme}
      toastOptions={{
        defaultOptions: {
          position: "top-right",
          isClosable: true,
          variant: "subtle",
          containerStyle: {
            // marginTop: "61px",
          },
        },
      }}
    >
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <ApplicationRouter />
        </AuthProvider>
      </QueryClientProvider>
    </ChakraProvider>
  );
}

export default App;
