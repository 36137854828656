export const ROLES = {
  FOREIGN_PARTNER: "ForeignPartner",
  DOMESTIC_PARTNER: "DomesticPartner",
  ADMINISTRATOR: "Administrator",
  GUEST: "Guest",
  VISITOR: "Visitor",
};

export const ACCOMMODATION_TYPES = {
  HOTEL: "Hotel",
  VILLA: "Villa",
  APARTMENT: "Apartment",
};

export const VEHICLE_TYPES = {
  LIMO: "limo",
  CARAVAN: "caravan",
  HATCHBACK: "hatchback",
  MONO_VOLUMEN: "mono_volumen",
  SUV: "suv",
  VAN: "van",
  MINIBUS: "minibus",
};

export const FUEL_TYPES = {
  PETROL: "petrol",
  DIESEL: "diesel",
  HYBRID: "hybrid",
  ELECTRIC: "electric",
};

export const RESERVATION_TYPES = {
  STAY: "stay",
  CAR: "car",
  TOUR: "tour",
};

export const STAY_CITIES = [
  "Sarajevo",
  "Mostar",
  "Jajce",
  "Jablanica",
  "Bihac",
  "Travnik",
  "Vlasic",
];

export const SARAJEVO_INTL_AIRPORT = "Sarajevo Intl Airport SJJ \u2708";

export const pickUpTimeInputOptions = [
  "00:00",
  "00:30",
  "01:00",
  "01:30",
  "02:00",
  "02:30",
  "03:00",
  "03:30",
  "04:00",
  "04:30",
  "05:00",
  "05:30",
  "06:00",
  "06:30",
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
];

export const childrenAgesOptions = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,
];

export const CITIES_TOURS = {
  SARAJEVO: "Sarajevo",
  MOSTAR: "Mostar",
  BIHAC: "Bihac",
  TRAVNIK: "Travnik",
};

export const BOOKING_TYPES = {
  STAY: "AccommodationUnit",
  CAR: "Vehicle",
  TOUR: "Tour",
};

export const CURRENCY_SYMBOLS = {
  eu: "EUR",
  tr: "TRY",
  sa: "SAR",
  ae: "AED",
  qa: "QAR",
  bh: "BHD",
  kw: "KWD",
  om: "OMR",
  ye: "YER",
};

export const UNICODE_FLAGS = {
  eu: "🇪🇺",
  tr: "🇹🇷",
  sa: "🇸🇦",
  ae: "🇦🇪",
  qa: "🇶🇦",
  bh: "🇧🇭",
  kw: "🇰🇼",
  om: "🇴🇲",
  ye: "🇾🇪",
};

export const desktopBreakpoint = 768;
