import axiosInstance from "./axiosInstance";

const signIn = (loginCredentials) =>
  axiosInstance.post("/auth/sign_in", loginCredentials);

const signOut = () => axiosInstance.delete("/auth/sign_out");

const register = (userData) => axiosInstance.post("/auth", userData);

const validateToken = () => axiosInstance.get("/auth/validate_token");

const invite = (inviteeData) => axiosInstance.post("/invitations", inviteeData);

const updateUser = (userData) => axiosInstance.put("/auth", userData);

export { signIn, signOut, register, invite, validateToken, updateUser };
