import {
  Badge,
  Button,
  Card,
  CardBody,
  Flex,
  Text,
  VStack,
} from "@chakra-ui/react";

const TripCard = ({ trip, onChangeTrip }) => {
  return (
    <Card w="full" variant="outline">
      <CardBody padding={4}>
        {trip.attractions.length > 0 ? (
          <VStack gap={4}>
            <Text alignSelf="flex-start">{trip.description}</Text>
            <Flex gap={2} flexWrap="wrap" alignSelf="flex-start">
              {trip.attractions.map((attraction, index) => (
                <Badge
                  key={index}
                  variant="outline"
                  colorScheme="yellow"
                  cursor="pointer"
                >
                  {attraction}
                </Badge>
              ))}
            </Flex>
            <Button
              alignSelf="flex-end"
              variant="link"
              colorScheme="teal"
              onClick={() => onChangeTrip()}
            >
              Change Trip
            </Button>
          </VStack>
        ) : (
          "Free activity day!"
        )}
      </CardBody>
    </Card>
  );
};

export default TripCard;
