import axiosInstance from "./axiosInstance";

const fetchVehicles = () => axiosInstance.get("/vehicles");

const fetchVehiclesByUser = (page) =>
  axiosInstance.get(`/vehicles/index_for_user`, {
    params: page,
  });

const createVehicle = (vehicleData) =>
  axiosInstance.post("/vehicles", vehicleData);

const submitPhotosToVehicle = (vehicleId, images) =>
  axiosInstance.post(`/vehicles/${vehicleId}/google_drive_images`, images, {
    headers: {
      "Content-Type": "multipart/form-data",
      "Key-Inflection": "camel",
    },
  });

const getVehicleById = (vehicleId) =>
  axiosInstance.get(`/vehicles/${vehicleId}`);

const updateVehicle = (vehicleId, vehicleData) =>
  axiosInstance.put(`/vehicles/${vehicleId}`, vehicleData);

const searchVehicles = (searchParams) =>
  axiosInstance.get("/vehicles/search", {
    params: searchParams,
  });

const getCategories = () => axiosInstance.get("/vehicles/categories");

export {
  fetchVehicles,
  fetchVehiclesByUser,
  createVehicle,
  submitPhotosToVehicle,
  getVehicleById,
  updateVehicle,
  searchVehicles,
  getCategories,
};
