import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Key-Inflection": "camel",
    //Currency: localStorage.getItem("currencyCode") || "eu",
  },
});

// request interceptor for currency changes
axiosInstance.interceptors.request.use(
  (config) => {
    const currencyCode = localStorage.getItem("currencyCode") || "eu";

    // Update currency header dynamically before request is sent
    config.headers["Currency"] = currencyCode;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
