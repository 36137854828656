import { React, useState, useEffect } from "react";
import {
  TableContainer,
  TableCaption,
  Table,
  Thead,
  Tr,
  Td,
  Th,
  Tbody,
  Button,
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
  Box,
  AccordionIcon,
  Skeleton,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getPendingBookings,
  changeBookingStatus,
} from "../../services/bookings";
import { useWindowSize } from "../../utils/customHooks";
import BookingDetailsModal from "./BookingDetailsModal";

function PendingBookings() {
  const { t } = useTranslation("common");
  const { width } = useWindowSize();
  const [currentPage, setCurrentPage] = useState(1);
  const [isAppending, setIsAppending] = useState(false);
  const [fetchNew, setFetchNew] = useState(false);
  const [bookingsData, setBookingsData] = useState([]);
  const [hasNextPage, setHasNextPage] = useState(false);

  //const [clickedIndex, setClickedIndex] = useState(null);

  /*const handleClick = (index) => {
    setClickedIndex(index);
    setTimeout(() => {
      setClickedIndex(null);
    }, 2000);
  };
  */

  const { data, isSuccess, isFetching, refetch } = useQuery(
    ["pendingBookings", currentPage],
    () =>
      getPendingBookings({
        page: !fetchNew ? currentPage : 1,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      select: (data) => data.data,
      onSuccess: (data) => {
        // Prepend new data to the top
        const existingIds = new Set(bookingsData.map((booking) => booking.id));
        const filteredNewData = data.results.filter(
          (booking) => !existingIds.has(booking.id)
        );
        if (isAppending) {
          // Append new data to the end
          setBookingsData((prevBookings) => [
            ...prevBookings,
            ...filteredNewData,
          ]);
        } else {
          setBookingsData((prevBookings) => [
            ...filteredNewData,
            ...prevBookings,
          ]);
        }

        // Reset appending and fetchNew flags
        setFetchNew(false);
        setIsAppending(false);
      },
      enabled: true,
    }
  );

  useEffect(() => {
    if (data?.pagination.nextPage && data?.pagination.totalPages > currentPage)
      setHasNextPage(true);
    else setHasNextPage(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (fetchNew) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchNew]);

  const bookingStatusUpdateMutation = useMutation({
    mutationFn: (booking) => {
      return changeBookingStatus(booking.id, booking);
    },
    onError: (_error) => {},
    onSuccess: (data) => {
      refetch();
    },
    enabled: false,
  });

  if (isSuccess) {
    if (width > 768) {
      return (
        <>
          <Button isLoading={fetchNew} onClick={() => setFetchNew(true)}>
            Refetch
          </Button>
          <TableContainer overflowX="unset" overflowY="unset">
            <Table variant="simple">
              <TableCaption>{t("pendingReservations")}</TableCaption>
              <Thead position="sticky" top={0}>
                <Tr>
                  <Th
                    backgroundColor="lightcoral"
                    position={"sticky"}
                    left={"0"}
                  >
                    Id
                  </Th>
                  <Th>Guest name</Th>
                  <Th>Guest surname</Th>
                  <Th>Guest telephone number</Th>
                  <Th>E-mail</Th>
                  <Th>Start date</Th>
                  <Th>End date</Th>
                  <Th>Price</Th>
                  <Th>Reservation type</Th>
                  <Th>Driver included</Th>
                  <Th>Booking details</Th>
                  <Th>Status</Th>
                </Tr>
              </Thead>
              <Tbody>
                {fetchNew && (
                  <>
                    <Tr>
                      <Td colSpan="100%">
                        <Skeleton height="20px" />
                      </Td>
                    </Tr>
                    <Tr>
                      <Td colSpan="100%">
                        <Skeleton height="20px" />
                      </Td>
                    </Tr>
                  </>
                )}
                {bookingsData.map((booking, index) => (
                  <Tr key={booking.id}>
                    <Td
                      backgroundColor="lightcoral"
                      position={"sticky"}
                      left={"0"}
                    >
                      <strong>R[{booking.id}]</strong>
                    </Td>
                    <Td>{booking.user.firstName}</Td>
                    <Td>{booking.user.lastName}</Td>
                    <Td>{booking.user.telephoneNumber}</Td>
                    <Td>{booking.user.email}</Td>
                    <Td>{booking.checkInDate}</Td>
                    <Td>{booking.checkOutDate}</Td>
                    <Td>{booking.fullPrice}</Td>
                    <Td>
                      {booking.accommodation
                        ? "stay"
                        : booking.car
                        ? "car"
                        : "tour"}
                    </Td>
                    <Td>{booking.driverIncluded ? "Yes" : "No"}</Td>
                    {booking.bookingType === "tour" ||
                    booking.bookingType === "stay" ? (
                      <Td>
                        <BookingDetailsModal
                          tourStaysData={booking.tour}
                          accommodationUnits={booking.accommodationUnits}
                          accommodation={booking.accommodation}
                        />
                      </Td>
                    ) : (
                      <Td>{booking.bookingDetailsList}</Td>
                    )}
                    <Td>{booking.currentStatus}</Td>
                    <Td>
                      <Button
                        //isLoading={clickedIndex === index && isLoading}
                        //isDisabled={clickedIndex === index && isLoading}
                        bg="green.200"
                        onClick={() => {
                          //handleClick(index);
                          bookingStatusUpdateMutation.mutate({
                            ...booking,
                            currentStatus: "approved",
                          });
                        }}
                      >
                        Approve
                      </Button>
                      <Button
                        //isLoading={clickedIndex === index && isLoading}
                        //isDisabled={clickedIndex === index && isLoading}
                        bg="red.500"
                        onClick={() => {
                          //handleClick(index);
                          bookingStatusUpdateMutation.mutate({
                            ...booking,
                            currentStatus: "declined",
                          });
                        }}
                      >
                        Decline
                      </Button>
                    </Td>
                  </Tr>
                ))}
                {/* Skeletons for loading state */}
                {isFetching && !fetchNew && (
                  <>
                    <Tr>
                      <Td colSpan="100%">
                        <Skeleton height="20px" />
                      </Td>
                    </Tr>
                    <Tr>
                      <Td colSpan="100%">
                        <Skeleton height="20px" />
                      </Td>
                    </Tr>
                  </>
                )}
              </Tbody>
            </Table>
          </TableContainer>
          {hasNextPage && (
            <Button
              isLoading={isFetching && !fetchNew}
              onClick={() => {
                setIsAppending(true);
                setCurrentPage(currentPage + 1);
              }}
            >
              Show more
            </Button>
          )}
        </>
      );
    } else {
      return (
        <>
          <Button isLoading={fetchNew} onClick={() => setFetchNew(true)}>
            Refetch
          </Button>
          <Accordion defaultIndex={[0]} allowMultiple>
            {fetchNew && <Skeleton height={"50px"}></Skeleton>}
            {bookingsData.map((booking, index) => (
              <AccordionItem key={booking.id}>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      <strong style={{ fontWeight: "bold" }}>
                        R{"["}
                        {booking.id}
                        {"]"}
                      </strong>{" "}
                      {booking.user.firstName} {booking.user.lastName}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Box as="span" flex="1" textAlign="left">
                    <p>Telephone number: {booking.user.telephoneNumber}</p>
                    <p>E-mail: {booking.user.email}</p>
                    <p>Chech-in date: {booking.checkInDate}</p>
                    <p>Check-out date: {booking.checkOutDate}</p>
                    <p>FullPrice: {booking.fullPrice}</p>
                    <p>
                      Reservation type:{" "}
                      {booking.accommodation
                        ? "stay"
                        : booking.car
                        ? "car"
                        : "tour"}
                    </p>
                    <p>
                      Driver inlcuded: {booking.driverIncluded ? "Yes" : "No"}
                    </p>

                    <p>Booking details:</p>
                    {booking.bookingType === "tour" ||
                    booking.bookingType === "stay" ? (
                      <p>
                        <BookingDetailsModal
                          tourStaysData={booking.tour}
                          accommodationUnits={booking.accommodationUnits}
                          accommodation={booking.accommodation}
                        />
                      </p>
                    ) : (
                      <p>{booking.bookingDetailsList}</p>
                    )}

                    <Button
                      size={"sm"}
                      //isLoading={clickedIndex === index && isLoading}
                      //isDisabled={clickedIndex === index && isLoading}
                      bg="green.200"
                      onClick={() => {
                        //handleClick(index);
                        bookingStatusUpdateMutation.mutate({
                          ...booking,
                          currentStatus: "approved",
                        });
                      }}
                    >
                      Approve
                    </Button>
                    <Button
                      size={"sm"}
                      //isLoading={clickedIndex === index && isLoading}
                      //isDisabled={clickedIndex === index && isLoading}
                      bg="red.500"
                      onClick={() => {
                        //handleClick(index);
                        bookingStatusUpdateMutation.mutate({
                          ...booking,
                          currentStatus: "declined",
                        });
                      }}
                    >
                      Decline
                    </Button>
                  </Box>
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>

          {isFetching && !fetchNew && <Skeleton height={"50px"}></Skeleton>}

          {hasNextPage && (
            <Button
              isLoading={isFetching && !fetchNew}
              onClick={() => {
                setIsAppending(true);
                setCurrentPage(currentPage + 1);
              }}
            >
              Show more
            </Button>
          )}
        </>
      );
    }
  }
}

export default PendingBookings;
