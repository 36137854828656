function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

//return the Date in the format expected by the server: YYYY-MM-DD
function getDateInServerExpectedFormat(date) {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    .toISOString()
    .split("T")[0];
}

function formatDate(dateTimeString) {
  // Extract the date part from the ISO 8601 datetime string
  const datePart = dateTimeString.split('T')[0];
  
  return datePart;
}

const dateAfter = (date) => {
  let tomorrow = new Date(date);
  tomorrow.setDate(tomorrow.getDate() + 1);
  return tomorrow;
};

function preparePhotosForUpload(photos) {
  const formData = new FormData();
  photos.forEach((file) => {
    formData.append("images[]", file);
  });
  return formData;
}

export {
  capitalize,
  getDateInServerExpectedFormat,
  formatDate,
  preparePhotosForUpload,
  dateAfter,
};
