import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  Divider,
  Flex,
  HStack,
  Image,
  List,
  ListItem,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import ImageGallery from "react-image-gallery";
import "./RoomCard.scss";
import { FullScreenButton } from "../../pages/accommodation/Accommodation";
import { useEffect, useState } from "react";
import { ACCOMMODATION_TYPES, CURRENCY_SYMBOLS } from "../../constants";

const RoomCard = ({
  room,
  type: accommodationType,
  onSelect,
  previewOnly,
  isForTour,
}) => {
  const {
    name,
    bedCount,
    capacity,
    facilities,
    images,
    pricePerNight,
    unitCount,
    recommendedPricePerDay,
  } = room;
  const { t, i18n } = useTranslation("common");
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [numberOfRoomsToSelect, setNumberOfRoomsToSelect] = useState(0);
  const galleryImages = images.map(({ url }) => ({
    original: url,
    thumbnail: url,
    originalClass: isFullscreen ? "" : "room-gallery-image",
  }));
  const [invokeOnRoomSelect, setInvokeOnRoomSelect] = useState(false);

  const [numberOfRoomsToSelectForTour, setNumberOfRoomsToSelectForTour] =
    useState(0);

  useEffect(() => {
    if (invokeOnRoomSelect) {
      onSelect(numberOfRoomsToSelect);
      setInvokeOnRoomSelect(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invokeOnRoomSelect]);

  let qurrencySymbol = "";
  if (localStorage.getItem("currencyCode") === null)
    qurrencySymbol = CURRENCY_SYMBOLS["eu"];

  for (const code in CURRENCY_SYMBOLS) {
    if (code === localStorage.getItem("currencyCode")) {
      qurrencySymbol = CURRENCY_SYMBOLS[code];
      break;
    }
  }

  return (
    <Card variant="outline" borderRadius="12px">
      {(accommodationType === ACCOMMODATION_TYPES.HOTEL || isForTour) && (
        <ImageGallery
          items={galleryImages}
          showPlayButton={false}
          showThumbnails={isFullscreen}
          isRTL={i18n.language === "ar"}
          showIndex={isFullscreen}
          additionalClass={`room-gallery ${isFullscreen ? "full-screen" : ""}`}
          onScreenChange={(isFullscreen) => setIsFullscreen(isFullscreen)}
          renderFullscreenButton={(onClick, _isFullscreen) => (
            <FullScreenButton label={galleryImages.length} onClick={onClick} />
          )}
        />
      )}
      <CardBody padding={3} fontSize="small" minH={240}>
        <Text fontSize="xl" fontWeight="bold">
          {accommodationType === ACCOMMODATION_TYPES.HOTEL || isForTour
            ? name
            : accommodationType}
        </Text>
        <HStack alignItems="flex-start" spacing={2} my={3}>
          <Image
            boxSize="16px"
            src={require("../../assets/single-bed-icon.svg").default}
          />
          <Text>{bedCount}</Text>
        </HStack>
        <HStack alignItems="flex-start" spacing={2} my={3}>
          <Image
            boxSize="16px"
            src={require("../../assets/two-people.svg").default}
          />
          <Text>Sleeps {capacity}</Text>
        </HStack>
        <List>
          {facilities?.split(",").map((facility, index) => (
            <ListItem key={index} fontSize="sm" mb={2}>
              · {facility.trim()}
            </ListItem>
          ))}
        </List>
      </CardBody>
      <Divider />
      <CardFooter justify="space-between" alignItems="center" padding={3}>
        <Box>
          <VStack alignItems={"left"}>
            <HStack>
              <Text as="span" fontWeight="bold" fontSize="large">
                {recommendedPricePerDay !== undefined &&
                recommendedPricePerDay !== null
                  ? `${qurrencySymbol} ${parseFloat(
                      recommendedPricePerDay.replace(/[^0-9.-]+/g, "")
                    ).toFixed(2)}`
                  : pricePerNight}
              </Text>
              <Text as="span" fontSize="sm">
                {"/"}
                {t("perNightLabel")}
              </Text>
            </HStack>
            {unitCount !== undefined && unitCount !== null && (
              <Text fontSize="sm">{`for ${unitCount} rooms`}</Text>
            )}
            {accommodationType !== ACCOMMODATION_TYPES.HOTEL && (
              <Text fontSize="sm">{`for entire ${accommodationType}`}</Text>
            )}
          </VStack>
        </Box>
        {!previewOnly && (
          <Flex gap={2}>
            {accommodationType === ACCOMMODATION_TYPES.HOTEL && !isForTour && (
              <HStack spacing={2}>
                <Button
                  isDisabled={numberOfRoomsToSelect === 0}
                  onClick={() => {
                    setNumberOfRoomsToSelect(numberOfRoomsToSelect - 1);
                    setInvokeOnRoomSelect(true);
                  }}
                  colorScheme="teal"
                  variant="outline"
                >
                  -
                </Button>
                <Text w="20px" align="center">
                  {numberOfRoomsToSelect}
                </Text>
                <Button
                  onClick={() => {
                    setNumberOfRoomsToSelect(numberOfRoomsToSelect + 1);
                    setInvokeOnRoomSelect(true);
                  }}
                  colorScheme="teal"
                  variant="outline"
                >
                  +
                </Button>
              </HStack>
            )}
            {isForTour && accommodationType === ACCOMMODATION_TYPES.HOTEL && (
              <>
                <HStack spacing={2}>
                  <Button
                    isDisabled={numberOfRoomsToSelectForTour === 0}
                    onClick={() => {
                      setNumberOfRoomsToSelectForTour(
                        numberOfRoomsToSelectForTour - 1
                      );
                    }}
                    colorScheme="teal"
                    variant="outline"
                  >
                    -
                  </Button>
                  <Text w="20px" align="center">
                    {numberOfRoomsToSelectForTour}
                  </Text>
                  <Button
                    onClick={() => {
                      setNumberOfRoomsToSelectForTour(
                        numberOfRoomsToSelectForTour + 1
                      );
                    }}
                    colorScheme="teal"
                    variant="outline"
                  >
                    +
                  </Button>
                </HStack>
              </>
            )}

            {isForTour && (
              <Button
                isDisabled={
                  accommodationType === ACCOMMODATION_TYPES.HOTEL &&
                  numberOfRoomsToSelectForTour === 0
                }
                colorScheme="teal"
                variant="solid"
                onClick={() => {
                  if (accommodationType !== ACCOMMODATION_TYPES.HOTEL)
                    onSelect(1);
                  else onSelect(numberOfRoomsToSelectForTour);
                }}
              >
                {t("selectButtonText")}
              </Button>
            )}
          </Flex>
        )}
      </CardFooter>
    </Card>
  );
};

export default RoomCard;
