import {
  Box,
  Center,
  Stack,
  Input,
  InputLeftElement,
  Button,
  Flex,
  Text,
  Tooltip,
  Portal,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Select,
  Heading,
  Show,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  RESERVATION_TYPES,
  SARAJEVO_INTL_AIRPORT,
  STAY_CITIES,
  childrenAgesOptions,
  pickUpTimeInputOptions,
} from "../../constants";
import { dateAfter, getDateInServerExpectedFormat } from "../../utils/helpers";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { RangeDatepicker } from "chakra-dayzed-datepicker";
import { capitalize } from "lodash";
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faCalendar,
  faPerson,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import InputGroupWithLabel from "../input-group-with-label/InputGroupWithLabel";
import { useTranslation } from "react-i18next";

function ReservationSearchForm({
  reservationType = RESERVATION_TYPES.STAY,
  forHomePage,
  isFetching,
  onSearchButtonClick,
  showCitySelector = true,
}) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("common");

  const locationRouter = useLocation();
  const [searchParams] = useSearchParams();

  const { isOpen, onToggle, onClose } = useDisclosure();

  const {
    location,
    pick_up_time,
    drop_off_time,
    start_date,
    end_date,
    adults,
    rooms,
  } = Object.fromEntries(searchParams.entries());

  const children = searchParams.getAll("children");

  const initialDates = () => {
    let tomorrow = dateAfter(new Date());
    return [tomorrow, dateAfter(tomorrow)];
  };

  const tourInitialDates = () => {
    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    let sevenDaysLater = new Date(tomorrow.getTime() + 7 * 24 * 60 * 60 * 1000);
    return [tomorrow, sevenDaysLater];
  };

  const [reservation, setReservation] = useState({
    place:
      location ||
      (reservationType === RESERVATION_TYPES.CAR
        ? SARAJEVO_INTL_AIRPORT
        : "Sarajevo"),
    selectedDates:
      start_date && end_date
        ? [new Date(Date.parse(start_date)), new Date(Date.parse(end_date))]
        : reservationType === RESERVATION_TYPES.TOUR
        ? tourInitialDates()
        : initialDates(),
    pickUpTime: pick_up_time,
    dropOffTime: drop_off_time,
    adults: Number(adults) || 2,
    children: children || [],
    rooms: Number(rooms) || 1,
  });

  const tourReservationFormBackgroundColor = "purple.300";
  const carReservationFormBackgroundColor = "teal.300";

  const StaysTravelersLabelLTR = `${reservation.rooms} rooms, ${reservation.adults} adults, ${reservation.children.length} children`;
  const StaysTravelersLabelRTL = `rooms ${reservation.rooms}, adults ${reservation.adults}, children ${reservation.children.length}`;

  const travelersLabel =
    reservationType === RESERVATION_TYPES.CAR
      ? `${reservation.adults} passengers`
      : //: `${reservation.rooms} rooms, ${reservation.adults} adults, ${reservation.children.length} children`;
      i18n.language === "ar"
      ? StaysTravelersLabelRTL
      : StaysTravelersLabelLTR;
  const backgroundImageProps = useBreakpointValue(
    {
      lg: forHomePage
        ? {
            backgroundSize: "cover",
            height: "320px",
            backgroundColor:
              reservationType === RESERVATION_TYPES.TOUR
                ? tourReservationFormBackgroundColor
                : carReservationFormBackgroundColor,
            background:
              reservationType === RESERVATION_TYPES.STAY
                ? "linear-gradient(rgba(0,0,0,0.9), rgba(0,0,0,0)), url('/bosnia-photo.jpg') center/cover no-repeat"
                : "",
          }
        : {},
      base: {},
    },
    {
      fallback: "lg",
    }
  );

  const reservationFormStackProps = useBreakpointValue(
    {
      lg: {
        position: forHomePage ? "absolute" : "relative",
        bottom: forHomePage ? "-36px" : "",
      },
      base: {},
    },
    {
      fallback: "lg",
    }
  );

  const searchButtonDisabled = () => {
    if (reservationType === RESERVATION_TYPES.TOUR) {
      return reservation.children.some((age) => age === "");
    } else if (reservationType === RESERVATION_TYPES.STAY) {
      return (
        (showCitySelector && !reservation.place) ||
        reservation.children.some((age) => age === "")
      );
    } else if (reservationType === RESERVATION_TYPES.CAR) {
      return (
        !reservation.pickUpTime ||
        !reservation.place ||
        !reservation.dropOffTime
      );
    }
  };

  return (
    <Box
      position="relative"
      marginBottom={showCitySelector ? "45px" : "0"}
      {...backgroundImageProps}
    >
      <Show above="lg">
        {forHomePage && (
          <Heading
            padding={"20px"}
            textAlign="left"
            fontSize="6xl"
            color={"whiteAlpha.900"}
          >
            {reservationType === RESERVATION_TYPES.STAY
              ? "Find your perfect stay in Bosnia and Herzegovina"
              : reservationType === RESERVATION_TYPES.CAR
              ? "Get around quickly and easily"
              : "Explore the best of Bosnia"}
          </Heading>
        )}
      </Show>
      <Center>
        <Stack
          padding={"20px 15px"}
          boxShadow="base"
          borderRadius={"lg"}
          background="white"
          alignItems="center"
          justifyContent="space-around"
          width={["full", "full", `${forHomePage ? "90%" : "full"}`, "full"]}
          direction={["column", "column", "column", "row"]}
          gap={[10, 10, 5]}
          {...reservationFormStackProps}
        >
          {reservationType !== RESERVATION_TYPES.TOUR && showCitySelector && (
            <InputGroupWithLabel
              label={
                reservationType === RESERVATION_TYPES.CAR
                  ? t("common:pickUpLocation")
                  : t("common:whereAreYouGoing")
              }
            >
              <InputLeftElement
                children={<FontAwesomeIcon icon={faLocationDot} />}
              />
              <Select
                fontSize={"sm"}
                defaultValue={reservation.place}
                style={{
                  fontSize: "sm",
                  paddingLeft: i18n.language === "en" && "2.5rem",
                  paddingRight: i18n.language === "ar" && "2.5rem",
                }}
                width={"full"}
                value={reservation.place}
                onChange={(e) => {
                  setReservation({
                    ...reservation,
                    place: capitalize(e.target.value),
                  });
                }}
              >
                {(reservationType === RESERVATION_TYPES.CAR
                  ? [SARAJEVO_INTL_AIRPORT, ...STAY_CITIES]
                  : STAY_CITIES
                ).map((city, index) => (
                  <option key={index} value={city}>
                    {city}
                  </option>
                ))}
              </Select>
            </InputGroupWithLabel>
          )}

          <InputGroupWithLabel label={t("common:chooseYourDates")}>
            <InputLeftElement
              children={<FontAwesomeIcon icon={faCalendar} />}
            />
            <RangeDatepicker
              configs={{
                dateFormat: "MMM dd",
              }}
              minDate={new Date()}
              usePortal
              propsConfigs={{
                inputProps: {
                  paddingLeft: i18n.language === "en" ? "2.5rem" : "0",
                  paddingRight: i18n.language === "ar" ? "2.5rem" : "0",
                  fontSize: "sm",
                  cursor: "pointer",
                },
                dayOfMonthBtnProps: {
                  defaultBtnProps: {
                    _hover: {
                      background: "teal.400",
                    },
                  },
                  isInRangeBtnProps: {
                    background: "teal.200",
                  },
                  selectedBtnProps: {
                    background: "teal.400",
                    color: "green",
                  },
                  todayBtnProps: {
                    background: "teal.400",
                  },
                },
              }}
              closeOnSelect
              selectedDates={reservation.selectedDates}
              onDateChange={(e) => {
                if (e[0].getTime() === e[1]?.getTime()) {
                  setReservation({
                    ...reservation,
                    selectedDates: [e[0], dateAfter(e[1])],
                  });
                } else {
                  setReservation({ ...reservation, selectedDates: e });
                }
              }}
            />
          </InputGroupWithLabel>

          {reservationType === RESERVATION_TYPES.CAR && (
            <>
              <InputGroupWithLabel label={t("common:pickUpYourCarAt")}>
                <InputLeftElement
                  children={<FontAwesomeIcon icon={faClock} />}
                />
                <Select
                  style={{
                    fontSize: "sm",
                    paddingLeft: i18n.language === "en" && "2.5rem",
                    paddingRight: i18n.language === "ar" && "2.5rem",
                  }}
                  fontSize={"sm"}
                  isRequired
                  placeholder={t("common:pickUpTime")}
                  value={reservation.pickUpTime}
                  onChange={(e) =>
                    setReservation({
                      ...reservation,
                      pickUpTime: e.target.value,
                    })
                  }
                >
                  {pickUpTimeInputOptions.map((time, tid) => (
                    <option key={`time-${tid}`} value={time}>
                      {time}
                    </option>
                  ))}
                </Select>
              </InputGroupWithLabel>

              <InputGroupWithLabel label={t("common:dropOffYourCarAt")}>
                <InputLeftElement
                  children={<FontAwesomeIcon icon={faClock} />}
                />
                <Select
                  fontSize={"sm"}
                  style={{
                    fontSize: "sm",
                    paddingLeft: i18n.language === "en" && "2.5rem",
                    paddingRight: i18n.language === "ar" && "2.5rem",
                  }}
                  isRequired
                  placeholder={t("common:dropOffTime")}
                  value={reservation.dropOffTime}
                  onChange={(e) =>
                    setReservation({
                      ...reservation,
                      dropOffTime: e.target.value,
                    })
                  }
                >
                  {pickUpTimeInputOptions.map((time, tid) => (
                    <option key={`time-${tid}`} value={time}>
                      {time}
                    </option>
                  ))}
                </Select>
              </InputGroupWithLabel>
            </>
          )}

          <Popover closeOnBlur placement="bottom-start" isOpen={isOpen}>
            <PopoverTrigger>
              <Box width="full" onClick={onToggle}>
                <InputGroupWithLabel label={t("common:totalPeople")}>
                  <InputLeftElement
                    children={<FontAwesomeIcon icon={faPerson} />}
                  />
                  <Input
                    fontSize={"sm"}
                    cursor={"pointer"}
                    placeholder="Number of guests"
                    value={travelersLabel}
                    isReadOnly
                  />
                </InputGroupWithLabel>
              </Box>
            </PopoverTrigger>
            <Portal>
              <PopoverContent zIndex={88} padding={"15px"}>
                <Box>
                  {(reservationType === RESERVATION_TYPES.CAR
                    ? ["adults"]
                    : ["rooms", "adults", "children"]
                  ).map((type, tid) => {
                    return (
                      <Flex
                        key={`type-${tid}`}
                        align={"baseline"}
                        justify={"space-between"}
                        mb={"20px"}
                      >
                        <Flex direction="column" align={"baseline"}>
                          <Text fontSize="sm">
                            {reservationType === RESERVATION_TYPES.CAR
                              ? "Passengers"
                              : capitalize(type)}
                          </Text>
                        </Flex>
                        <Flex>
                          <Button
                            size="xs"
                            onClick={() =>
                              setReservation({
                                ...reservation,
                                [type]:
                                  type === "children"
                                    ? [...reservation[type].slice(0, -1)]
                                    : reservation[type] - 1,
                              })
                            }
                            isDisabled={
                              type === "children"
                                ? reservation[type].length <= 0
                                : reservation[type] <= 1
                            }
                            variant="outline"
                            colorScheme="teal"
                          >
                            -
                          </Button>
                          <Box
                            alignSelf="center"
                            w={"40px"}
                            textAlign={"center"}
                          >
                            {type === "children"
                              ? reservation[type].length
                              : reservation[type]}
                          </Box>
                          <Button
                            size="xs"
                            onClick={() =>
                              setReservation({
                                ...reservation,
                                [type]:
                                  type === "children"
                                    ? [...reservation[type], ""]
                                    : reservation[type] + 1,
                              })
                            }
                            variant="outline"
                            colorScheme="teal"
                          >
                            +
                          </Button>
                        </Flex>
                      </Flex>
                    );
                  })}
                </Box>

                {reservation.children.length > 0 && (
                  <Box>
                    <Text mb={"10px"} fontSize="xs" color="gray.500">
                      To find you a place to stay that fits your entire group
                      along with correct prices, we need to know how old your
                      children will be at check-out
                    </Text>
                    <Flex gap={2} mb={"20px"} direction="row" flexWrap={"wrap"}>
                      {reservation.children.map((age, index) => (
                        <Select
                          style={{
                            fontSize: "sm",
                            paddingLeft: i18n.language === "en" && "2.5rem",
                            paddingRight: i18n.language === "ar" && "2.5rem",
                          }}
                          maxWidth={"48.5%"}
                          key={`age-${index}`}
                          flex={"50%"}
                          size="sm"
                          isRequired
                          placeholder="Child age"
                          value={age}
                          onChange={(e) =>
                            setReservation({
                              ...reservation,
                              children: [
                                ...reservation.children.map((age, ind) => {
                                  if (ind === index) {
                                    return e.target.value;
                                  }
                                  return age;
                                }),
                              ],
                            })
                          }
                        >
                          {childrenAgesOptions.map((age, aid) => (
                            <option key={`age-${aid}`} value={age}>
                              {`${age} year(s) old`}
                            </option>
                          ))}
                        </Select>
                      ))}
                    </Flex>
                  </Box>
                )}

                <Button
                  onClick={onClose}
                  alignSelf="flex-end"
                  colorScheme="teal"
                  variant="solid"
                  size="sm"
                  width="150px"
                >
                  Done
                </Button>
              </PopoverContent>
            </Portal>
          </Popover>

          <Tooltip
            label={`Please select your ${
              reservationType === RESERVATION_TYPES.CAR
                ? "pick-up location and time"
                : reservationType === RESERVATION_TYPES.STAY
                ? "destination and child ages"
                : "child ages"
            }!`}
            isDisabled={!searchButtonDisabled()}
          >
            <Button
              width={["full", "full", "xs", "sm"]}
              borderRadius="xl"
              minWidth={"100px"}
              colorScheme="teal"
              variant="solid"
              isLoading={isFetching}
              isDisabled={searchButtonDisabled()}
              onClick={() => {
                navigate({
                  pathname: forHomePage
                    ? reservationType === RESERVATION_TYPES.TOUR
                      ? `/${reservationType}s/builder`
                      : `/${reservationType}s/search`
                    : locationRouter.pathname,
                  search: `?${createSearchParams({
                    location: reservation.place,
                    start_date: getDateInServerExpectedFormat(
                      reservation.selectedDates[0]
                    ),
                    end_date: getDateInServerExpectedFormat(
                      reservation.selectedDates[1]
                    ),
                    pick_up_time: reservation.pickUpTime,
                    drop_off_time: reservation.dropOffTime,
                    adults: reservation.adults,
                    rooms: reservation.rooms,
                    children: reservation.children,
                  })}`,
                });

                if (!forHomePage && onSearchButtonClick) {
                  onSearchButtonClick();
                }
              }}
            >
              {t("common:searchButtonText")}
            </Button>
          </Tooltip>
        </Stack>
      </Center>
    </Box>
  );
}

export default ReservationSearchForm;
