import React from "react";
import { Box, Container, Heading, Text, Link, Stack } from "@chakra-ui/react";

const TermsOfUse = () => {
  return (
    <Container maxW="container.md" mt={5} mb={5}>
      <Box p={5} bg="white" borderRadius="md" boxShadow="md">
        <Heading as="h1" size="xl" mb={4}>
          Terms of Use
        </Heading>
        <Text mb={4}>Effective Date: August 10, 2024</Text>

        <Heading as="h2" size="lg" mb={2}>
          1. Introduction
        </Heading>
        <Text mb={4}>
          Welcome to Fursatook Bosnia ("we", "our", "us"). These Terms of Use
          ("Terms") govern your access to and use of our website fursatook.com
          (the "Site"). By accessing or using our Site, you agree to comply with
          these Terms. If you do not agree with these Terms, please do not use
          our Site.
        </Text>

        <Heading as="h2" size="lg" mb={2}>
          2. Use of the Site
        </Heading>
        <Text mb={4}>
          You agree to use the Site only for lawful purposes and in accordance
          with these Terms. You are prohibited from using the Site in any manner
          that could damage, disable, overburden, or impair the Site or
          interfere with any other party's use of the Site.
        </Text>

        <Heading as="h2" size="lg" mb={2}>
          3. Intellectual Property
        </Heading>
        <Text mb={4}>
          The content on the Site, including but not limited to text, graphics,
          logos, and software, is the property of [Fursatook Bosnia] or its
          licensors and is protected by intellectual property laws. You may not
          reproduce, distribute, modify, or create derivative works from any
          content on the Site without our prior written consent.
        </Text>

        <Heading as="h2" size="lg" mb={2}>
          4. User Content
        </Heading>
        <Text mb={4}>
          You are responsible for any content you post or transmit to the Site.
          By posting content, you grant us a non-exclusive, royalty-free,
          perpetual, and worldwide license to use, reproduce, and display the
          content in connection with the Site.
        </Text>

        <Heading as="h2" size="lg" mb={2}>
          5. Third-Party Links
        </Heading>
        <Text mb={4}>
          The Site may contain links to third-party websites. We are not
          responsible for the content or practices of any third-party sites. You
          should review the terms of use and privacy policies of any third-party
          sites you visit.
        </Text>

        <Heading as="h2" size="lg" mb={2}>
          6. Disclaimers and Limitation of Liability
        </Heading>
        <Text mb={4}>
          The Site is provided "as is" and "as available" without warranties of
          any kind, either express or implied. We disclaim all warranties,
          including but not limited to implied warranties of merchantability and
          fitness for a particular purpose. We are not liable for any damages
          arising from the use or inability to use the Site, including but not
          limited to direct, indirect, incidental, or consequential damages.
        </Text>

        <Heading as="h2" size="lg" mb={2}>
          7. Indemnification
        </Heading>
        <Text mb={4}>
          You agree to indemnify and hold harmless [Fursatook Bosnia] and its
          affiliates, officers, directors, employees, and agents from any
          claims, liabilities, damages, losses, or expenses arising out of your
          use of the Site or violation of these Terms.
        </Text>

        <Heading as="h2" size="lg" mb={2}>
          8. Changes to Terms
        </Heading>
        <Text mb={4}>
          We may update these Terms from time to time. Any changes will be
          posted on this page with an updated effective date. Your continued use
          of the Site after any changes constitutes your acceptance of the new
          Terms.
        </Text>

        <Heading as="h2" size="lg" mb={2}>
          9. Governing Law
        </Heading>
        <Text mb={4}>
          These Terms are governed by and construed in accordance with the laws
          of Bosnia and Herzegovina, without regard to its conflict of law
          principles. Any disputes arising from these Terms or your use of the
          Site shall be resolved in the courts located in Bosnia and
          Herzegovina.
        </Text>

        <Heading as="h2" size="lg" mb={2}>
          10. Contact Us
        </Heading>
        <Text>
          If you have any questions about these Terms or our Site, please
          contact us at:
        </Text>
        <Stack spacing={1} mt={2}>
          <Text>fursatook.com</Text>
          <Text></Text>
          <Text>
            Email:{" "}
            <Link href={`mailto:fursatook.it@gmail.com`} color="blue.500">
              fursatook.it@gmail.com
            </Link>
          </Text>
        </Stack>
      </Box>
    </Container>
  );
};

export default TermsOfUse;
