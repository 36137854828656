import { React, useState, useRef } from "react";
import {
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Image,
  Avatar,
  Flex,
  Spacer,
  MenuGroup,
  MenuDivider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Select,
  VStack,
} from "@chakra-ui/react";
import InputGroupWithLabel from "../input-group-with-label/InputGroupWithLabel";
import { ChevronDownIcon, PlusSquareIcon } from "@chakra-ui/icons";
import "./Header.scss";
import { useAuth } from "../../contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ROLES } from "../../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouseCircleCheck,
  faCar,
  faRightFromBracket,
  faUserPlus,
  faBook,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";
import {
  desktopBreakpoint,
  CURRENCY_SYMBOLS,
  UNICODE_FLAGS,
} from "../../constants";
import { useWindowSize } from "../../utils/customHooks";

function Header() {
  let { logout, currentUser, isAuthenticated } = useAuth();
  const { width } = useWindowSize();
  const { t, i18n } = useTranslation("common");
  const isVisitor = currentUser?.type === ROLES.VISITOR;

  const changeLanguage = (lng) => {
    localStorage.setItem("lang", lng);
    i18n.changeLanguage(lng);
    if (lng === "ar") {
      //document.body.dir = "rtl";
    } else {
      //document.body.dir = "ltr";
    }
    window.location.reload();
  };

  const getCurrencyFromLocalStorage = () => {
    const currency = localStorage.getItem("currencyCode");
    return currency;
  };

  const saveCurrencyToLocalStorage = (currency) => {
    localStorage.setItem("currencyCode", currency);
    window.location.reload();
  };

  const logo = (
    <Image
      boxSize="42px"
      borderRadius="full"
      alt="logo"
      src="../../../fursatook-logo.jpg"
    />
  );

  function CurrencyMenuItems() {
    return (
      <>
        <MenuItem
          onClick={() => {
            saveCurrencyToLocalStorage("eu");
          }}
        >
          {i18n.language === "en" ? <span>🇪🇺 EUR</span> : <span>EUR 🇪🇺</span>}
        </MenuItem>
        <MenuItem
          onClick={() => {
            saveCurrencyToLocalStorage("tr");
          }}
        >
          {i18n.language === "en" ? <span>🇹🇷 TRY</span> : <span>TRY 🇹🇷</span>}
        </MenuItem>
        <MenuItem
          onClick={() => {
            saveCurrencyToLocalStorage("sa");
          }}
        >
          {i18n.language === "en" ? <span>🇸🇦 SAR</span> : <span>SAR 🇸🇦</span>}
          <span></span>
        </MenuItem>
        <MenuItem
          onClick={() => {
            saveCurrencyToLocalStorage("ae");
          }}
        >
          {i18n.language === "en" ? <span>🇦🇪 AED</span> : <span>AED 🇦🇪</span>}
        </MenuItem>
        <MenuItem
          onClick={() => {
            saveCurrencyToLocalStorage("qa");
          }}
        >
          {i18n.language === "en" ? <span>🇶🇦 QAR</span> : <span>QAR 🇶🇦</span>}
        </MenuItem>
        <MenuItem
          onClick={() => {
            saveCurrencyToLocalStorage("bh");
          }}
        >
          {i18n.language === "en" ? <span>🇧🇭 BHD</span> : <span>BHD 🇧🇭</span>}
        </MenuItem>
        <MenuItem
          onClick={() => {
            saveCurrencyToLocalStorage("kw");
          }}
        >
          {i18n.language === "en" ? <span>🇰🇼 KWD</span> : <span>KWD 🇰🇼</span>}
        </MenuItem>
        <MenuItem
          onClick={() => {
            saveCurrencyToLocalStorage("om");
          }}
        >
          {i18n.language === "en" ? <span>🇴🇲 OMR</span> : <span>OMR 🇴🇲</span>}
        </MenuItem>
        <MenuItem
          onClick={() => {
            saveCurrencyToLocalStorage("ye");
          }}
        >
          {i18n.language === "en" ? <span>🇾🇪 YER</span> : <span>YER 🇾🇪</span>}
        </MenuItem>
      </>
    );
  }

  function CurrencyMenuButtonBasedOnSelectedCurrencyAndLanguage({
    isForMobileMenu = false,
  }) {
    return (
      <MenuButton variant="ghost" as={Button} rightIcon={<ChevronDownIcon />}>
        <span>
          {getCurrencyFromLocalStorage() === "eu" ||
          getCurrencyFromLocalStorage() === null ? (
            i18n.language === "en" ? (
              <span>{`🇪🇺 EUR ${isForMobileMenu ? "EN" : ""}`}</span>
            ) : (
              <span>{`EUR 🇪🇺 ${isForMobileMenu ? "AR" : ""}`}</span>
            )
          ) : getCurrencyFromLocalStorage() === "sa" ? (
            i18n.language === "en" ? (
              <span>{`🇸🇦 SAR ${isForMobileMenu ? "EN" : ""}`}</span>
            ) : (
              <span>{`SAR 🇸🇦 ${isForMobileMenu ? "AR" : ""}`}</span>
            )
          ) : getCurrencyFromLocalStorage() === "tr" ? (
            i18n.language === "en" ? (
              <span>🇹🇷 TRY</span>
            ) : (
              <span>TRY 🇹🇷</span>
            )
          ) : getCurrencyFromLocalStorage() === "ae" ? (
            i18n.language === "en" ? (
              <span>🇦🇪 AED</span>
            ) : (
              <span>AED 🇦🇪</span>
            )
          ) : getCurrencyFromLocalStorage() === "qa" ? (
            i18n.language === "en" ? (
              <span>🇶🇦 QAR</span>
            ) : (
              <span>QAR 🇶🇦</span>
            )
          ) : getCurrencyFromLocalStorage() === "bh" ? (
            i18n.language === "en" ? (
              <span>🇧🇭 BHD</span>
            ) : (
              <span>BHD 🇧🇭</span>
            )
          ) : getCurrencyFromLocalStorage() === "kw" ? (
            i18n.language === "en" ? (
              <span>🇰🇼 KWD</span>
            ) : (
              <span>KWD 🇰🇼</span>
            )
          ) : getCurrencyFromLocalStorage() === "om" ? (
            i18n.language === "en" ? (
              <span>🇴🇲 OMR</span>
            ) : (
              <span>OMR 🇴🇲</span>
            )
          ) : i18n.language === "en" ? (
            <span>🇾🇪 YER</span>
          ) : (
            <span>YER 🇾🇪</span>
          )}
        </span>
      </MenuButton>
    );
  }

  function CurrencyMenu() {
    return (
      <Menu initialFocusRef={-1}>
        <CurrencyMenuButtonBasedOnSelectedCurrencyAndLanguage
          isForMobileMenu={false}
        />
        <MenuList
          zIndex={1000}
          display={"flex"}
          flexDirection={"column"}
          alignContent={"center"}
          justifyContent={"space-between"}
        >
          <CurrencyMenuItems />
        </MenuList>
      </Menu>
    );
  }

  function LanguageMenu() {
    return (
      <Menu initialFocusRef={-1}>
        <MenuButton
          variant="ghost"
          as={Button}
          leftIcon={<FontAwesomeIcon icon={faGlobe} />}
          rightIcon={<ChevronDownIcon />}
        >
          <span>{i18n.language === "en" ? "EN" : "العربية"}</span>
        </MenuButton>
        <MenuList>
          <MenuItem
            onClick={() =>
              //handleChangeLanguage("en")
              changeLanguage("en")
            }
          >
            <span>{t("common:en")}</span>
          </MenuItem>
          <MenuItem
            onClick={
              () => changeLanguage("ar")
              //handleChangeLanguage("ar")
            }
          >
            <span>{t("common:ar")}</span>
          </MenuItem>
        </MenuList>
      </Menu>
    );
  }

  function AdminMenuOptions() {
    return (
      <MenuGroup>
        {currentUser?.type === ROLES.ADMINISTRATOR && (
          <>
            <MenuGroup>
              <Link to="/pending-reservations">
                <MenuItem icon={<FontAwesomeIcon icon={faBook} />}>
                  {t("pendingReservations")}
                </MenuItem>
              </Link>
              <Link to="/all-reservations">
                <MenuItem icon={<FontAwesomeIcon icon={faBook} />}>
                  {t("allReservations")}
                </MenuItem>
              </Link>
              <Link to="/invite-user">
                <MenuItem icon={<FontAwesomeIcon icon={faUserPlus} />}>
                  {t("invite")}
                </MenuItem>
              </Link>
            </MenuGroup>
            <MenuDivider />
          </>
        )}

        <MenuGroup title={t("accommodationsName")}>
          <MenuGroup>
            <Link to="/manage-accommodations">
              <MenuItem icon={<FontAwesomeIcon icon={faHouseCircleCheck} />}>
                {t("manage")}
              </MenuItem>
            </Link>
            <Link to="/create-accommodation">
              <MenuItem icon={<PlusSquareIcon />}>{t("addNew")}</MenuItem>
            </Link>
          </MenuGroup>
        </MenuGroup>
        <MenuDivider />
        <MenuGroup title={t("vehiclesName")}>
          <MenuGroup>
            <Link to="/manage-vehicles">
              <MenuItem icon={<FontAwesomeIcon icon={faCar} />}>
                {t("manage")}
              </MenuItem>
            </Link>
            <Link to="/create-vehicle">
              <MenuItem icon={<PlusSquareIcon />}>{t("addNew")}</MenuItem>
            </Link>
          </MenuGroup>
        </MenuGroup>
      </MenuGroup>
    );
  }

  function AvatarDropDown() {
    return (
      <Menu initialFocusRef={-1} zIndex={5}>
        <MenuButton as={Button} variant="ghost" rightIcon={<ChevronDownIcon />}>
          <Avatar
            size="sm"
            name={
              isAuthenticated &&
              !isVisitor &&
              `${currentUser?.firstName} ${currentUser?.lastName}`
            }
            bg="gray.500"
          />
        </MenuButton>
        <MenuList zIndex={5}>
          {isAuthenticated && !isVisitor ? (
            <MenuGroup>
              {[ROLES.ADMINISTRATOR, ROLES.DOMESTIC_PARTNER].includes(
                currentUser?.type
              ) && <AdminMenuOptions />}
              <MenuDivider />
              <MenuItem
                icon={<FontAwesomeIcon icon={faRightFromBracket} />}
                onClick={() => logout()}
              >
                {t("logout")}
              </MenuItem>
            </MenuGroup>
          ) : (
            <MenuGroup>
              <Link to="/login">
                <MenuItem>{t("login")}</MenuItem>
              </Link>
              <Link to="/register">
                <MenuItem>{t("register")}</MenuItem>
              </Link>
            </MenuGroup>
          )}
        </MenuList>
      </Menu>
    );
  }

  function LanguageAndCurrencyMenu() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedCurrencyOption, setSelectedCurrencyOption] = useState(
      getCurrencyFromLocalStorage() || "eu"
    );
    const [selectedLanguageOption, setSelectedLanguageOption] = useState(
      i18n.language
    );
    const prevSelectedCurrencyOption = useRef(
      getCurrencyFromLocalStorage() || "eu"
    );

    let qurrencySymbol = "";

    for (const code in CURRENCY_SYMBOLS) {
      if (localStorage.getItem("currencyCode") === null) {
        qurrencySymbol = CURRENCY_SYMBOLS["eu"];
        break;
      }
      if (code === localStorage.getItem("currencyCode")) {
        qurrencySymbol = CURRENCY_SYMBOLS[code];
        break;
      }
    }
    return (
      <>
        <Button onClick={onOpen} variant={"ghost"}>
          {qurrencySymbol} &nbsp; <FontAwesomeIcon icon={faGlobe} />
        </Button>

        <Modal onClose={onClose} size={"full"} isOpen={isOpen}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader fontSize={"xs"}>Display settings</ModalHeader>
            <ModalCloseButton />
            <ModalBody marginTop={"10px"}>
              <VStack gap={3}>
                <InputGroupWithLabel label={"Language"}>
                  <Select
                    defaultValue={i18n.language}
                    onChange={(e) => {
                      setSelectedLanguageOption(e.target.value);
                    }}
                  >
                    <option value="en">{t("common:en")}</option>
                    <option value="ar">{t("common:ar")}</option>
                  </Select>
                </InputGroupWithLabel>
                <InputGroupWithLabel label={"Currency"}>
                  <Select
                    defaultValue={getCurrencyFromLocalStorage()}
                    onChange={(e) => {
                      setSelectedCurrencyOption(e.target.value);
                    }}
                  >
                    {i18n.language === "en" ? (
                      <>
                        <option value="eu">
                          <span
                            class="flag"
                            width="1.5em"
                            verticalAlign="middle"
                          >
                            {UNICODE_FLAGS["eu"]}
                          </span>
                          <span class="text">EUR</span>
                        </option>

                        <option value="tr">
                          <span
                            class="flag"
                            width="1.5em"
                            verticalAlign="middle"
                          >
                            {UNICODE_FLAGS["tr"]}
                          </span>
                          <span class="text">TRY</span>
                        </option>
                        <option value="sa">
                          <span
                            class="flag"
                            width="1.5em"
                            verticalAlign="middle"
                          >
                            {UNICODE_FLAGS["sa"]}
                          </span>
                          <span class="text">SAR</span>
                        </option>
                        <option value="ae">
                          <span
                            class="flag"
                            width="1.5em"
                            verticalAlign="middle"
                          >
                            {UNICODE_FLAGS["ae"]}
                          </span>
                          <span class="text">AED</span>
                        </option>
                        <option value="qa">
                          <span
                            class="flag"
                            width="1.5em"
                            verticalAlign="middle"
                          >
                            {UNICODE_FLAGS["qa"]}
                          </span>
                          <span class="text">QAR</span>
                        </option>
                        <option value="bh">
                          <span
                            class="flag"
                            width="1.5em"
                            verticalAlign="middle"
                          >
                            {UNICODE_FLAGS["bh"]}
                          </span>
                          <span class="text">BHD</span>
                        </option>
                        <option value="kw">
                          <span
                            class="flag"
                            width="1.5em"
                            verticalAlign="middle"
                          >
                            {UNICODE_FLAGS["kw"]}
                          </span>
                          <span class="text">KWD</span>
                        </option>
                        <option value="om">
                          <span
                            class="flag"
                            width="1.5em"
                            verticalAlign="middle"
                          >
                            {UNICODE_FLAGS["om"]}
                          </span>
                          <span class="text">OMR</span>
                        </option>
                        <option value="ye">
                          <span
                            class="flag"
                            width="1.5em"
                            verticalAlign="middle"
                          >
                            {UNICODE_FLAGS["ye"]}
                          </span>
                          <span class="text">YER</span>
                        </option>
                      </>
                    ) : (
                      <>
                        <option value="eu">
                          <span class="text">EUR</span>
                          <span
                            class="flag"
                            width="1.5em"
                            verticalAlign="middle"
                          >
                            {UNICODE_FLAGS["eu"]}
                          </span>
                        </option>
                        <option value="tr">
                          <span class="text">TRY</span>
                          <span
                            class="flag"
                            width="1.5em"
                            verticalAlign="middle"
                          >
                            {UNICODE_FLAGS["tr"]}
                          </span>
                        </option>
                        <option value="sa">
                          <span class="text">SAR</span>
                          <span
                            class="flag"
                            width="1.5em"
                            verticalAlign="middle"
                          >
                            {UNICODE_FLAGS["sa"]}
                          </span>
                        </option>
                        <option value="ae">
                          <span class="text">AED</span>
                          <span
                            class="flag"
                            width="1.5em"
                            verticalAlign="middle"
                          >
                            {UNICODE_FLAGS["ae"]}
                          </span>
                        </option>
                        <option value="qa">
                          <span class="text">QAR</span>
                          <span
                            class="flag"
                            width="1.5em"
                            verticalAlign="middle"
                          >
                            {UNICODE_FLAGS["qa"]}
                          </span>
                        </option>
                        <option value="bh">
                          <span class="text">BHD</span>
                          <span
                            class="flag"
                            width="1.5em"
                            verticalAlign="middle"
                          >
                            {UNICODE_FLAGS["bh"]}
                          </span>
                        </option>
                        <option value="kw">
                          <span class="text">KWD</span>
                          <span
                            class="flag"
                            width="1.5em"
                            verticalAlign="middle"
                          >
                            {UNICODE_FLAGS["kw"]}
                          </span>
                        </option>
                        <option value="om">
                          <span class="text">OMR</span>
                          <span
                            class="flag"
                            width="1.5em"
                            verticalAlign="middle"
                          >
                            {UNICODE_FLAGS["om"]}
                          </span>
                        </option>
                        <option value="ye">
                          <span class="text">YER</span>
                          <span
                            class="flag"
                            width="1.5em"
                            verticalAlign="middle"
                          >
                            {UNICODE_FLAGS["ye"]}
                          </span>
                        </option>
                      </>
                    )}
                  </Select>
                </InputGroupWithLabel>
              </VStack>
            </ModalBody>
            <ModalFooter>
              <Button
                borderRadius={15}
                bg={"teal"}
                width={"100%"}
                onClick={() => {
                  onClose();
                  changeLanguage(selectedLanguageOption);
                  if (
                    selectedCurrencyOption !==
                    prevSelectedCurrencyOption.current
                  ) {
                    saveCurrencyToLocalStorage(selectedCurrencyOption);
                    prevSelectedCurrencyOption.current = selectedCurrencyOption;
                  }
                }}
                textColor={"white"}
              >
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  }

  return (
    <header>
      <Flex align="center">
        <Link to="/">{logo}</Link>
        <Spacer />
        {width < desktopBreakpoint ? (
          <LanguageAndCurrencyMenu />
        ) : (
          <>
            <CurrencyMenu />
            <LanguageMenu />
          </>
        )}
        <AvatarDropDown />
      </Flex>
    </header>
  );
}

export default Header;
