import { FUEL_TYPES, VEHICLE_TYPES } from "../../constants";
import { useAuth } from "../../contexts/AuthContext";
import { createVehicle, submitPhotosToVehicle } from "../../services/vehicles";
import VehicleForm from "../../components/vehicle-form/VehicleForm";
import { useEntityCreation, usePhotoUpload } from "../../utils/sharedMutations";
import { preparePhotosForUpload } from "../../utils/helpers";

function CreateVehicle() {
  const { userData } = useAuth();

  const submitPhotosMutation = usePhotoUpload(({ vehicleId, photos }) =>
    submitPhotosToVehicle(vehicleId, photos)
  );

  const createVehicleMutation = useEntityCreation((vehicleData) =>
    createVehicle({
      ...vehicleData,
      contact: {
        ...vehicleData.contact,
        phoneNumber: vehicleData.contact.phoneNumber,
      },
    })
  );

  const onSubmit = async (vehicleData, photos) => {
    const vehicle = await createVehicleMutation.mutateAsync(vehicleData);

    if (photos.length > 0)
      await submitPhotosMutation.mutateAsync({
        vehicleId: vehicle.data.id,
        photos: preparePhotosForUpload(photos),
      });
  };
  return (
    <VehicleForm
      vehicleData={{
        name: "",
        vehicleType: VEHICLE_TYPES.HATCHBACK,
        fuelType: FUEL_TYPES.PETROL,
        numberOfSeats: 4,
        available: true,
        automatic: false,
        productionYear: "",
        pricePerDay: "",
        contact: {
          name: userData ? userData.firstName + " " + userData.lastName : "",
          phoneNumber: userData ? userData.telephoneNumber : "",
        },
      }}
      onSubmit={onSubmit}
      isSubmitting={
        createVehicleMutation.isLoading || submitPhotosMutation.isLoading
      }
    />
  );
}

export default CreateVehicle;
