import axiosInstance from "./axiosInstance";

const createBooking = (bookingData) =>
  axiosInstance.post(`/bookings`, { booking: bookingData });

const getBookings = (page) =>
  axiosInstance.get(`/bookings`, {
    params: page,
  });

const getBooking = (bookingId) => axiosInstance.get(`/bookings/${bookingId}`);

const getPendingBookings = (page) =>
  axiosInstance.get(`/bookings/pending`, {
    params: page,
  });

const changeBookingStatus = (bookingId, booking) =>
  axiosInstance.patch(`/bookings/${bookingId}/`, { booking });

const submitPhotosToBooking = (bookingId, images, reservationPassport) => {
  //console.log(bookingId);

  const formData = new FormData();
  images.forEach((image, index) => {
    formData.append(`${index}`, image);
  });
  formData.append("reservation_passport", JSON.stringify(reservationPassport));

  return axiosInstance.post(
    `/bookings/${bookingId}/google_drive_images`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        "Key-Inflection": "camel",
      },
    }
  );
};

export {
  createBooking,
  getBookings,
  getBooking,
  getPendingBookings,
  submitPhotosToBooking,
  changeBookingStatus,
};
