import { useTranslation } from "react-i18next";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Button,
  Center,
  Stack,
  Heading,
  Divider,
  Box,
  VStack,
  HStack,
  Image,
  CloseButton,
  NumberInput,
  NumberInputField,
  Flex,
} from "@chakra-ui/react";
import singleBedIcon from "../../assets/single-bed-icon.svg";
import doubleBedIcon from "../../assets/double-bed-icon.svg";
import extraBedIcon from "../../assets/extra-bed-icon.svg";
import ReactInputMask from "react-input-mask";
import { PlusSquareIcon } from "@chakra-ui/icons";
import { useDropZoneUpload } from "../../utils/customHooks";

function AccommodationUnitForm({
  accommodationUnitData,
  onSubmit,
  editable = false,
  isSubmitting,
  allowPhotoUpload = true,
}) {
  const { t } = useTranslation("common");
  const {
    selectedFiles,
    cleanBuffer,
    getRootProps,
    getInputProps,
    handleRemoveFile,
  } = useDropZoneUpload(accommodationUnitData.images);

  const SeasonalPrice = ({
    id,
    season,
    pricePerNight,
    onRemove,
    onPriceChange,
    onPeriodChange,
  }) => {
    return (
      <HStack gap={4} align="baseline">
        <Flex align="baseline">
          <FormLabel fontSize="sm">{t("periodLabel")}</FormLabel>
          <Input
            value={season}
            width={"140px"}
            as={ReactInputMask}
            mask="99-99,99-99"
            alwaysShowMask
            onChange={onPeriodChange}
          />
        </Flex>
        <Flex align="flex-end">
          <FormLabel fontSize="sm">{t("priceLabel")}</FormLabel>
          <NumberInput
            width="90px"
            placeholder="120"
            value={pricePerNight}
            variant="filled"
          >
            <NumberInputField onChange={onPriceChange} />
          </NumberInput>
        </Flex>
        <CloseButton size="sm" onClick={onRemove} />
      </HStack>
    );
  };

  return (
    <Center>
      <Stack width={"500px"} spacing={3}>
        <Heading size={"lg"} mb={8}>
          {editable ? t("editAccommodationUnit") : t("createAccommodationUnit")}
        </Heading>
        <Formik
          initialValues={accommodationUnitData}
          enableReinitialize
          validationSchema={Yup.object({
            name: Yup.string().required(t("requiredFieldMessage")),
            defaultPrice: Yup.number()
              .required(t("requiredFieldMessage"))
              .min(1, t("priceTooLowMessage")),
          })}
          onSubmit={(values, actions) => {
            const newlyAddedFiles = selectedFiles
              .filter((file) => !file.id)
              .map((file) => file.file);

            onSubmit(
              {
                ...values,
                images_attributes: selectedFiles.filter((file) => file.id),
              },
              newlyAddedFiles
            );

            if (!editable) {
              actions.resetForm();
              cleanBuffer();
            }
          }}
        >
          {(props) => (
            <Form>
              <Stack spacing={3}>
                <Field name="name">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.name && form.touched.name}
                    >
                      <Input
                        isDisabled={editable}
                        {...field}
                        placeholder={t("nameInputPlaceholder")}
                        variant="filled"
                      />
                      <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <Divider />

                <VStack mb="4">
                  <HStack>
                    <Image boxSize="25px" src={singleBedIcon} />
                    <Field name="singleBedCount">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.singleBedCount &&
                            form.touched.singleBedCount
                          }
                        >
                          <NumberInput
                            min={0}
                            max={10}
                            variant="filled"
                            value={field.value}
                          >
                            <NumberInputField id={`single-bed`} {...field} />
                          </NumberInput>
                        </FormControl>
                      )}
                    </Field>
                  </HStack>

                  <HStack>
                    <Image boxSize="25px" src={doubleBedIcon} />
                    <Field name="doubleBedCount">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.doubleBedCount &&
                            form.touched.doubleBedCount
                          }
                        >
                          <NumberInput
                            min={0}
                            max={10}
                            variant="filled"
                            value={field.value}
                          >
                            <NumberInputField id={`single-bed`} {...field} />
                          </NumberInput>
                        </FormControl>
                      )}
                    </Field>
                  </HStack>

                  <HStack>
                    <Image boxSize="25px" src={extraBedIcon} />
                    <Field name="extraBedCount">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.extraBedCount &&
                            form.touched.extraBedCount
                          }
                        >
                          <NumberInput
                            min={0}
                            max={10}
                            variant="filled"
                            value={field.value}
                          >
                            <NumberInputField id={`single-bed`} {...field} />
                          </NumberInput>
                        </FormControl>
                      )}
                    </Field>
                  </HStack>
                </VStack>

                <Divider />

                <FormLabel>{t("facilitiesLabel")}</FormLabel>
                <Field name="facilities">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.facilities && form.touched.facilities
                      }
                    >
                      <Input
                        {...field}
                        placeholder={"Balcony, Sea view, ..."}
                        variant="filled"
                      />
                    </FormControl>
                  )}
                </Field>

                <Divider />

                <FormLabel>{t("seasonalPricesLabel")}</FormLabel>
                <Field name="defaultPrice">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.defaultPrice && form.touched.defaultPrice
                      }
                    >
                      <FormLabel fontSize="sm">
                        {t("defaultPriceLabel")}
                      </FormLabel>
                      <NumberInput variant="filled" {...field}>
                        <NumberInputField {...field} />
                      </NumberInput>
                      <FormErrorMessage>
                        {form.errors.defaultPrice}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <Field name="seasonalPricesAttributes">
                  {({ field, form }) => (
                    <FormControl>
                      <VStack gap="2">
                        {field.value?.map((seasonalPrice, index) => {
                          if (seasonalPrice._destroy) {
                            return null;
                          }
                          return (
                            <SeasonalPrice
                              onRemove={() => {
                                if (editable) {
                                  props.setFieldValue(
                                    "seasonalPricesAttributes",
                                    props.values.seasonalPricesAttributes.map(
                                      (p, i) => {
                                        if (i === index) {
                                          return {
                                            ...field.value[index],
                                            _destroy: true,
                                          };
                                        } else {
                                          return p;
                                        }
                                      }
                                    )
                                  );
                                } else
                                  props.setFieldValue(
                                    "seasonalPricesAttributes",
                                    props.values.seasonalPricesAttributes.filter(
                                      (_, i) => i !== index
                                    )
                                  );
                              }}
                              onPriceChange={(e) => {
                                props.setFieldValue(
                                  `seasonalPricesAttributes.${index}.pricePerNight`,
                                  e.target.value
                                );
                              }}
                              onPeriodChange={(e) => {
                                props.setFieldValue(
                                  `seasonalPricesAttributes.${index}.season`,
                                  e.target.value
                                );
                              }}
                              key={index}
                              {...seasonalPrice}
                            />
                          );
                        })}
                      </VStack>
                    </FormControl>
                  )}
                </Field>

                <Button
                  onClick={() => {
                    props.setFieldValue(
                      "seasonalPricesAttributes",
                      props.values.seasonalPricesAttributes.concat({})
                    );
                  }}
                  leftIcon={<PlusSquareIcon />}
                  size="sm"
                  variant="outline"
                >
                  {t("addSeasonalPrice")}
                </Button>

                <Divider />

                {allowPhotoUpload && (
                  <div>
                    <FormLabel>{t("imagesLabel")}</FormLabel>
                    <div
                      {...getRootProps()}
                      style={{ padding: "20px", border: "2px dashed #ccc" }}
                    >
                      <input {...getInputProps()} />
                      <p>{t("dropZoneMessage")}</p>
                    </div>
                    <Box mt="4">
                      <HStack wrap="wrap" spacing="4">
                        {selectedFiles.map((image, index) => {
                          if (image._destroy) {
                            return null;
                          }
                          return (
                            <Box key={index}>
                              <Box
                                width="50px"
                                height="50px"
                                position={"relative"}
                              >
                                <Image src={image.url} />
                                <CloseButton
                                  size={"sm"}
                                  position={"absolute"}
                                  top={"-10px"}
                                  right={"-10px"}
                                  onClick={() =>
                                    handleRemoveFile(index, image.id)
                                  }
                                />
                              </Box>
                            </Box>
                          );
                        })}
                      </HStack>
                    </Box>
                  </div>
                )}

                <Button
                  width="full"
                  isLoading={isSubmitting}
                  type="submit"
                  colorScheme="whatsapp"
                  mt={4}
                >
                  {editable
                    ? t("editAccommodationUnit")
                    : t("createAccommodationUnit")}
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      </Stack>
    </Center>
  );
}

export default AccommodationUnitForm;
