import {
  Badge,
  Box,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  HStack,
  Heading,
  Image,
  Skeleton,
  SkeletonText,
  Text,
  VStack,
} from "@chakra-ui/react";
import "./AccommodationCard.scss";
import Amenity from "../accommodation/Amenity";

const CARD_WIDTH = { base: "100%", lg: "600px" };
const IMAGE_WIDTH = { base: "160px", sm: "220px" };

const AccommodationCardSkeleton = () => {
  return (
    <Card
      w={CARD_WIDTH}
      variant="unstyled"
      className="accommodation-card"
      borderRadius="none"
      display="flex"
      flexDir="row"
      alignItems="stretch"
      minH="200px"
      maxH="240px"
    >
      <Skeleton minW={IMAGE_WIDTH} w={IMAGE_WIDTH} />
      <Box w="100%" padding="16px">
        <SkeletonText skeletonHeight="5" width="200px" noOfLines={1} mb={4} />
        <SkeletonText noOfLines={2} spacing={3} />
      </Box>
    </Card>
  );
};

const AccommodationCard = ({ data }) => {
  const {
    name,
    address,
    amenities,
    rating,
    previewImageUrl,
    pricePerNight,
    totalPrice,
  } = data;

  // iterate through amenities and return the first two that are true
  const [firstAmenity = false, secondAmenity = false] = Object.keys(
    amenities
  ).reduce((acc, key) => {
    if (amenities[key]) {
      acc.push(key);
    }
    return acc;
  }, []);

  const cardPadding = "12px";

  return (
    <Card
      className="accommodation-card"
      variant="outline"
      borderRadius="lg"
      w={CARD_WIDTH}
      display="flex"
      flexDir="row"
      alignItems="stretch"
      minH="200px"
      maxH="240px"
    >
      <Box flexShrink={0} w={IMAGE_WIDTH} minW={IMAGE_WIDTH} h="100%">
        <Image
          src={previewImageUrl}
          alt={name}
          h="100%"
          objectFit="cover"
          w="100%"
        />
      </Box>
      <VStack w="100%" spacing={0} align="left">
        <CardHeader padding={cardPadding}>
          <Box>
            <Heading fontSize="xl" paddingBottom={1}>
              {name}
            </Heading>
            <Text fontSize="small">{address}</Text>
          </Box>
        </CardHeader>
        <CardBody padding={cardPadding}>
          <HStack spacing={2} wrap="wrap">
            <Amenity
              amenityKey={firstAmenity}
              size={{ base: "xs", sm: "sm" }}
            />
            <Amenity
              amenityKey={secondAmenity}
              size={{ base: "xs", sm: "sm" }}
            />
          </HStack>
        </CardBody>
        <CardFooter justify={"space-between"} padding={cardPadding}>
          <Text>
            <Badge fontSize="small" colorScheme="whatsapp">
              {rating}
            </Badge>
          </Text>
          <VStack>
            <HStack spacing={0}>
              <Text as="b" mr={1} dir="ltr">
                {pricePerNight}
              </Text>
              <Text fontSize="small">/night</Text>
            </HStack>
            <Text fontSize="md" as="b" dir="ltr">
              Total: {totalPrice}
            </Text>
          </VStack>
        </CardFooter>
      </VStack>
    </Card>
  );
};

export default AccommodationCard;
export { AccommodationCardSkeleton };
