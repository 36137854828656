import { Box, Button, Heading, VStack } from "@chakra-ui/react";
import Checkout from "../../payment/Checkout";
import { useState } from "react";

function BookingConfirmationStep({ prevStep, nextStep, activeStep }) {
  const [showPrevButton, setShowPrevButton] = useState(true);
  const showPreviousButton = (isVisible) => {
    setShowPrevButton(isVisible);
  };
  return (
    <Box border="1px" borderColor="gray.200" borderRadius="lg" padding="15px">
      <VStack align="flex-start" spacing="2">
        <Heading as="h3" size="sm">
          Finish reservation
        </Heading>
        <Checkout
          showPreviousButton={showPreviousButton}
          nextStep={nextStep}
          activeStep={activeStep}
        />
        {
          showPrevButton ? (
            <Button colorScheme="blue" size="sm" onClick={prevStep}>
              Previous
            </Button>
          ) : null /*
          <Button fontSize="xs">Go to my reservations</Button>
        */
        }
      </VStack>
    </Box>
  );
}

export default BookingConfirmationStep;
