import { useTranslation } from "react-i18next";
import { Flex, Text } from "@chakra-ui/react";
import { chakra } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaw,
  faEgg,
  faSpoon,
  faUtensils,
  faPersonSwimming,
  faWifi,
  faSquareParking,
  faTemperatureArrowUp,
  faTv,
  faBanSmoking,
  faWheelchair,
  faBellConcierge,
  faSnowflake,
  faShirt,
  faSatelliteDish,
} from "@fortawesome/free-solid-svg-icons";

const AMENITIES_ICONS = {
  breakfastOnlyService: faEgg,
  fullBoard: faSpoon,
  swimmingPool: faPersonSwimming,
  wiFi: faWifi,
  parking: faSquareParking,
  airConditioning: faSnowflake,
  heating: faTemperatureArrowUp,
  ironingService: faShirt,
  cableChannels: faSatelliteDish,
  nonSmokingRooms: faBanSmoking,
  petsAllowed: faPaw,
  flatScreenTv: faTv,
  restaurant: faUtensils,
  wheelchairAccessible: faWheelchair,
  roomService: faBellConcierge,
};

function Amenity({ amenityKey, size }) {
  const Icon = chakra(FontAwesomeIcon);
  const { t } = useTranslation(["accommodation"]);
  return (
    <Flex display={"flex"} alignItems="center">
      <Icon size={size} mr={2} w="24px" icon={AMENITIES_ICONS[amenityKey]} />
      <Text
        fontSize={size}
        as={"span"}
        whiteSpace={"nowrap"}
        alignSelf="center"
      >
        {t(amenityKey)}
      </Text>
    </Flex>
  );
}

export default Amenity;
