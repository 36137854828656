import React from "react";
import ReservationTabs from '../../components/reservation-tabs/ReservationTabs';
import { RESERVATION_TYPES } from '../../constants';

function Home({ selectedTab = RESERVATION_TYPES.STAY }) {
  return (
    <ReservationTabs selectedTab={selectedTab} />
  );
}

export default Home;
