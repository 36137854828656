import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useToast } from "@chakra-ui/react";
import { useRef } from "react";

function useEntityCreation(creationFunction) {
  const { t } = useTranslation("common");
  const toast = useToast();
  const toastRef = useRef();

  return useMutation({
    mutationFn: creationFunction,
    onMutate: (data) => {
      toastRef.current = toast({
        title: t("creatingEntityMessage"),
        status: "loading",
      });
    },
    onSuccess: () => {
      toast.update(toastRef.current, {
        title: t("entityCreationSuccessMessage"),
        status: "success",
      });
    },
    onError: (error) => {
      toast.update(toastRef.current, {
        title: error.message,
        status: "error",
      });
    },
  });
}

function useEntityUpdate(updateFunction) {
  const { t } = useTranslation("common");
  const toast = useToast();
  const toastRef = useRef();

  return useMutation({
    mutationFn: updateFunction,
    onMutate: (data) => {
      toastRef.current = toast({
        title: t("updatingEntityMessage"),
        status: "loading",
      });
    },
    onSuccess: () => {
      toast.update(toastRef.current, {
        title: t("entityUpdateSuccessMessage"),
        status: "success",
      });
    },
    onError: (error) => {
      toast.update(toastRef.current, {
        title: error.message,
        status: "error",
      });
    },
  });
}

function usePhotoUpload(photoUploadFunction) {
  const { t } = useTranslation("common");
  const toast = useToast();
  const toastRef = useRef();

  return useMutation({
    mutationFn: photoUploadFunction,
    onMutate: (data) => {
      toastRef.current = toast({
        title: t("uploadingPhotosMessage"),
        status: "loading",
      });
    },
    onSuccess: () => {
      toast.update(toastRef.current, {
        title: t("photosUploadSuccessMessage"),
        status: "success",
      });
    },
    onError: (error) => {
      toast.update(toastRef.current, {
        title: error.message,
        status: "error",
      });
    },
  });
}

export { useEntityCreation, useEntityUpdate, usePhotoUpload };
