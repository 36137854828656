import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";

const useWindowSize = () => {
  const [windowSize, setWindowSize] = React.useState({
    width: undefined,
    height: undefined,
  });

  React.useEffect(() => {
    const handleResize = () =>
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowSize;
};

const useDropZoneUpload = (initialPhotos = [], onDropAction = null) => {
  const [selectedFiles, setSelectedFiles] = useState(initialPhotos);

  const onDrop = useCallback((acceptedFiles) => {
    setSelectedFiles((prevSelectedFiles) => [
      ...prevSelectedFiles,
      ...acceptedFiles.map((file) => {
        return { id: null, url: URL.createObjectURL(file), file: file };
      }),
    ]);
    onDropAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cleanBuffer = () => {
    setSelectedFiles([]);
  };

  const handleRemoveFile = (index, id) => {
    const updatedFiles = [...selectedFiles];
    if (id) {
      updatedFiles[index] = { id: updatedFiles[index].id, _destroy: true };
    } else updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
    onDropAction();
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { "image/*": [".jpeg", ".png"] },
    multiple: true,
  });

  return {
    selectedFiles,
    cleanBuffer,
    getRootProps,
    getInputProps,
    handleRemoveFile,
  };
};

export { useWindowSize, useDropZoneUpload };
