import { React, useState, useEffect, forwardRef, useRef } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import noPreview from "../../assets/no-preview-available.png";
import {
  HStack,
  Skeleton,
  SkeletonText,
  Box,
  Image,
  Grid,
  GridItem,
  Badge,
  Flex,
  Text,
  Button,
  Container,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  VStack,
  chakra,
  useBreakpoint,
  useBreakpointValue,
  useDisclosure,
  theme,
  CloseButton,
  Divider,
  AbsoluteCenter,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faImage } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import "./Accommodation.scss";
import { getAccommodationBySlug } from "../../services/accommodations";
import { getRecommendedUnitsForAccommodation } from "../../services/accommodations";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { useWindowSize } from "../../utils/customHooks";
import Amenity from "../../components/accommodation/Amenity";
import {
  ACCOMMODATION_TYPES,
  RESERVATION_TYPES,
  desktopBreakpoint,
  CURRENCY_SYMBOLS,
} from "../../constants";
import GoogleMap from "../../components/google-map/GoogleMap";
import RoomCard from "../../components/room-card/RoomCard";
import { createBooking } from "../../services/bookings";
import _ from "lodash";
import ReservationSearchForm from "../../components/reservation-search-form/ReservationSearchForm";

export const FullScreenButton = ({ label, onClick }) => {
  const btnProps = {
    colorScheme: "blackAlpha",
    bottom: "10px",
    right: "10px",
    position: "absolute",
    variant: "solid",
    leftIcon: <FontAwesomeIcon icon={faImage} />,
    color: "white",
    zIndex: 5,
  };

  return (
    <Button onClick={onClick} {...btnProps}>
      {label}
    </Button>
  );
};

function GridImages({ imagesGrid, onClick, imagesCount }) {
  return (
    <Grid
      className="grid-images"
      position={"relative"}
      templateRows={"repeat(2, 1fr)"}
      templateColumns={"repeat(4, 1fr)"}
      onClick={onClick}
    >
      <GridItem className="grid-image-big" rowSpan={2} colSpan={2}>
        {imagesGrid[0] !== undefined ? (
          <Image cursor="pointer" h="100%" w={"100%"} src={imagesGrid[0]} />
        ) : (
          <Image src={noPreview} />
        )}
      </GridItem>
      {imagesGrid.slice(1, 5).map((image, index) => {
        return (
          <GridItem
            key={index}
            className="smaller-grid-images"
            row={Math.floor(index / 2) + 1}
            column={(index % 2) + 3}
          >
            {image !== undefined ? (
              <Image cursor="pointer" h="100%" w={"100%"} src={image} />
            ) : (
              <Image src={noPreview} />
            )}
          </GridItem>
        );
      })}

      <FullScreenButton label={imagesCount} />
    </Grid>
  );
}

const RoomPicker = forwardRef(
  ({ rooms, type, onRoomSelect, previewOnly, isHotel }, ref) => {
    const templateColumns = useBreakpointValue({
      base: "repeat(1, 1fr)",
      sm: "repeat(1, 1fr)",
      md: "repeat(2, 1fr)",
      lg: "repeat(3, 1fr)",
    });

    return (
      <Grid ref={ref} templateColumns={templateColumns} gap={3}>
        {rooms.map((room, index) => (
          <GridItem w="100%" key={index}>
            <RoomCard
              isHotel={isHotel}
              previewOnly={previewOnly}
              room={room}
              type={type}
              onSelect={(quantity) => {
                onRoomSelect(room.id, quantity);
              }}
            />
          </GridItem>
        ))}
      </Grid>
    );
  }
);

function Accommodation() {
  const { width } = useWindowSize();
  const [overlayShown, setOverlayShown] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showThumbnailsMobile, setShowThumbnailsMobile] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [roomQuantities, setRoomQuantities] = useState([]);
  const imageGalleryRef = useRef();
  const { t, i18n } = useTranslation(["accommodation", "common"]);
  const Icon = chakra(FontAwesomeIcon);
  const [recommendedUnits, setRecommendedUnits] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0.0);
  const [searchParams] = useSearchParams();
  const startDate = searchParams.get("start_date");
  const endDate = searchParams.get("end_date");
  let qurrencySymbol = "";
  if (localStorage.getItem("currencyCode") === null)
    qurrencySymbol = CURRENCY_SYMBOLS["eu"];

  for (const code in CURRENCY_SYMBOLS) {
    if (code === localStorage.getItem("currencyCode")) {
      qurrencySymbol = CURRENCY_SYMBOLS[code];
      break;
    }
  }

  const { isLoading, data, isSuccess, isFetching, isError, remove, refetch } =
    useQuery(
      ["accommodData", startDate, endDate],
      () => {
        return getAccommodationBySlug(slug, startDate, endDate);
      },
      {
        refetchOnWindowFocus: false,
        select: (data) => data.data,
        onSuccess: () => {
          //console.log("data", data);
          // Trigger refetch of the second query when the first query succeeds
          refetchRecommendedUnits();
        },
        enabled: true,
      }
    );

  const {
    isLoading: isLoadingRecommended,
    isFetching: isFetchingRecommended,
    isSuccess: isSuccessRecommendedUnits,
    data: recommendedData,
    refetch: refetchRecommendedUnits,
  } = useQuery(
    ["recommendedUnits"],
    () => {
      const params = {
        [_.snakeCase("startDate")]: searchParams.get("start_date"),
        [_.snakeCase("endDate")]: searchParams.get("end_date"),
        adults: parseInt(searchParams.get("adults")),
        children: childrenParams,
        [_.snakeCase("rooms")]: parseInt(searchParams.get("rooms")),
      };
      return getRecommendedUnitsForAccommodation(slug, params);
    },
    {
      refetchOnWindowFocus: false,
      select: (data) => data.data,
      onSuccess: (fetchedRecommendedUnits) => {
        const recommendedUnitsArray =
          fetchedRecommendedUnits.recommendedUnitsPerAccommodation[0].niz;

        const filteredUnits = data?.units.filter((unit) =>
          recommendedUnitsArray.some((item) => item.id === unit.id)
        );
        const updatedFilteredUnits = filteredUnits?.map((unit) => {
          const recommendedUnit = recommendedUnitsArray.find(
            (item) => item.id === unit.id
          );
          if (recommendedUnit) {
            return {
              ...unit,
              unitCount: recommendedUnit.unitCount,
              priceForPeriod: recommendedUnit.priceForPeriod,
              recommendedPricePerDay: recommendedUnit.recommendedPricePerDay,
            };
          } else {
            return {
              ...unit,
              unitCount: 0,
            };
          }
        });
        setRecommendedUnits(updatedFilteredUnits || []);
      },
    },
    {
      enabled: false,
    }
  );

  useEffect(() => {
    // Create a copy of roomQuantities to update
    const updatedRoomQuantities = { ...roomQuantities };

    recommendedData?.recommendedUnitsPerAccommodation[0].niz.forEach(
      (recommendedItem) => {
        const recommendedId = recommendedItem.id;

        // Default to 0 if 'unitCount' is not provided
        const recommendedQuantity = recommendedItem.unitCount || 0;
        if (updatedRoomQuantities.hasOwnProperty(recommendedId)) {
          updatedRoomQuantities[recommendedId] = recommendedQuantity;
        }
      }
    );
    setRoomQuantities(updatedRoomQuantities);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recommendedUnits]);

  useEffect(() => {
    if (!data) return;
    setRoomQuantities(Object.fromEntries(data.units.map(({ id }) => [id, 0])));
  }, [data]);

  useEffect(() => {
    return () => {
      remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (overlayShown) {
      imageGalleryRef?.current?.toggleFullScreen();
    }
  }, [overlayShown]);

  useEffect(() => {
    var temp = 0;
    for (const key in roomQuantities) {
      if (roomQuantities.hasOwnProperty(key)) {
        if (roomQuantities[key] > 0) {
          if (data && data.units) {
            var selectedUnit = data?.units?.find(
              (unit) => unit.id === parseInt(key)
            );

            for (var i = 0; i < roomQuantities[key]; i++) {
              const pricePerDay = parseFloat(
                selectedUnit.pricePerDay.replace(/[^0-9.-]+/g, "")
              ).toFixed(2);

              temp += pricePerDay * selectedUnit.numberOfDaysBooked;
            }
          }
          setTotalPrice(temp.toFixed(2));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomQuantities]);

  const { slug } = useParams();
  const roomPickerRef = useRef(null);
  const isBelowLg = ["base", "sm"].includes(useBreakpoint());
  const {
    isOpen: isMapModalOpen,
    onOpen: onMapModalOpen,
    onClose: onMapModalClose,
  } = useDisclosure();
  const anyRoomSelected = Object.values(roomQuantities).some(
    (quantity) => quantity > 0
  );

  const childrenParams = searchParams
    .getAll("children")
    .map((child) => parseInt(child));
  const childrenCount = childrenParams.length;

  const childrenUnderSixYearsOld = childrenParams.filter(
    (child) => child <= 6 && child > 2
  );

  const infantChildren = childrenParams.filter((child) => child <= 2);

  const guestsMaxOccupancy = () => {
    const childrenOverSixYearsOld =
      childrenCount - infantChildren.length - childrenUnderSixYearsOld.length >
      0
        ? childrenCount - childrenUnderSixYearsOld.length
        : 0;
    const adultsAndOlderChildren =
      parseInt(searchParams.get("adults")) + childrenOverSixYearsOld;
    if (adultsAndOlderChildren - childrenUnderSixYearsOld.length >= 0) {
      return adultsAndOlderChildren;
    }
    var difference =
      (adultsAndOlderChildren - childrenUnderSixYearsOld.length) * -1;
    var temp = 0;
    for (var i = 0; i <= difference; i++) if (i % 2 !== 0) temp++;
    return adultsAndOlderChildren + temp;
  };

  const preview = searchParams.get("preview");

  const navigate = useNavigate();
  const bookingCreation = useMutation({
    mutationFn: () => {
      return createBooking({
        bookingType: RESERVATION_TYPES.STAY.toLowerCase(),
        startDate: searchParams.get("start_date"),
        endDate: searchParams.get("end_date"),
        adults: parseInt(searchParams.get("adults")),
        children: parseInt(searchParams.get("children")),
        bookables: Object.keys(roomQuantities)
          .filter((roomId) => roomQuantities[roomId] > 0)
          .map((roomId) => {
            return {
              id: roomId,
              type: "AccommodationUnit",
              quantity: roomQuantities[roomId],
            };
          }),
      });
    },
    onError: (_error) => {},
    onSuccess: (data) => {
      navigate(`/book/${data.data.uuid}`);
    },
  });

  const selectedRoomCapacity = isLoading
    ? 0
    : _.sum(
        Object.keys(roomQuantities).map(
          (roomId) =>
            // eslint-disable-next-line eqeqeq
            data?.units.find((unit) => unit.id == roomId).capacity *
            roomQuantities[roomId]
        )
      );
  const allGuestsFitted = selectedRoomCapacity >= guestsMaxOccupancy();
  const handleReservationClick = () => {
    if (data.accommodationType === ACCOMMODATION_TYPES.VILLA) {
      // proceed with the reservation since it's booked as a whole
      alert("Proceed with the reservation");
    } else {
      roomPickerRef?.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const selectedRoomsToPills = () => {
    if (
      isLoading ||
      isLoadingRecommended ||
      isFetching ||
      isFetchingRecommended
    )
      return "";
    return Object.keys(roomQuantities)
      .filter((roomId) => roomQuantities[roomId] > 0)
      .map((roomId) => {
        // eslint-disable-next-line eqeqeq
        const room = data.units.find((room) => room.id == roomId);
        const quantity = roomQuantities[roomId];

        return (
          <Badge
            className="room-pill"
            variant={"outline"}
            mb={2}
            display="flex"
            size="md"
            alignItems="flex-start"
            flexWrap={"no-wrap"}
            borderRadius={7}
            direction={"column"}
          >
            <Text padding={1}>
              {room && quantity ? `${quantity}x ${room.name}` : ""}
            </Text>
            {data.type === ACCOMMODATION_TYPES.HOTEL && (
              <CloseButton
                color={"gray.500"}
                size="sm"
                onClick={() =>
                  setRoomQuantities({
                    ...roomQuantities,
                    [roomId]: 0,
                  })
                }
              />
            )}
          </Badge>
        );
      });
  };

  if (isError) {
    return (
      <Container maxWidth="1200px" padding="0px">
        <Text fontSize="xl" fontWeight="bold" mb={4}>
          {"Loading error!"}
        </Text>
        <Text fontSize={"lg"} mb={4}>
          Missing start or end date, please don't change url parameters
          manually!
        </Text>
        <Text fontSize={"md"} mb={3}>
          To continue, please go back to home page
        </Text>
      </Container>
    );
  }

  if (
    isLoading ||
    isLoadingRecommended ||
    isFetching ||
    isFetchingRecommended
  ) {
    const skeletonGridImages = (
      <Container maxWidth="1200px" padding="0px">
        <Grid
          gap="2px"
          height={"400px"}
          templateRows={"repeat(2, 1fr)"}
          templateColumns={"repeat(4, 1fr)"}
        >
          <GridItem rowSpan={2} colSpan={2}>
            <Skeleton height="400px" width="100%" />
          </GridItem>
          {Array(4)
            .fill("")
            .slice(0, 4)
            .map((index) => {
              return (
                <Skeleton
                  key={index}
                  className="skeleton-grid-smaller-images"
                />
              );
            })}
        </Grid>
      </Container>
    );
    return (
      <Flex gap={6} direction={"column"} paddingBottom="60px">
        {width > desktopBreakpoint ? (
          skeletonGridImages
        ) : (
          <Skeleton height={"400px"} />
        )}
        <Container maxWidth="1200px" padding="0px">
          <HStack>
            <Skeleton height={"20px"} width={"30%"} />
            {width > desktopBreakpoint ? (
              <SkeletonText size="10" />
            ) : (
              <SkeletonText size="5" />
            )}
          </HStack>
          <SkeletonText mt="4" noOfLines={4} spacing="4" skeletonHeight="2" />
          <Grid
            paddingTop={"10px"}
            gap="7px"
            autoRows="auto"
            templateColumns={{
              base: "100%",
              sm: "repeat(2, 50%)",
              lg: "repeat(2, 25%)",
            }}
          >
            {Array(5)
              .fill("")
              .map((_, i) => (
                <Skeleton height={"20px"} width={"50%"} />
              ))}
          </Grid>
        </Container>
      </Flex>
    );
  } else if (isSuccess && isSuccessRecommendedUnits) {
    let flagShowMoreButton = data.description.length > 250 ? true : false;

    let arrayOfImagesUrls = data.images.map((image) => {
      return image.url;
    });

    let amenitiesAvailable = Object.keys(data.amenities).filter(
      (amenity) => data.amenities[amenity]
    );

    return (
      <Container maxWidth="1200px" padding="0px" mb="60px">
        <Flex gap={6} direction={"column"}>
          {width > desktopBreakpoint && !overlayShown ? (
            <GridImages
              imagesGrid={arrayOfImagesUrls}
              onClick={function () {
                setOverlayShown(true);
              }}
              imagesCount={arrayOfImagesUrls.length}
            />
          ) : (
            <ImageGallery
              additionalClass={`accommodation-image-gallery ${
                isFullscreen ? "full-screen" : ""
              }`}
              isRTL={i18n.language === "ar"}
              showIndex={true}
              bg={"white"}
              showPlayButton={false}
              showThumbnails={
                (width >= desktopBreakpoint && overlayShown) ||
                (width < desktopBreakpoint && showThumbnailsMobile)
              }
              ref={imageGalleryRef}
              onScreenChange={(fullScreen) => {
                if (fullScreen) {
                  setShowThumbnailsMobile(true);
                  setIsFullscreen(true);
                } else {
                  setShowThumbnailsMobile(false);
                  setOverlayShown(false);
                  setIsFullscreen(false);
                }
              }}
              renderFullscreenButton={(onClick, _isFullscreen) => (
                <FullScreenButton
                  onClick={onClick}
                  label={arrayOfImagesUrls.length}
                />
              )}
              items={arrayOfImagesUrls.map((image) => {
                return {
                  original: image,
                  thumbnail: image,
                };
              })}
            />
          )}
          <VStack align="left">
            <Flex justifyContent="space-between" alignItems="flex-start" mb={1}>
              <Stack
                direction={isBelowLg ? "column" : "row"}
                alignItems={isBelowLg ? "flex-start" : "center"}
              >
                <Text
                  as="b"
                  fontSize="3xl"
                  lineHeight={"2rem"}
                  mr={isBelowLg ? 4 : 2}
                >
                  {data.name}
                </Text>
                <Badge as="span" fontSize="lg" colorScheme="teal">
                  {data.rating}
                </Badge>
              </Stack>
              {!preview && (
                <Button
                  colorScheme="teal"
                  variant="solid"
                  onClick={() => handleReservationClick()}
                >
                  {t("common:reserveButtonText")}
                </Button>
              )}
            </Flex>
            <Flex>
              <Icon
                float={"left"}
                boxSize={"20px"}
                w={"20px"}
                color={"teal.500"}
                icon={faLocationDot}
              />
              <Text marginLeft="5px" fontSize={"1em"}>
                {`${data.address}, ${data.city}`} <br />
                <Button
                  variant="link"
                  color="teal.500"
                  onClick={onMapModalOpen}
                >
                  {t("common:showOnMap")}
                </Button>
              </Text>
            </Flex>
          </VStack>
          <Box mb={4}>
            <Text as="span">
              {showMore
                ? data.description
                : `${data.description.substring(0, 250)}...`}
            </Text>
            {flagShowMoreButton && (
              <Button
                ml={2}
                variant={"link"}
                onClick={() => setShowMore(!showMore)}
              >
                {showMore ? t("common:showLess") : t("common:showMore")}
              </Button>
            )}
          </Box>

          <Grid
            autoRows="auto"
            gap={3}
            mb={4}
            templateColumns={{
              base: "100%",
              sm: "repeat(2, 50%)",
              lg: "repeat(2, 25%)",
            }}
          >
            {amenitiesAvailable.map((amenity, index) => {
              return (
                <Amenity
                  amenityKey={amenity}
                  key={index}
                  size={isBelowLg ? "md" : "lg"}
                />
              );
            })}
          </Grid>

          <ReservationSearchForm
            forHomePage={false}
            reservationType={RESERVATION_TYPES.STAY}
            showCitySelector={false}
            onSearchButtonClick={() => {
              Object.keys(roomQuantities).forEach((roomId) => {
                setRoomQuantities((prevRoomQuantities) => ({
                  ...prevRoomQuantities,
                  [roomId]: 0,
                }));
              });
              refetch();
            }}
            isFetching={isLoading}
          />

          {data.type === ACCOMMODATION_TYPES.HOTEL && (
            <>
              <Text fontSize="xl" fontWeight="bold" mb={4}>
                {`Recommended ${
                  recommendedData?.recommendedUnitsPerAccommodation[0].niz
                    .length > 1
                    ? `rooms`
                    : `room`
                } for ${searchParams.get("adults")} adults ${
                  childrenCount > 0 ? `and ${childrenCount} children` : ""
                }`}
              </Text>

              <Grid
                templateColumns={{
                  base: "repeat(1, 1fr)",
                  sm: "repeat(1, 1fr)",
                  md: "repeat(2, 1fr)",
                  lg: "repeat(3, 1fr)",
                }}
                gap={3}
              >
                {recommendedUnits.map((room, index) => (
                  <GridItem w="100%" key={index}>
                    {
                      <RoomCard
                        previewOnly={true}
                        room={room}
                        type={data.type}
                        onSelect={(quantity) => {}}
                      />
                    }
                  </GridItem>
                ))}
              </Grid>

              <Box position="relative" padding="10">
                <Divider />
                <AbsoluteCenter bg="white" px="4">
                  All rooms
                </AbsoluteCenter>
              </Box>
            </>
          )}
          <RoomPicker
            isHotel={
              data.accommodationType !== ACCOMMODATION_TYPES.HOTEL
                ? true
                : false
            }
            previewOnly={preview}
            ref={roomPickerRef}
            type={data.type}
            rooms={data.units}
            onRoomSelect={(id, quantity) => {
              setRoomQuantities({
                ...roomQuantities,
                [id]: quantity,
              });
            }}
          />
          <Modal
            isOpen={isMapModalOpen}
            onClose={onMapModalClose}
            size={isBelowLg ? "full" : "xl"}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{data.name}</ModalHeader>
              <ModalCloseButton />
              <ModalBody padding={0}>
                <GoogleMap
                  lat={data.latitude}
                  lng={data.longitude}
                  {...(!isBelowLg && { height: "500px" })}
                />
              </ModalBody>
            </ModalContent>
          </Modal>
        </Flex>
        {anyRoomSelected && (
          <Flex
            gap={6}
            zIndex={5}
            position="fixed"
            bottom={0}
            left={0}
            right={0}
            w="full"
            bg="white"
            borderTop={`1px solid ${theme.colors.gray[200]}`}
            justifyContent="space-between"
            alignItems="center"
            padding={4}
          >
            <HStack
              spacing={2}
              flexWrap={"wrap"}
              overflowY={"auto"}
              maxHeight={"90px"}
            >
              {selectedRoomsToPills()}
            </HStack>

            <VStack spacing={2}>
              <Text fontSize="md" fontWeight="bold" dir="ltr">
                {`Total: ${qurrencySymbol} ${totalPrice}`}
              </Text>
              <VStack spacing={2} alignItems="flex-end">
                <Button
                  colorScheme="teal"
                  variant="solid"
                  isDisabled={!allGuestsFitted}
                  isLoading={bookingCreation.isLoading}
                  onClick={() => bookingCreation.mutate()}
                >
                  {t("common:reserveButtonText")}
                </Button>
                {!allGuestsFitted && (
                  <Text fontSize="sm" color="red.500">
                    {`You still need to fit ${
                      guestsMaxOccupancy() - selectedRoomCapacity
                    } more people`}
                  </Text>
                )}
              </VStack>
            </VStack>
          </Flex>
        )}
      </Container>
    );
  }
}

export default Accommodation;
