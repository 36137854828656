import { Box, Spinner, Text } from "@chakra-ui/react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { useEffect, useRef, useState } from "react";

const DEFAULT_ZOOM_VALUE = 15;

const withMapWrapper = (MapComponent) => {
  const renderByStatus = (status) => {
    switch (status) {
      case Status.LOADING:
        return <Spinner />;
      case Status.ERROR:
        return <Text color="red">Error loading map</Text>;
      default:
        return null;
    }
  };

  return (props) => (
    <Wrapper
      apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
      version="beta"
      libraries={["marker"]}
      render={(status) => renderByStatus(status)}
    >
      <MapComponent {...props} />
    </Wrapper>
  );
};

function createMarker(map, lat, lng) {
  return new window.google.maps.Marker({
    position: { lat, lng },
    map,
  });
}

const GoogleMap = ({ lat, lng, height }) => {
  const latitude = parseFloat(lat);
  const longitude = parseFloat(lng);
  const mapOptions = {
    mapId: process.env.REACT_APP_GOOGLE_MAPS_MAP_ID,
    center: { lat: latitude, lng: longitude },
    zoom: DEFAULT_ZOOM_VALUE,
  };
  const [map, setMap] = useState();
  const mapRef = useRef();

  useEffect(() => {
    setMap(new window.google.maps.Map(mapRef.current, mapOptions));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (map) {
      createMarker(map, latitude, longitude);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  return <Box padding={0} h={height || "100vh"} ref={mapRef}></Box>;
};

export default withMapWrapper(GoogleMap);
