import React, { useState } from "react";
import { searchVehicles } from "../../services/vehicles";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Box,
  Button,
  Divider,
  Flex,
  FormLabel,
  HStack,
  Input,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Show,
  Stack,
  VStack,
  useDisclosure,
  Text,
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
} from "@chakra-ui/react";
import { AccommodationCardSkeleton } from "../../components/accommodation-card/AccommodationCard";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSliders, faFilter } from "@fortawesome/free-solid-svg-icons";
import VehicleCard from "../../components/vehicle-card/VehicleCard";
import { MdAirlineSeatReclineExtra } from "react-icons/md";
import ReservationTabs from "../../components/reservation-tabs/ReservationTabs";
import { RESERVATION_TYPES } from "../../constants";
import ClearFilterButton from "../../components/clear-filter/ClearFilterButton";
import Pagination from "../../components/pagination/Pagination";
import { createBooking } from "../../services/bookings";

function VehiclesSearch() {
  const _ = require("lodash");
  const [vehicles, setVehicles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [shouldResetParams, setShouldResetParams] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams.entries());

  const typesParams = searchParams.getAll("types");

  const seatsRangeMinParam = searchParams.get("seats_range_min");
  const seatsRangeMaxParam = searchParams.get("seats_range_max");

  const navigate = useNavigate();

  const initialSeatsRange = [Number(params.adults), Number(params.adults) + 4];

  const { data, isFetching } = useQuery(
    ["searchVehicles", params, currentPage],
    () =>
      searchVehicles({
        name: params.name || "",
        page: currentPage,
        types: typesParams || [],
        seats_range_min:
          seatsRangeMinParam !== null
            ? seatsRangeMinParam
            : initialSeatsRange[0],
        seats_range_max:
          seatsRangeMaxParam !== null
            ? seatsRangeMaxParam
            : initialSeatsRange[1],
        booking: {
          start_date: searchParams.get("start_date"),
          end_date: searchParams.get("end_date"),
          pickup_time: searchParams.get("pick_up_time"),
          dropoff_time: searchParams.get("drop_off_time"),
          location: searchParams.get("location"),
        },
      }),
    {
      refetchOnWindowFocus: false,
      select: (payload) => payload.data,
      onSuccess: (newData) => {
        if (shouldResetParams || currentPage === 1) {
          setShouldResetParams(false);
          setVehicles(newData.results);
        } else {
          setVehicles([...vehicles, ...newData.results]);
        }
      },
    }
  );

  const bookVehicleMutation = useMutation(
    (vehicleId) =>
      createBooking({
        bookingType: RESERVATION_TYPES.CAR.toLowerCase(),
        startDate: searchParams.get("start_date"),
        endDate: searchParams.get("end_date"),
        pickupLocation: searchParams.get("location"),
        pickupTime: searchParams.get("pick_up_time"),
        dropoffTime: searchParams.get("drop_off_time"),
        adults: parseInt(searchParams.get("adults")),
        children: parseInt(searchParams.get("children")),
        bookables: [{ id: parseInt(vehicleId), quantity: 1, type: "Vehicle" }],
      }),
    {
      onSuccess: (data) => {
        navigate(`/book/${data.data.uuid}`);
      },
    }
  );

  const renderSkeleton = (length = 3) => {
    return [...Array(length)].map((_item, index) => (
      <AccommodationCardSkeleton key={index} />
    ));
  };

  const renderVehicles = () => {
    return (
      <Pagination
        stackProps={{ w: "100%" }}
        isFetching={isFetching}
        hasNextPage={data?.pagination.nextPage !== null ? true : false}
        onShowMore={() => setCurrentPage(currentPage + 1)}
      >
        <VStack gap={5} w="inherit">
          {vehicles?.map((vehicle) => (
            <VehicleCard
              vehicle={vehicle}
              onSelect={(vehicleId) => {
                bookVehicleMutation.mutate(vehicleId);
              }}
            />
          ))}
        </VStack>
      </Pagination>
    );
  };

  const vehicleTypes = [
    "limo",
    "caravan",
    "hatchback",
    "mono_volumen",
    "suv",
    "van",
    "minibus",
  ];

  const { isOpen, onOpen, onClose } = useDisclosure();

  function Filters({ width = "250px" }) {
    const [filters, setFilters] = useState({
      name: params.name || "",
      types: Object.fromEntries(
        vehicleTypes.map((type) => [type, typesParams.includes(type)])
      ),
      seatsRangeMin:
        seatsRangeMinParam !== null ? seatsRangeMinParam : initialSeatsRange[0],
      seatsRangeMax:
        seatsRangeMaxParam !== null ? seatsRangeMaxParam : initialSeatsRange[1],
    });

    function makeTypesFilterText() {
      let text = Object.keys(filters.types)
        .filter((type) => filters.types[type])
        .map((type) => _.startCase(type))
        .join(", ");
      return text
        ? _.truncate(text, { length: 25, omission: "..." })
        : "Select...";
    }

    const rangeSliderThumbProps = {
      paddingLeft: 0.2,
      paddingRight: 0.2,
      boxSize: 7,
    };
    return (
      <VStack width={width} spacing={8}>
        <Box>
          <FormLabel size={"sm"}>Search by vehicle name</FormLabel>
          <Input
            value={filters.name}
            onChange={(e) => {
              setFilters({
                ...filters,
                name: e.target.value,
              });
            }}
          />
        </Box>
        <Divider />
        <Box width={"100%"} zIndex={9999}>
          <FormLabel size={"sm"}>Filter by vehicle category</FormLabel>
          <Menu closeOnSelect={false}>
            <MenuButton
              variant="outline"
              as={Button}
              colorScheme="teal"
              width={"100%"}
            >
              {makeTypesFilterText()}
            </MenuButton>
            <MenuList minWidth="240px">
              <MenuOptionGroup
                value={Object.keys(filters.types).filter(
                  (type) => filters.types[type]
                )}
                type="checkbox"
              >
                {Object.keys(filters.types).map((type) => (
                  <MenuItemOption
                    key={type}
                    value={type}
                    onClick={(e) => {
                      setFilters({
                        ...filters,
                        types: {
                          ...filters.types,
                          [e.target.innerText.toLowerCase().replace(/ /g, "_")]:
                            !filters.types[
                              e.target.innerText
                                .toLowerCase()
                                .replace(/ /g, "_")
                            ],
                        },
                      });
                    }}
                  >
                    {_.startCase(type)}
                  </MenuItemOption>
                ))}
              </MenuOptionGroup>
            </MenuList>
          </Menu>
        </Box>
        <Divider />
        <Box width={"100%"}>
          <Stack spacing={[1, 5]} direction={["column"]}>
            <FormLabel size={"sm"}>Filter by number of seats</FormLabel>

            <RangeSlider
              defaultValue={[filters.seatsRangeMin, filters.seatsRangeMax]}
              min={2}
              max={15}
              value={[filters.seatsRangeMin, filters.seatsRangeMax]}
              onChange={(val) => {
                setFilters({
                  ...filters,
                  seatsRangeMin: val[0],
                  seatsRangeMax: val[1],
                });
              }}
            >
              <RangeSliderTrack bg="lightgreen">
                <RangeSliderFilledTrack bg="teal" />
              </RangeSliderTrack>
              <RangeSliderThumb index={0} {...rangeSliderThumbProps}>
                <Box color="teal" as={MdAirlineSeatReclineExtra} />
                <Text fontSize="xs">{filters.seatsRangeMin}</Text>
              </RangeSliderThumb>
              <RangeSliderThumb index={1} {...rangeSliderThumbProps}>
                <Box color="teal" as={MdAirlineSeatReclineExtra} />
                <Text fontSize="xs">{filters.seatsRangeMax}</Text>
              </RangeSliderThumb>
            </RangeSlider>
          </Stack>
        </Box>
        <Divider />
        <Button
          leftIcon={<FontAwesomeIcon icon={faFilter} />}
          colorScheme="teal"
          width={"100%"}
          onClick={() => {
            if (isOpen) {
              onClose();
            }
            setCurrentPage(1);
            setSearchParams({
              ...params,
              name: filters.name,
              types: Object.keys(filters.types).filter(
                (type) => filters.types[type]
              ),
              seats_range_min: filters.seatsRangeMin,
              seats_range_max: filters.seatsRangeMax,
            });
          }}
        >
          Apply filters
        </Button>
        {(typesParams.length > 0 ||
          params.name ||
          seatsRangeMinParam ||
          seatsRangeMaxParam) && (
          <ClearFilterButton
            variant={"link"}
            onClick={() => {
              setCurrentPage(1);
              setSearchParams({
                location: params.location,
                start_date: params.start_date,
                end_date: params.end_date,
                pick_up_time: params.pickUpTime,
                drop_off_time: params.dropOffTime,
                adults: params.adults,
                children: params.children,
              });
            }}
          />
        )}
      </VStack>
    );
  }

  const handleSearchButtonClick = () => {
    setCurrentPage(1);
    setShouldResetParams(true);
  };

  return (
    <Flex direction="column">
      <ReservationTabs
        selectedTab={RESERVATION_TYPES.CAR}
        forHomePage={false}
        isFetching={shouldResetParams && isFetching}
        onSearchButtonClick={handleSearchButtonClick}
      />
      <Show below="md">
        <Button
          mt={"-35px"}
          mb={"35px"}
          width="full"
          leftIcon={<FontAwesomeIcon icon={faSliders} />}
          onClick={onOpen}
        >
          Sort & Filter
        </Button>
        <Modal onClose={onClose} size={"full"} isOpen={isOpen}>
          <ModalOverlay />
          <ModalContent padding={"25px"}>
            <ModalCloseButton />
            <Filters width="full" />
          </ModalContent>
        </Modal>
      </Show>

      <HStack align="flex-start" w="100%" spacing="16">
        <Show above="md">
          <Filters />
        </Show>
        <VStack
          w="inherit"
          direction="column"
          spacing="16px"
          alignItems="flex-start"
        >
          {isFetching && currentPage === 1
            ? renderSkeleton(4)
            : renderVehicles()}
          {vehicles?.length === 0 && !isFetching && (
            <Text>No vehicles found with selected filters</Text>
          )}
        </VStack>
      </HStack>
    </Flex>
  );
}

export default VehiclesSearch;
