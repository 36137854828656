import {
  Badge,
  Box,
  Button,
  Collapse,
  Divider,
  Flex,
  HStack,
  Heading,
  Icon,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import Amenity from "../../accommodation/Amenity";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { TbAutomaticGearbox } from "react-icons/tb";
import { MdAirlineSeatReclineExtra } from "react-icons/md";
import { BsFuelPumpFill } from "react-icons/bs";
import { FaCar } from "react-icons/fa6";
import { capitalize } from "lodash";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../../utils/helpers";
import { ACCOMMODATION_TYPES } from "../../../constants";

function BookingSummaryStep({ bookingType, ...props }) {
  const dateStringOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const { isOpen: isOpenUnitsCollapsible, onToggle: onUnitsCollapsibleToggle } =
    useDisclosure();

  const navigate = useNavigate();

  function AccommodationBookingSummary({ accommodation }) {
    return (
      <Box
        width="full"
        border="1px"
        borderColor="gray.200"
        borderRadius="lg"
        padding="15px"
      >
        <VStack align="flex-start" spacing="2">
          <Text fontSize="sm">{accommodation.type}</Text>
          <Heading as="h3" size="sm">
            {accommodation.name}
          </Heading>
          <Text fontSize="sm">{`${accommodation.address}, ${accommodation.city}`}</Text>
          <Text>
            <Badge fontSize="small" colorScheme="whatsapp">
              {accommodation.rating}
            </Badge>
          </Text>
          <HStack spacing={2} wrap="wrap">
            {Object.keys(accommodation.amenities).map((amenity, index) => (
              <Amenity key={index} amenityKey={amenity} size={"sm"} />
            ))}
          </HStack>
        </VStack>
      </Box>
    );
  }

  function CarBookingSummary({ car }) {
    return (
      <Box
        width="full"
        border="1px"
        borderColor="gray.200"
        borderRadius="lg"
        padding="15px"
      >
        <VStack align="flex-start" spacing="2">
          <Heading as="h3" size="sm">
            {car.name}
          </Heading>
          <Text>
            <Badge fontSize="small">{car.productionYear}</Badge>
          </Text>
          <Flex gap={5} flexWrap="wrap" fontSize="sm">
            <HStack>
              <Icon as={FaCar}></Icon>
              <Text>{capitalize(car.vehicleType)}</Text>
            </HStack>
            <HStack>
              <Icon as={TbAutomaticGearbox} />
              <Text>{car.automatic ? "Automatic" : "Manual"}</Text>
            </HStack>
            <HStack>
              <Icon as={MdAirlineSeatReclineExtra}></Icon>
              <Text>{car.numberOfSeats} seats</Text>
            </HStack>
            <HStack>
              <Icon as={BsFuelPumpFill}></Icon>
              <Text>{capitalize(car.fuelType)}</Text>
            </HStack>
          </Flex>
        </VStack>
      </Box>
    );
  }

  function TourBookingSummary({ tour }) {
    return (
      <Box
        width="full"
        border="1px"
        borderColor="gray.200"
        borderRadius="lg"
        padding="15px"
      >
        <VStack align="flex-start" spacing="2">
          <Text fontSize="sm">{"Tour"}</Text>
          <Heading as="h3" size="sm">
            {tour.name}
          </Heading>
        </VStack>
      </Box>
    );
  }

  const details = props.details;
  let accommodationUnits = [];
  if (bookingType === "stay" || bookingType === "tour")
    accommodationUnits = props.accommodationUnits;

  return (
    <VStack gap="5">
      {bookingType === "stay" ? (
        <AccommodationBookingSummary accommodation={props.accommodation} />
      ) : bookingType === "car" ? (
        <CarBookingSummary car={props.car} />
      ) : (
        <TourBookingSummary tour={props.tour} />
      )}
      <Box
        width="full"
        border="1px"
        borderColor="gray.200"
        borderRadius="lg"
        padding="15px"
      >
        <VStack align="flex-start" spacing="2">
          <Heading as="h3" size="sm">
            Reservation details
          </Heading>
          <HStack height="90px">
            <Flex flexDir="column" justifyContent="space-between">
              <Text fontSize="sm">
                {bookingType === "car" ? "Pick-up:" : "Check-in:"}
              </Text>
              <Text flexWrap="nowrap" fontWeight="bold">
                {`${details.checkIn.toLocaleDateString(
                  undefined,
                  dateStringOptions
                )}${details.pickupTime ? `, ${details.pickupTime}` : ", 12:00"}`}
              </Text>
            </Flex>
            <Divider orientation="vertical" />
            <Flex flexDir="column" justifyContent="space-between">
              <Text fontSize="sm">
                {bookingType === "car" ? "Drop-off:" : "Check-out:"}
              </Text>
              <Text fontWeight="bold">
                {`${details.checkOut.toLocaleDateString(
                  undefined,
                  dateStringOptions
                )}${details.dropoffTime ? `, ${details.dropoffTime}` : ", 12:00"}`}
              </Text>
            </Flex>
          </HStack>
          <Flex direction="column">
            <Text fontSize="sm">{`Total length of ${
              bookingType === "car" ? "rent" : "stay"
            }:`}</Text>
            <Text fontWeight="bold">{`${details.totalNights} ${
              bookingType === "stay" ? "night(s)" : "day(s)"
            }`}</Text>
          </Flex>

          <Divider />

          {(bookingType === "tour" || bookingType === "stay") && (
            <>
              <Button
                display="flex"
                justifyContent="space-between"
                width="full"
                variant="unstyled"
                onClick={onUnitsCollapsibleToggle}
                rightIcon={
                  isOpenUnitsCollapsible ? (
                    <ChevronUpIcon boxSize="6" />
                  ) : (
                    <ChevronDownIcon boxSize="6" />
                  )
                }
              >
                <Flex direction="column" align="flex-start">
                  <Text fontWeight="light" fontSize="sm">
                    You selected:
                  </Text>
                  <Text fontWeight="bold">{`${accommodationUnits.reduce(
                    (totalRooms, a) => totalRooms + a.quantity,
                    0
                  )} rooms for ${details.adults} adults, ${
                    details.children ? details.children : "0"
                  } children`}</Text>
                </Flex>
              </Button>
              <Collapse in={isOpenUnitsCollapsible} animateOpacity>
                <Flex direction="column" align="flex-start">
                  {accommodationUnits.map((unit, index) => (
                    <Text key={index} fontWeight="light" fontSize="sm">
                      {`${unit.quantity}x ${unit.name} (${formatDate(
                        unit.startDate
                      )}  -  ${formatDate(unit.endDate)})`}
                      {unit?.stay?.type === ACCOMMODATION_TYPES.HOTEL
                        ? ` - ${unit.stay.name}`
                        : ""}
                    </Text>
                  ))}
                </Flex>
              </Collapse>
            </>
          )}

          {bookingType === "car" && (
            <>
              <Flex direction="column">
                <Text fontSize="sm">Pick-up location:</Text>
                <Text fontWeight="bold">
                  {
                    //check jel za auto da uvijek Sarajeov ili odabire ??
                    details.pickupLocation || "Sarajevo"
                  }
                </Text>
              </Flex>
              <Flex direction="column">
                <Text fontSize="sm">Total passengers:</Text>
                <Text fontWeight="bold">{`${details.adults} adults, ${
                  details.children ? details.children : "0"
                } children`}</Text>
              </Flex>
            </>
          )}

          {bookingType === "tour" && (
            <>
              <Flex direction="column">
                <Text fontSize="sm">Pick-up location:</Text>
                <Text fontWeight="bold">
                  {<Text>{`${props.tour.pickupLocation}`}</Text>}
                </Text>
              </Flex>
              <Flex direction="column">
                <Text fontSize="sm">Vehicle category:</Text>
                <Text fontWeight="bold">{`${props.tour.selectedVehicleCategory.name} (${props.tour.selectedVehicleCategory.seats} passenger seats) with driver`}</Text>
              </Flex>
              <Flex direction="column">
                <Text fontSize="sm">Total guests:</Text>
                <Text fontWeight="bold">{`${details.adults} adults, ${
                  details.children ? details.children : "0"
                } children`}</Text>
              </Flex>
            </>
          )}

          <Button
            size="sm"
            variant="ghost"
            colorScheme="blue"
            padding="8px"
            margin="0 0 -8px -8px"
            onClick={() => navigate(`/${bookingType}s`)}
          >
            Change your selection
          </Button>
        </VStack>
      </Box>

      <Box
        border="1px"
        borderColor="gray.200"
        borderRadius="lg"
        padding="15px"
        width="full"
      >
        <VStack align="flex-start" spacing="2">
          <Heading as="h3" size="sm">
            Your price summary
          </Heading>
          <VStack width="full">
            {details.priceSummary.entries.map((entry, index) => (
              <Flex
                key={index}
                width="full"
                align="center"
                justifyContent="space-between"
                //wrap={"wrap"}
              >
                <>
                  {/*<Text wordBreak={true} fontSize="2xs">{`${entry.name}  (${formatDate(
                    entry.startDate
                  )} - ${formatDate(entry.endDate)})`}</Text>*/}
                  <Text wordBreak={true} fontSize="2xs">{`${entry.name}`}</Text>
                  <Text fontSize="xs">{`${entry.totalPricePerUnit}`}</Text>
                </>
              </Flex>
            ))}
            <Flex width="full" align="center" justifyContent="space-between">
              {details.priceSummary.vehicleCategory !== null &&
              details.priceSummary.vehicleCategory !== undefined ? (
                <>
                  <Text fontSize="2xs">Vehicle category:</Text>
                  <Text fontSize="xs">{`${details.priceSummary.vehicleCategory.name} (EUR${details.priceSummary.vehicleCategory.price} per day)`}</Text>
                </>
              ) : null}
            </Flex>
          </VStack>
          <Flex width="full" align="center" justifyContent="space-between">
            <Text fontWeight="bold">Total price:</Text>
            <Text fontWeight="bold">{`${details.priceSummary.totalPrice}`}</Text>
          </Flex>
        </VStack>
      </Box>
    </VStack>
  );
}

export default BookingSummaryStep;
