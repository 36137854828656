import React from "react";

import { VStack, Button } from "@chakra-ui/react";

function Pagination({
  hasNextPage,
  onShowMore,
  isFetching,
  stackProps,
  ...props
}) {
  return (
    <VStack gap={7} {...stackProps}>
      {props.children}
      {hasNextPage && (
        <Button
          alignSelf={"flex-start"}
          isLoading={isFetching}
          onClick={onShowMore}
        >
          Show more
        </Button>
      )}
    </VStack>
  );
}

export default Pagination;
