import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { fetchVehiclesByUser } from "../../services/vehicles";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Image,
  Button,
  Skeleton,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { EditIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";

function ManageVehicles() {
  const { t } = useTranslation("common");
  const [currentPage, setCurrentPage] = useState(1);
  const [vehiclesData, setVehiclesData] = useState([]);
  const [hasNextPage, setHasNextPage] = useState(false);

  const {
    data: vehicles,
    isLoading,
    isFetching,
  } = useQuery(
    ["manageVehicles", currentPage],
    () => fetchVehiclesByUser({ page: currentPage }),
    {
      keepPreviousData: true,

      refetchOnWindowFocus: false,
      enabled: true,
      select: (payload) => payload.data,
      onSuccess: (data) => {
        setVehiclesData((prevVehiclesData) => [
          ...prevVehiclesData,
          ...data.results,
        ]);
      },
    }
  );

  useEffect(() => {
    if (
      vehicles?.pagination.nextPage &&
      vehicles?.pagination.totalPages > currentPage
    )
      setHasNextPage(true);
    else setHasNextPage(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicles]);

  if (isLoading) {
    return (
      <div>
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <>
      <TableContainer>
        <Table variant="simple">
          <TableCaption>
            Vehicles added by administrators and domestic partners
          </TableCaption>
          <Thead>
            <Tr>
              <Th>{t("id")}</Th>
              <Th>{t("thumbnail")}</Th>
              <Th>{t("name")}</Th>
              <Th>{t("addedBy")}</Th>
              <Th>{t("actions")}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {vehiclesData.map((vehicle) => (
              <Tr key={vehicle.id}>
                <Td>{vehicle.id}</Td>
                <Td>
                  <Link to={`/vehicles/${vehicle.id}/edit`}>
                    <Image
                      borderRadius="lg"
                      src={vehicle.previewImageUrl}
                      alt="photo"
                      boxSize={"50px"}
                      objectFit="cover"
                    />
                  </Link>
                </Td>
                <Td>{vehicle.name}</Td>
                <Td>{vehicle.createdBy.firstName}</Td>
                <Td>
                  <Link to={`/vehicles/${vehicle.id}/edit`}>
                    <Button size="xs" variant="outline" leftIcon={<EditIcon />}>
                      {t("edit")}
                    </Button>
                  </Link>
                </Td>
              </Tr>
            ))}
            {isFetching && (
              <>
                <Tr>
                  <Td colSpan="100%">
                    <Skeleton height="20px" />
                  </Td>
                </Tr>
                <Tr>
                  <Td colSpan="100%">
                    <Skeleton height="20px" />
                  </Td>
                </Tr>
              </>
            )}
          </Tbody>
        </Table>
      </TableContainer>
      {hasNextPage && (
        <Button
          isLoading={isFetching}
          onClick={() => {
            setCurrentPage(currentPage + 1);
          }}
        >
          Show more
        </Button>
      )}
    </>
  );
}

export default ManageVehicles;
