import {
  Button,
  CloseButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Flex,
  Alert,
  AlertIcon,
  HStack,
  Badge,
  Text,
  VStack,
  Spinner,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { searchAccommodations } from "../../services/accommodations";
import { useSearchParams } from "react-router-dom";
import RoomCard from "../../components/room-card/RoomCard";
import { useEffect, useState, forwardRef, useRef } from "react";
import { AccommodationCardSkeleton } from "../../components/accommodation-card/AccommodationCard";
//import { ACCOMMODATION_TYPES } from "../../constants";

const RoomPicker = forwardRef(
  ({ rooms, type, onRoomSelect, previewOnly, isHotel }, ref) => {
    return (
      <Box ref={ref} gap={3}>
        {rooms.map((room, index) => (
          <Box w="100%" key={index}>
            <RoomCard
              isForTour={true}
              isHotel={isHotel}
              previewOnly={previewOnly}
              room={room}
              type={type}
              onSelect={(quantity) => {
                onRoomSelect(room.id, quantity);
              }}
            />
          </Box>
        ))}
      </Box>
    );
  }
);

const ChooseStayMenu = ({ tourStay, onStaySelect }) => {
  const [searchParams] = useSearchParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [roomQuantities, setRoomQuantities] = useState([]);
  const [accommodationsData, setAccommodationsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const roomPickerRef = useRef(null);

  useEffect(
    () => {
      //console.log("roomQuantities", roomQuantities);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [roomQuantities]
  );
  /*onStaySelect([
      {
        id: unit.id,
        name: unit.name,
        quantity: quantityRooms,
      },
    ]);
    */ const {
    data: accommodationUnits,
    isFetching,
    refetch,
    isError,
    //isSuccess,
  } = useQuery(
    ["stays", searchParams, currentPage],
    () => {
      return searchAccommodations({
        page: currentPage,
        ...Object.fromEntries(searchParams.entries()),
        location: tourStay.destination,
        start_date: tourStay.startDate,
        end_date: tourStay.endDate,
      });
    },
    {
      enabled: true,
      refetchOnWindowFocus: false,
      select: (payload) => payload.data,
      onSuccess: (data) => {
        if (currentPage === 1) setAccommodationsData(data.results);
        else setAccommodationsData([...accommodationsData, ...data.results]);
      },
    }
  );

  useEffect(() => {
    if (!accommodationsData) return;
    accommodationsData.forEach((accommodation) => {
      if (accommodation.type === "Hotel") {
        accommodation.units.forEach((unit) => {
          setRoomQuantities((prevRoomQuantities) => ({
            ...prevRoomQuantities,
            [unit.id]:
              prevRoomQuantities[unit.id] !== undefined
                ? prevRoomQuantities[unit.id]
                : 0,
            //setRoomQuantities({
            //  ...roomQuantities,
            //  [unit.id]: 0,
          }));
        });
      } else {
        // setRoomQuantities({
        //   ...roomQuantities,
        //   [accommodation.units[0].id]: 0,
        // });
      }
      //setRoomQuantities(
      //  Object.fromEntries(accommodationsData.units.map(({ id }) => [id, 0]))
      //);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accommodationsData]);

  useEffect(() => {
    if (isOpen) {
      setAccommodationsData([]);
      setCurrentPage(1);
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  /* const fullStayName = (stay) => {
    return `${stay.name}`;

    //return `${
    //  stay.accommodationType === "hotel" ? stay.accommodationName + " - " : ""
    //}${stay.name}`;
  };
*/

  useEffect(() => {
    if (
      accommodationUnits?.pagination.nextPage &&
      accommodationUnits?.pagination.totalPages > currentPage
    )
      setHasNextPage(true);
    else setHasNextPage(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accommodationUnits]);

  const selectedRoomsToPills = () => {
    if (isFetching) return "";
    return Object.keys(roomQuantities)
      .filter((roomId) => roomQuantities[roomId] > 0)
      .map((roomId) => {
        /*accommodationsData.forEach((accommodation) => {
          accommodation.units.forEach((unit) => {
            if (unit.id == roomId) {
              const room = unit;
              const quantity = roomQuantities[roomId];
            }
          });
        });*/

        //const room = accommodationsData.units.find((room) => room.id == roomId);
        //const quantity = roomQuantities[roomId];

        const room = accommodationsData
          .flatMap((accommodation) => accommodation.units) // Flatten the array of units
          .find((unit) => Number(unit.id) === Number(roomId)); // Find the first unit with the matching roomId

        const quantity = room ? roomQuantities[roomId] : undefined;

        return (
          <Badge
            className="room-pill"
            variant={"outline"}
            mb={2}
            display="flex"
            size="md"
            alignItems="flex-start"
            flexWrap={"no-wrap"}
            borderRadius={7}
            direction={"column"}
          >
            <Text padding={1}>
              {room && quantity ? `${quantity}x ${room.name}` : ""}
            </Text>
            <CloseButton
              color={"gray.500"}
              size="sm"
              onClick={() =>
                setRoomQuantities({
                  ...roomQuantities,
                  [roomId]: 0,
                })
              }
            />
          </Badge>
        );
      });
  };

  return (
    <>
      <Button onClick={onOpen}>
        {!tourStay.stay[0].id && !tourStay.stay[0].name
          ? "Choose your stay in " + tourStay.destination
          : "Change your stay"}
      </Button>
      {!tourStay.stay[0].name ? (
        <Alert status="warning">
          <AlertIcon />
          Please choose your accommodation
        </Alert>
      ) : !tourStay.stay[0].id ? (
        <Alert status="info">
          <AlertIcon />
          We will select optimal accommodation for you
        </Alert>
      ) : (
        <HStack spacing={1} align="start" direction="column" flexWrap={"wrap"}>
          {tourStay.stay.map((item, index) => (
            <Alert key={index} status="success">
              <AlertIcon />
              {item.quantity}x {item.name}
            </Alert>
          ))}
        </HStack>
      )}
      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior={"inside"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {"Accommodations in " +
              tourStay.destination +
              " for period " +
              tourStay.startDate +
              " - " +
              tourStay.endDate}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody as={Flex} flexDirection={"column"} gap={7} pb={6}>
            {isFetching && currentPage === 1 ? null : isError ? (
              <p>Error loading stays</p>
            ) : accommodationUnits?.results?.length === 0 ? (
              <>
                <p>
                  No stays available in {tourStay.destination} for given dates
                </p>
                <Button
                  onClick={() =>
                    onStaySelect([
                      {
                        id: null,
                        name: `Stay in ${tourStay.destination} chosen by us`,
                      },
                    ])
                  }
                  variant="outline"
                >
                  Let us choose stay for you
                </Button>
              </>
            ) : (
              accommodationsData.map((accommodation, index) => (
                <>
                  {accommodation.type === "Hotel" ? (
                    <Accordion allowToggle>
                      <AccordionItem>
                        <h2>
                          <AccordionButton
                            _expanded={
                              {
                                /*bg: "silver", color: "white"*/
                              }
                            }
                          >
                            <Box as="span" flex="1" textAlign="left">
                              {accommodation.name}
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                          {
                            <RoomPicker
                              isHotel={true}
                              previewOnly={false}
                              ref={roomPickerRef}
                              type={"Hotel"}
                              rooms={accommodation.units}
                              onRoomSelect={(id, quantity) => {
                                setRoomQuantities({
                                  ...roomQuantities,
                                  [id]: quantity,
                                });
                              }}
                            />
                            /*accommodation.units.map((unit, unitIndex) => (
                            <RoomCard
                              key={unit.id}
                              isForTour={true}
                              room={{
                                ...unit,
                                name: unit.name,
                                pricePerDay: unit.pricePerNight,
                                images: unit.images,
                              }}
                              type={accommodation.type}
                              onSelect={(quantityRooms) => {
                                //onRoomSelect(room.id, quantity);

                                if (accommodation.type === "Hotel") {
                                  setRoomQuantities({
                                    ...roomQuantities,
                                    //[roomId]: 0,
                                  });
                                }
                                onStaySelect([
                                  {
                                    id: unit.id,
                                    name: `${unit.name} (${accommodation.name})`,
                                    quantity: quantityRooms,
                                  },
                                ]);
                              }}
                            />
                          ))*/
                          }
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                  ) : (
                    accommodation.units.map((unit, unitIndex) => (
                      <RoomCard
                        key={unit.id}
                        isForTour={true}
                        room={{
                          ...unit,
                          name: accommodation.name,
                          pricePerDay: unit.pricePerNight,
                          images: accommodation.images,
                        }}
                        type={accommodation.type}
                        onSelect={(quantityRooms) => {
                          onStaySelect([
                            {
                              id: unit.id,
                              name: unit.name,
                              quantity: quantityRooms,
                            },
                          ]);
                        }}
                      />
                    ))
                  )}
                </>
              ))
            )}
            {isFetching && currentPage > 1 && <AccommodationCardSkeleton />}
            {hasNextPage && (
              <Button
                height={"50px"}
                isLoading={isFetching}
                onClick={() => {
                  setCurrentPage(currentPage + 1);
                  setHasNextPage(true);
                }}
              >
                Show more
              </Button>
            )}
          </ModalBody>
          <ModalFooter as={Flex}>
            <HStack spacing={4} align="start" direction="column">
              <HStack
                spacing={2}
                flexWrap={"wrap"}
                overflowY={"auto"}
                maxHeight={"90px"}
              >
                {selectedRoomsToPills()}
              </HStack>

              {isFetching ? (
                <Spinner></Spinner>
              ) : (
                <VStack alignItems={"normal"}>
                  <Button
                    mr={3}
                    colorScheme="teal"
                    onClick={() => {
                      const units = Object.keys(roomQuantities)
                        .filter((unitId) => roomQuantities[unitId] > 0) // Filter units with quantity > 0
                        .map((unitId) => {
                          const quantity = roomQuantities[unitId];
                          let matchedUnit = null;
                          for (const accommodation of accommodationsData) {
                            for (const unit of accommodation.units) {
                              if (Number(unit.id) === Number(unitId)) {
                                matchedUnit = {
                                  id: unitId,
                                  name: unit.name,
                                  quantity: quantity,
                                };
                                break;
                              }
                            }
                            if (matchedUnit) break;
                          }

                          return matchedUnit;
                        })
                        .filter((unit) => unit);

                      if (units.length === 0) {
                        alert("Please select at least one room");
                        return;
                      }
                      onStaySelect(units);
                    }}
                  >
                    Select rooms
                  </Button>
                  <Button colorScheme="blue" mr={3} onClick={onClose}>
                    Close
                  </Button>
                </VStack>
              )}
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ChooseStayMenu;
