import { Center, Spinner } from "@chakra-ui/react";
import React from "react";
import {
  Link,
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
  useNavigate,
} from "react-router-dom";
import InviteUser from "./components/invite-user/InviteUser";
import Login from "./components/login/Login";
import { RESERVATION_TYPES, ROLES } from "./constants";
import { useAuth } from "./contexts/AuthContext";
import MainLayout from "./layouts/main/MainLayout";
import Home from "./pages/home/Home";
import Register from "./pages/register/Register";
import Accommodation from "./pages/accommodation/Accommodation";
import CreateAccommodation from "./pages/create-accommodation/CreateAccommodation";
import EditAccommodation from "./pages/edit-accommodation/EditAccommodation";
import AccommodationsSearch from "./pages/accommodations-search/AccommodationsSearch";
import CreateVehicle from "./pages/create-vehicle/CreateVehicle";
import EditVehicle from "./pages/edit-vehicle/EditVehicle";
import ManageAccommodations from "./pages/manage-accommodations/ManageAccommodations";
import CreateAccommodationUnit from "./pages/create-accommodation-unit/CreateAccommodationUnit.js";
import EditAccommodationUnit from "./pages/edit-accommodation-unit/EditAccommodationUnit.js";
import ManageVehicles from "./pages/manage-vehicles/ManageVehicles.js";
import Booking from "./pages/booking/Booking.js";
import VehiclesSearch from "./pages/vehicles-search/VehiclesSearch.js";
import PendingBookings from "./pages/booking/PendingBookings.js";
import AllBookings from "./pages/booking/AllBookings.js";
import TourBuilder from "./pages/tours/TourBuilder.js";
import PrivacyPolicy from "./pages/PrivacyPolicy.js";
import TermsOfUse from "./pages/TermsOfUse.js";

function NotFound() {
  return <h1>404 Not Found</h1>;
}

function About() {
  return <h1>About us</h1>;
}

function Contact() {
  return <Link to="/register">Register</Link>;
}

function Splash() {
  return (
    <Center>
      <Spinner size="xl" />
    </Center>
  );
}

const ProtectedRoute = ({ children, allowedRoles }) => {
  let { isAuthenticated, currentUser, isLoadingUser } = useAuth();

  if (isLoadingUser) {
    return <Splash />;
  }
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }
  if (!allowedRoles || allowedRoles.includes(currentUser?.type)) {
    return children;
  }
};

const AuthRoute = ({ children }) => {
  let { isAuthenticated, currentUser, isLoadingUser } = useAuth();
  let navigate = useNavigate();

  if (isLoadingUser) {
    return <Splash />;
  }
  if (!isAuthenticated || currentUser?.type === ROLES.VISITOR) {
    return children;
  }
  navigate(-1);
};

function ApplicationRouter() {
  return (
    <Router>
      <MainLayout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/stays"
            element={<Home selectedTab={RESERVATION_TYPES.STAY} />}
          />
          <Route
            path="/cars"
            element={<Home selectedTab={RESERVATION_TYPES.CAR} />}
          />
          <Route
            path="/tours"
            element={<Home selectedTab={RESERVATION_TYPES.TOUR} />}
          />
          <Route path="/stays/search" element={<AccommodationsSearch />} />
          <Route path="/cars/search" element={<VehiclesSearch />} />
          <Route path="/privacy_policy" element={<PrivacyPolicy />} />
          <Route path="/terms_of_use" element={<TermsOfUse />} />

          <Route
            path="/login"
            exact
            element={
              <AuthRoute>
                <Login />
              </AuthRoute>
            }
          />
          <Route
            path="/register"
            exact
            element={
              <AuthRoute>
                <Register />
              </AuthRoute>
            }
          />
          <Route path="/book/:bookingId" exact element={<Booking />} />
          <Route
            path="/about"
            exact
            element={
              <ProtectedRoute>
                <About />
              </ProtectedRoute>
            }
          />
          <Route
            path="/invite-user"
            exact
            element={
              <ProtectedRoute allowedRoles={[ROLES.ADMINISTRATOR]}>
                <InviteUser />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pending-reservations"
            exact
            element={
              <ProtectedRoute>
                <PendingBookings />
              </ProtectedRoute>
            }
          />
          <Route
            path="/all-reservations"
            exact
            element={
              <ProtectedRoute>
                <AllBookings />
              </ProtectedRoute>
            }
          />
          <Route
            path="/create-vehicle"
            exact
            element={
              <ProtectedRoute
                allowedRoles={[ROLES.ADMINISTRATOR, ROLES.DOMESTIC_PARTNER]}
              >
                <CreateVehicle />
              </ProtectedRoute>
            }
          />
          <Route
            path="/vehicles/:vehicleId/edit"
            element={
              <ProtectedRoute
                allowedRoles={[ROLES.ADMINISTRATOR, ROLES.DOMESTIC_PARTNER]}
              >
                <EditVehicle />
              </ProtectedRoute>
            }
          ></Route>
          <Route path="/tours/builder" element={<TourBuilder />}></Route>
          {/* <Route path="/tours/:tourId" element={<Tour />}></Route> */}
          <Route
            path="/manage-vehicles"
            exact
            element={
              <ProtectedRoute
                allowedRoles={[ROLES.ADMINISTRATOR, ROLES.DOMESTIC_PARTNER]}
              >
                <ManageVehicles />
              </ProtectedRoute>
            }
          />
          <Route path="/:type/:slug" element={<Accommodation />} />

          <Route
            path="/create-accommodation"
            exact
            element={
              <ProtectedRoute
                allowedRoles={[ROLES.ADMINISTRATOR, ROLES.DOMESTIC_PARTNER]}
              >
                <CreateAccommodation />
              </ProtectedRoute>
            }
          />
          <Route
            path="/manage-accommodations"
            exact
            element={
              <ProtectedRoute
                allowedRoles={[ROLES.ADMINISTRATOR, ROLES.DOMESTIC_PARTNER]}
              >
                <ManageAccommodations />
              </ProtectedRoute>
            }
          />
          <Route
            path="/:accommodationType/:slug/edit"
            element={
              <ProtectedRoute
                allowedRoles={[ROLES.ADMINISTRATOR, ROLES.DOMESTIC_PARTNER]}
              >
                <EditAccommodation />
              </ProtectedRoute>
            }
          />
          <Route
            path="/:accommodationType/:accommodationId/create-accommodation-unit"
            element={
              <ProtectedRoute
                allowedRoles={[ROLES.ADMINISTRATOR, ROLES.DOMESTIC_PARTNER]}
              >
                <CreateAccommodationUnit />
              </ProtectedRoute>
            }
          />
          <Route
            path="/:accommodationType/:accommodationSlug/:accommodationUnitId/edit"
            element={
              <ProtectedRoute
                allowedRoles={[ROLES.ADMINISTRATOR, ROLES.DOMESTIC_PARTNER]}
              >
                <EditAccommodationUnit />
              </ProtectedRoute>
            }
          />

          <Route path="/contact" exact element={<Contact />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </MainLayout>
    </Router>
  );
}

export default ApplicationRouter;
