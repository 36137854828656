import React from "react";
import { Button } from "@chakra-ui/react";

function ClearFilterButton({ onClick, variant }) {
  return (
    <Button variant={variant} width={"full"} onClick={onClick}>
      Clear filters
    </Button>
  );
}

export default ClearFilterButton;
